import { useHistory } from 'react-router-dom';
import { Col, FlexboxGrid } from 'rsuite';
import axios from '../../lib/axios';
import { notification, notificationError } from '../../lib/notification';
import { Title } from './resubscribe.style';
import { SpaceDiv } from '../../layout/header/HeaderForms.styles';
import { ButtonAdd } from '../posts/Posts.styles';
import { Container } from '../../styles/generalStyles';

// eslint-disable-next-line
const handleUnsubscribe = async (email: string, history: any) => {
  try {
    await axios.post(`mail/resubscribe`, {}, { params: { email } });
    notification({
      type: 'success',
      title: 'Success',
      description: 'Resubscribed Successfully',
    });

    history.push('/');
  } catch (error) {
    notificationError({
      title: 'Error',
      description: 'Could not send reply',
    });
  }
};

const UnsubscribeEmails = () => {
  const history = useHistory();

  const handleClick = () => {
    const email = new URLSearchParams(window.location.search).get('email');

    if (!email) {
      throw new Error('Email address not found in URL');
    }
    handleUnsubscribe(email, history);
  };

  return (
    <>
      <Title>Resubscribe from emails?</Title>
      <SpaceDiv />
      <Container>
        <FlexboxGrid align="middle" justify="center">
          <Col xs={24} md={17}>
            <FlexboxGrid align="middle" justify="space-around">
              <FlexboxGrid.Item
                componentClass={Col}
                xs={24}
                md={20}
                style={{ padding: 0 }}>
                <ButtonAdd onClick={handleClick}>
                  <>
                    <span>Resubscribe</span>
                  </>
                </ButtonAdd>
              </FlexboxGrid.Item>
            </FlexboxGrid>
          </Col>
        </FlexboxGrid>
      </Container>
    </>
  );
};

export default UnsubscribeEmails;
