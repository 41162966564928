import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { confirmAlert } from 'react-confirm-alert';
import { Loader } from 'rsuite';
import FacebookIcon from '../../assets/icons/facebook.svg';
import GoogleIcon from '../../assets/icons/google.svg';
import InstagramIcon from '../../assets/icons/instagram.png';
import TwitterIcon from '../../assets/icons/x.svg';
import TikTokIcon from '../../assets/icons/TikTok.svg';
import VideoIcon from '../../assets/icons/video.svg';
import DefaultImage from '../../assets/images/default.jpg';
import { useAuth } from '../../context/AuthContext';
import { formatDate } from '../../lib/format';
import {
  ButtonNotification,
  ContentContainer,
  CtaContainer,
  CtaWrapper,
  DateContainer,
  ImageContainer,
  ImageTextContainer,
  NetworkIcon,
  PostDate,
  PostImage,
  PostText,
  TextContainer,
  VideoImage,
} from './Post.styles';
import { notificationWithNodes } from '../../lib/notification';
import useDeleteSocialPost from '../../hooks/useDeletePosts';

interface Props {
  post: Post;
  setDataPostSelected?: React.Dispatch<React.SetStateAction<Post | null>>;
}

const iconMapper = {
  facebook: FacebookIcon,
  twitter: TwitterIcon,
  instagram: InstagramIcon,
  google: GoogleIcon,
  tiktok: TikTokIcon,
};

const Post = ({ post, setDataPostSelected }: Props) => {
  const handleClickDescription = (clickedPost: Post) =>
    setDataPostSelected?.(clickedPost);
  const { user } = useAuth();
  const [displayPremiumPlan, setDisplayPremiumPlan] = useState<boolean>(false);
  const history = useHistory();
  const deleteSocialPost = useDeleteSocialPost();
  const trialEndDate = user?.companies?.[0]?.payment?.trialEndDate ?? null;

  useEffect(() => {
    const { subscriptionStatus, invoiceStatus, hasAccess } =
      user?.companies?.[0]?.payment ?? {};
    const isInTrialPeriod = trialEndDate && new Date(trialEndDate) > new Date();
    if (
      (subscriptionStatus === 'active' && invoiceStatus === 'paid') ||
      (subscriptionStatus === 'active_lifetime' && invoiceStatus === 'paid') ||
      isInTrialPeriod ||
      (subscriptionStatus === 'trialing' &&
        invoiceStatus === 'paid' &&
        hasAccess) ||
      (subscriptionStatus === 'trial_expired' && !hasAccess)
    ) {
      setDisplayPremiumPlan(true);
    } else {
      setDisplayPremiumPlan(false);
    }
  }, [user]);

  const handleBoost = (id: string) => {
    if (!displayPremiumPlan) {
      notificationWithNodes({
        type: 'info',
        title: 'Upgrade your plan',
        description: (
          <>
            <p>
              You&apos;ll need to upgrade to a premium plan to activate this
              feature.
            </p>
            <ButtonNotification
              onClick={() => history.push('/user-panel/account')}>
              See plans
            </ButtonNotification>
          </>
        ),
        duration: 10000,
      });
    } else {
      // reload page, to be able to show embeded FB post
      window.location.href = `${window.location.origin}/user-panel/promotions/new/${id}`;
    }
  };

  const deletePostConfirmation = (id: string, socialNetwork: string) => {
    confirmAlert({
      message: `Do you want to delete this post?`,
      buttons: [
        {
          label: 'Confirm',
          onClick: () => {
            deletePost(id, socialNetwork);
          },
        },
        {
          label: 'Cancel',
          onClick: () => null,
        },
      ],
    });
  };

  const deletePost = async (id: string, socialNetwork: string) => {
    try {
      await deleteSocialPost.mutateAsync({
        postId: id,
        socialNetwork,
      });
      // if (response && 'postId' in response) {
      //   console.log('success');
      // }
    } catch (e) {
      if (e instanceof Error) {
        // return { status: false };
        // console.log('error');
      }
    }
  };
  return (
    <div className="  bg-black p-5  border-gray-300 md:items-center items-start">
      <NetworkIcon src={iconMapper[post.network]} alt="Network" />
      <ContentContainer>
        <ImageTextContainer>
          {post?.media.image ? (
            <ImageContainer onClick={() => handleClickDescription(post)}>
              <PostImage src={post.media.image} alt="Post" />
              {post.media.type === 'video' && (
                <VideoImage
                  src={VideoIcon}
                  alt="Video"
                  style={{ position: 'absolute' }}
                />
              )}
            </ImageContainer>
          ) : (
            <PostImage
              src={DefaultImage}
              alt="Post"
              onClick={() => handleClickDescription(post)}
            />
          )}
          <TextContainer>
            <PostText>{post.text}</PostText>
          </TextContainer>
        </ImageTextContainer>
        <DateContainer>
          <PostDate>{formatDate(post.publishedAt)}</PostDate>
        </DateContainer>
        <CtaWrapper>
          {post.isBoostable && (
            <CtaContainer>
              <button type="button" onClick={() => handleBoost(post.id)}>
                Boost
              </button>
            </CtaContainer>
          )}

          {(post.network === 'facebook' ||
            post.network === 'twitter' ||
            post.network === 'google') && (
            <CtaContainer>
              {deleteSocialPost.isLoading ? (
                <Loader content="Deleting..." />
              ) : (
                <button
                  type="button"
                  onClick={() => deletePostConfirmation(post.id, post.network)}>
                  Delete
                </button>
              )}
            </CtaContainer>
          )}
        </CtaWrapper>
      </ContentContainer>
    </div>
  );
};

export default Post;
