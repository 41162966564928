import CryptoJS from 'crypto-js';
import DefaultImage from '../assets/images/default.jpg';

interface SensitiveData {
  email: string;
  password: string;
}

const SECRET_KEY = process.env.REACT_APP_SECRET_KEY || 'mySecretKey123';

export const getCurrencySymbol = (currency: string) => {
  switch (currency) {
    case 'EUR':
      return '€';
    case 'USD':
      return '$';
    case 'GBP':
      return '£';
    default:
      return '$';
  }
};

export const calculateSpending = (totalBudget: number, budgetLeft: number) => {
  return ((totalBudget - budgetLeft) * 100) / totalBudget;
};

export const stringToFloat = (value: string) => parseFloat(value) / 100;

export const formatFloat = (value: number) => value.toFixed(2);

export const getImage = (imageUrl: string | undefined) => {
  if (imageUrl) {
    return imageUrl;
  }
  return DefaultImage;
};

export const formatNumber = (value: number | string, decimalNumbers = 0) => {
  if (typeof value === 'number') {
    return new Intl.NumberFormat('en-GB', {
      minimumFractionDigits: decimalNumbers,
      maximumFractionDigits: decimalNumbers,
    }).format(value);
  }

  return new Intl.NumberFormat('en-GB', {
    minimumFractionDigits: decimalNumbers,
    maximumFractionDigits: decimalNumbers,
  }).format(parseFloat(value));
};

export const capitalize = (word: string) =>
  word.charAt(0).toUpperCase() + word.slice(1);

export const percentageText = (
  oldNumber: number,
  number: number,
  type: 'negative' | 'positive' | 'neutral',
) => {
  if (oldNumber === 0 && type === 'positive') return 'N/A';
  if (type === 'negative') return `-${number}%`;
  if (type === 'positive') return `+${number}%`;

  return `${number}%`;
};

// Helper function to format size in a human-readable format
export const formatSize = (bytes: number): string => {
  if (bytes === 0) return '0 Bytes';
  const k = 1024;
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
  const i = Math.floor(Math.log(bytes) / Math.log(k));
  return `${parseFloat((bytes / k ** i).toFixed(2))} ${sizes[i]}`;
};

// Helper function to format duration as minutes and seconds
export const formatDuration = (seconds: number): string => {
  const minutes = Math.floor(seconds / 60);
  const remainingSeconds = Math.floor(seconds % 60);
  return `${minutes}m ${remainingSeconds}s`;
};

export const encryptData = (data: SensitiveData) => {
  const encryptedData = encodeURIComponent(
    CryptoJS.AES.encrypt(JSON.stringify(data), SECRET_KEY).toString(),
  );
  return encryptedData;
};

export const decryptData = (encryptedData: string): SensitiveData | null => {
  try {
    const bytes = CryptoJS.AES.decrypt(
      decodeURIComponent(encryptedData),
      SECRET_KEY,
    );
    const decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
    return decryptedData;
  } catch (error) {
    return null;
  }
};
