import styled from 'styled-components';

// Header Styles
export const StepHeaderContainer = styled.div`
  width: 100%;
  height: 5%;
  display: flex;
  flex-direction: column;
  background-color: #fff;
  font-family: 'Inter', sans-serif;
  margin-bottom: 10px;
  @media (max-width: 640px) {
    margin-bottom: 20px;
  }
`;
export const CreateAccountTitle = styled.h1`
  font-size: 1rem;
  font-family: 'Inter', sans-serif;
  color: #dd2256;
  text-align: center;
  @media (max-width: 640px) {
    font-size: 1rem;
  }
`;

export const StepIndicator = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 1.5rem;
`;

export const StepCircle = styled.div<{ active: boolean }>`
  width: 10px;
  height: 10px;
  margin: 0 5px;
  border-radius: 50%;
  background-color: ${props => (props.active ? '#DD2256' : '#FFC0CB')};
  transition: background-color 0.3s ease-in-out;
`;

export const BackButtondiv = styled.div`
  display: none;

  @media (max-width: 780px) {
    font-family: 'Inter', sans-serif;

    display: flex;
    width: 78%;
    position: fixed;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    background: #fff;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    z-index: 1000;
    min-height: 140px;
  }

  @media (max-width: 480px) {
    padding: 0;
  }
`;

export const BackButton = styled.button`
  background-color: #ffd6df;
  font-family: 'Inter', sans-serif;
  color: #dd2256;
  width: 140px;
  height: 50px;
  font: bold;
  border: 1px solid #dd2256;
  border-radius: 20px;
  font-size: 20px;
  cursor: pointer;
  margin: 0 10px;

  &:hover {
    background: linear-gradient(180deg, #dd2256 0%, #e43c39 100%);
    color: #fff;
  }

  @media (max-width: 780px) {
    width: 140px;
    height: 40px;
    font-size: 18px;
    border-radius: 12px;
    margin: 0 5px;
  }
  @media (max-width: 480px) {
    width: 140px;
    height: 50px;
    font-size: 186x;
    border-radius: 15px;
    margin: 0 5px;
  }

  @media (max-width: 380px) {
    width: 120px;
    height: 40px;
    font-size: 14px;
    border-radius: 15px;
  }
`;

export const BackButtonnext = styled.button`
  font-family: 'Inter', sans-serif;

  color: #fff;
  width: 140px;
  height: 50px;
  border: 1px solid #dd2256;
  border-radius: 20px;
  font-size: 20px;
  cursor: pointer;
  margin: 0 10px;
  background: linear-gradient(180deg, #dd2256 0%, #e43c39 100%);

  &:hover {
    background-color: #c51e4a;
    color: #fff;
  }

  @media (max-width: 780px) {
    width: 140px;
    height: 40px;
    font-size: 18px;
    border-radius: 12px;
    margin: 0 5px;
  }
  @media (max-width: 480px) {
    width: 140px;
    height: 50px;
    font-size: 16px;
    border-radius: 15px;
    margin: 0 5px;
  }

  @media (max-width: 380px) {
    width: 120px;
    height: 40px;
    font-size: 14px;
    border-radius: 15px;
  }
`;

export const ContentContainer = styled.div`
  // flex: 1;
  width: 90%;
  margin: 0 auto;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  @media (max-width: 780px) {
    width: 80%;
    padding-bottom: 120px;
  }
`;

export const DesktopBackButtonContainer = styled.div`
  display: flex;

  flex-direction: row-reverse;
  justify-content: center;
  align-items: center;
  width: 68%;
  background: #fff;
  z-index: 1000;
  border-radius: 15px 15px 0 0;
  font-family: 'Inter', sans-serif;
  margin-top: 100px;
  gap: 20px;

  @media (max-width: 1480px) {
    width: 72%;
    justify-content: space-between;
  }
  @media (max-width: 780px) {
    display: none;
  }
`;

export const NextButtonContainer = styled.div`
  width: 50%;
  display: flex;
  justify-content: start;
  align-items: center;
`;

export const BackCancelContainer = styled.div`
  width: 50%;
  display: flex;
  justify-content: end;
  align-items: center;
  border: 0.1em solid #ffffff;
  border-radius: 10px;
  box-sizing: border-box;
  text-decoration: none;
  font-weight: 300;
  color: #ffffff;
  text-align: center;
  transition: all 0.2s;

  @media (max-width: 780px) {
    margin-bottom: 20px;
  }
`;

export const Button = styled.button`
  width: 100%; /* Makes the button fill its container */
  background: linear-gradient(180deg, #dd2256 0%, #e43c39 100%);
  color: white;
  padding: 10px 0;
  border: none;
  border-radius: 12px;
  font-size: 18px;
  cursor: pointer;
  margin: 0; /* Removes top margin to prevent height differences */
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #c51e4a;
  }

  @media (max-width: 1480px) {
    font-size: 16px;
    border-radius: 10px;
    padding: 8px 0; /* Standardizes padding to ensure the same height */
  }

  @media (max-width: 780px) {
    display: none;
  }
`;

export const SignUpButton = styled.button`
  width: 100%;
  background: linear-gradient(180deg, #dd2256 0%, #e43c39 100%);
  color: white;
  padding: 10px 0;
  border: none;
  border-radius: 12px;
  font-size: 10px;
  cursor: pointer;
  margin: 5px;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #c51e4a;
  }

  @media (max-width: 1480px) {
    font-size: 12px;
    border-radius: 10px;
    padding: 8px 0;
  }
`;

export const DesktopBackButton = styled.button`
  width: 100%; /* Makes the button fill its container */
  font-family: 'Inter', sans-serif;
  background-color: #fff;
  color: #dd2256;
  padding: 10px 0;
  border: 1px solid #dd2256;
  border-radius: 12px;
  font-size: 18px;
  cursor: pointer;
  margin: 0; /* Removes top margin to prevent height differences */
  transition: background-color 0.3s ease, color 0.3s ease, transform 0.3s ease;

  &:hover {
    background: linear-gradient(180deg, #dd2256 0%, #e43c39 100%);
    color: #fff;
  }

  @media (max-width: 1480px) {
    font-size: 16px;
    border-radius: 10px;
    padding: 7px 0; /* Standardizes padding to ensure the same height */
  }

  @media (max-width: 780px) {
    display: none;
  }
`;

export const StyledErrorMessage = styled.div`
  color: red;
  font-size: 10px;
  margin-bottom: 10px;
  font-weight: bold;
  width: 65%;
  color: red;
  @media (max-width: 1480px) {
    margin-top: none;
    margin-bottom: none;
    line-height: 0px;
  }
  @media (max-width: 780px) {
  }
`;
