import { Button, Col, FlexboxGrid, Row } from 'rsuite';
import styled from 'styled-components';

interface ColMarginProps {
  signup?: boolean;
  newFormat?: boolean;
}

export const CardPage = styled(FlexboxGrid)`
  background-color: ${props => props.theme.colors.softGrey};
  border-radius: 20px;
  margin: 0px 23px 15px;
  padding: 10px 25px;
  justify-content: space-between;

  .profile-img {
    width: 40px;
    margin-right: 20px;
    border-radius: 50%;
  }

  button {
    align-self: flex-end;
    background-color: ${props => props.theme.colors.blue};
    font-size: ${props => props.theme.fonts.paragraph};
    color: ${props => props.theme.colors.white};
    font-weight: bolder;
  }

  @media ${props => props.theme.breakpoints.maxMedium} {
    flex-direction: column;
    align-items: center;

    button {
      margin: 5px 0;
    }
  }
`;

export const NoAccount = styled.div`
  padding: 0px 10px;
  text-align: center;
  font-size: 16px;
  font-weight: 600;
`;

export const NoAdsAccountDescription = styled.div`
  padding: 10px 10px 0px;
  text-align: center;
  font-size: 16px;
`;

export const ButtonAdd = styled(Button)<{ bgColor: string }>`
  background-color: ${props => props.bgColor};
  padding: ${({ signup }) => (signup ? '8px 15px' : '12px 30px')};
  border-radius: 50px;
  color: ${props => props.theme.colors.white};
  font-weight: bolder;
  font-size: 10px;
  font-family: ${({ signup }) => (signup ? "'Inter', sans-serif" : 'inherit')};

  &:disabled {
    // background-color: ${props => props.bgColor} !important;
    background-color: ${({ signup, bgColor }) => (signup ? 'none' : bgColor)};
  }

  @media ${props => props.theme.breakpoints.xs},
    ${props => props.theme.breakpoints.sm} {
    margin-bottom: 20px;
  }
  @media (max-width: 1480px) {
    padding: ${({ signup }) => (signup ? '6px 10px' : '12px 30px')};
    padding: ${({ pad }) => pad};
  }
  @media (max-width: 992px) {
    padding: ${({ signup }) => (signup ? '10px 5px' : '12px 30px')};
  }
  @media (max-width: 780px) {
    padding: 8px 5px;
    // padding: ${({ signup }) => (signup ? '7px 5px' : '12px 20px')};
  }
  @media (max-width: 520px) {
    padding: ${({ signup }) => (signup ? '12px 5px' : '8px 10px')};
    border-radius: ${({ signup }) => (signup ? '20px' : '20px')};
  }
  @media (max-width: 420px) {
    padding: ${({ signup }) => (signup ? '12px 5px' : '10px 6px')};
    border-radius: ${({ signup }) => (signup ? '20px' : '10px')};
  }
`;

export const Paragraph = styled.p`
  font-size: ${props => props.theme.fonts.paragraph};
  color: ${props => props.theme.colors.greyLetter};
  font-family: 'Poppins';
  text-transform: capitalize;
`;

export const ParagraphColor = styled(Paragraph)<{ status?: number }>`
  color: ${props => props.status === 1 && props.theme.colors.softGreen};
  color: ${props => props.status === 0 && props.theme.colors.red};
`;

export const TextContainer = styled.div`
  display: flex;
  margin-left: 8%;
  align-items: center;

  @media ${props => props.theme.breakpoints.xs},
    ${props => props.theme.breakpoints.sm} {
    flex-direction: column;
    margin-bottom: 2%;
  }
`;

export const Container = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 10px;

  @media ${props => props.theme.breakpoints.xs},
    ${props => props.theme.breakpoints.sm} {
    flex-direction: column;

    ${ButtonAdd}:last-child {
      margin-bottom: 0; /* Remove margin-bottom from the last button */
    }
  }
`;

export const ButtonContainer = styled.div<{ signup?: boolean }>`
  width: auto;
  display: flex;
  justify-content: flex-end;
  padding: 5px;
  @media ${props => props.theme.breakpoints.xs},
    ${props => props.theme.breakpoints.sm} {
    flex-direction: column;
    ${ButtonAdd}:only-child {
      margin-bottom: 0;
    }
  }
`;

// export const ColMargin = styled(Col)`
//   margin-top: 30px;
//   margin-bottom: 20px;
// `;
export const ColMargin = styled(Col)<ColMarginProps>`
  display: ${({ newFormat }) => (newFormat ? 'flex' : '')};
  margin-top: ${({ signup }) => (signup ? '5px' : '0px')};
  margin-bottom: ${({ signup }) => (signup ? '10px' : '18px')};

  @media (max-width: 1480px) {
    margin-top: ${({ signup }) => (signup ? '2px' : '0px')};
    margin-bottom: ${({ signup }) => (signup ? '2px' : '10px')};
  }
`;
export const CustomRow = styled(Row)<{ signup: boolean }>`
  display: flex;
  flex-direction: ${({ signup }) => (signup ? 'column' : 'row')};
  align-items: center;
  margin-right: 0;
  ${({ signup }) =>
    signup &&
    `
    width: 100%;
    display:flex;

    justify-content: center;
  `}
`;

export const Subtitlep = styled.p`
  font-family: 'Inter', sans-serif;
  font-size: 14px;
  color: #666;
  margin-bottom: 5px;
  margin-top: 15px;
  padding: 0 20px;
  text-align: left; /* Added to align text to the left */
  // font-weight: 600;
  @media (max-width: 1280px) {
    font-size: 12px;
    padding: 0 20px;
  }

  @media (max-width: 780px) {
    font-size: 12px;
    padding: 0 15px;
  }
`;
