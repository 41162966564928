import { useHistory } from 'react-router-dom';
import { Col, FlexboxGrid } from 'rsuite';// import { Paragraph } from './ForgetPass.styles';
import axios from '../../lib/axios';
import { notification, notificationError } from '../../lib/notification';
import { Title } from './UnsubscribeEmails.styles';
import { SpaceDiv } from '../../layout/header/HeaderForms.styles';
import { ButtonAdd } from '../posts/Posts.styles';
import { Container } from '../../styles/generalStyles';


// eslint-disable-next-line
const handleUnsubscribe = async (email: string, history: any) => {
    try {

        await axios.post(`mail/unsubscribe`, {}, { params: { email } });
        notification({
            type: 'success',
            title: 'Success',
            description: 'Unsubscribed Successfully',
        });

        history.push('/');

    } catch (error) {
        notificationError({
            title: 'Error',
            description: 'Could not send reply',
        });
    }
};

const UnsubscribeEmails = () => {

    const history = useHistory();

    const handleClick = () => {

        const email = new URL(window.location.href).searchParams.get("email");
        if (!email) {
            throw new Error("Email address not found in URL");
        }
        handleUnsubscribe(email, history);
    };

    return (
        <>
            <Title>
                Unsubscribe from emails?
            </Title>
            <SpaceDiv />
            <Container>
                <FlexboxGrid align="middle" justify="center">
                    <Col xs={24} md={17}>
                        <FlexboxGrid align="middle" justify="space-around">
                            <FlexboxGrid.Item
                                componentClass={Col}
                                xs={24}
                                md={20}
                                style={{ padding: 0 }}>
                                <ButtonAdd onClick={handleClick}>
                                    <>
                                        <span>Unsubscribe</span>
                                    </>
                                </ButtonAdd>
                            </FlexboxGrid.Item>
                        </FlexboxGrid>
                    </Col>
                </FlexboxGrid>
            </Container>
        </>
    );
};

export default UnsubscribeEmails;
