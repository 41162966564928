/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable camelcase */
import React, { useState, forwardRef, useImperativeHandle } from 'react';
import { Button, Col, FlexboxGrid, Toggle } from 'rsuite';
import { useTheme } from 'styled-components';
import { useAuth } from '../../../context/AuthContext';
import { TYPES_TOOLS } from '../../../actions/toolsActions';
import { TYPES_OF_SOCIAL_NETWORKS } from '../../../lib/globalValues';
import { notificationWithSimpleNodes } from '../../../lib/notification';
import { assignTool } from '../../../services/assignTool';
import { disableTool } from '../../../services/disableTool';
import { facebookConnect } from '../../../services/facebookTools';
import { getToolsCompanyObj } from '../../../services/getToolsCompany';
import {
  instagramLogin,
  instagramPageSelected,
} from '../../../services/instagramTools';
import ModalTools from '../../modals/modalTools/ModalTools';
import {
  ButtonAdd,
  ButtonContainer,
  CardPage,
  Container,
  NoAccount,
  NoAdsAccountDescription,
} from '../Toogle.styles';

interface Props {
  companyId?: number;
  dispatchTool: any;
  stateTool: any;
  invalidateQuery?: () => void;
  signup?: boolean;
  newFormat?: boolean;
  clickedSocial?: string;
  socialNetworkPages?: {
    facebook: boolean;
    google: boolean;
    instagram: boolean;
    twitter: boolean;
    tiktok: boolean;
  };
  updateParentPages: () => Promise<void>;
}
interface InstagramToogleRef {
  handleClick: () => void;
}

const InstagramToogle = forwardRef<InstagramToogleRef, Props>(
  (
    {
      companyId,
      stateTool,
      dispatchTool,
      invalidateQuery,
      signup = false,
      newFormat = false,
      clickedSocial,
      socialNetworkPages,
      updateParentPages,
    }: Props,
    ref,
  ) => {
    const theme = useTheme();

    const [dataModal, setDataModal] = useState<FacebookData | null>(null);
    const [dataModalArray, setDataModalArray] = useState<string[]>([]);
    const [switchData, setSwitchData] = useState<[] | null>(null);
    const [step, setStep] = useState<'page' | 'switch'>('page');

    const activated =
      stateTool[TYPES_OF_SOCIAL_NETWORKS.INSTAGRAM]?.enabled === true;
    useImperativeHandle(
      ref,
      () => ({
        handleClick: () => {
          if (clickedSocial === 'INSTAGRAM') {
            handleLoginInstagram();
          }
        },
      }),
      [clickedSocial],
    );

    const { user, refetch } = useAuth();

    const handleLoginInstagram = async () => {
      try {
        const facebookAcoount = await instagramLogin();
        const { data } = await facebookConnect(
          facebookAcoount as fb.StatusResponse,
        );

        if (data) {
          setDataModal(data);
        }
      } catch (err) {
        notificationWithSimpleNodes({
          type: 'open',
          title: '',
          description: `Instagram connection canceled`,
        });
      }
    };

    const handleAddSelectedPagesClick = async (
      page: InstagramPage,
      event: React.SyntheticEvent,
    ) => {
      event.stopPropagation();
      if (updateParentPages) {
        try {
          event.stopPropagation();
          await updateParentPages();
          event.stopPropagation();
        } catch (error) {
          // console.error('Error updating parent pages:', error);
        }
      }
      setDataModalArray(prev => [...prev, page.id]);
      const instaPageArray: InstagramPage[] = [page];

      if (dataModal) {
        await handleSelectPages(instaPageArray, event);
      }
    };
    const handleSelectPages = async (
      page: InstagramPage[],
      event: React.SyntheticEvent,
    ) => {
      event.stopPropagation();
      const instagramPages = await instagramPageSelected(page);
      const transformedPages: Tool[] = instagramPages.data.map(
        (selectedPage: any) => ({
          type: TYPES_OF_SOCIAL_NETWORKS.INSTAGRAM,
          accessToken: selectedPage.access_token,
          pageId: selectedPage.id,
          pageName: `${selectedPage.name} - ${selectedPage.category}`,
          enabled: dataModalArray.length === 0,
          userId: selectedPage.userId,
          profilePic: selectedPage.profile_picture_url,
          pageDetails: selectedPage.pageDetails,
        }),
      );

      if (companyId) {
        const { data } = await assignTool({
          companyId,
          tool: transformedPages,
        });

        if (data && data[0] && dataModalArray.length === 0) {
          dispatchTool({
            type: TYPES_TOOLS.ACTIVATE_INSTAGRAM,
            payload: data[0],
          });
          refetch();
        }
      } else {
        dispatchTool({
          type: TYPES_TOOLS.ACTIVATE_INSTAGRAM,
          payload: {
            // enabled: true,
            ...transformedPages[0],
          },
        });
        refetch();
      }

      invalidateQuery?.();
      // setDataModal(null);
    };

    const handleShowPages = async () => {
      const { data: dataTools } = await getToolsCompanyObj(companyId);
      setSwitchData(dataTools.instagram);
      setStep('switch');
    };

    const handleSelectPage = async (page: any) => {
      const defaultSelectContent = [
        {
          type: TYPES_OF_SOCIAL_NETWORKS.INSTAGRAM,
          accessToken: page.accessToken,
          pageId: page.pageId,
          // pageName: `${name} - ${category}`,
          userId: page.userId,
          // adAccountId: null,
          enabled: true,
          id: page.id,
        },
      ];

      if (companyId) {
        const { data } = await assignTool({
          companyId,
          tool: defaultSelectContent,
        });

        dispatchTool({
          type: TYPES_TOOLS.ACTIVATE_INSTAGRAM,
          payload: data[0],
        });
        refetch();
        setSwitchData(null);
      }
    };

    const disableButton =
      user?.companies[0]?.tools?.filter(
        item => item?.enabled === true && item?.type === 'instagram',
      )[0]?.enabled || false;

    return (
      <>
        {dataModal && step === 'page' && (
          <ModalTools
            setDataModal={setDataModal}
            dataModal={dataModal}
            title="Connect Instagram"
            description={
              dataModal?.pages?.length > 0
                ? 'Select all the Instagram Business pages you want to connect'
                : ''
            }
            setDataModalArray={setDataModalArray}
            step={dataModal?.pages?.length > 0 ? 'no-ads' : 'no-fb-pages'}
            disableButton={disableButton}>
            {dataModal?.pages?.length > 0 ? (
              <div>
                {dataModal?.pages?.map(page => {
                  return (
                    <CardPage
                      align="middle"
                      justify="space-around"
                      key={page.id}>
                      <FlexboxGrid.Item xs={20} componentClass={Col}>
                        <span>
                          {page.name} - {page.category}
                        </span>
                      </FlexboxGrid.Item>
                      <FlexboxGrid.Item xs={4} componentClass={Col}>
                        <Toggle
                          checked={dataModalArray.includes(page.id)}
                          disabled={dataModalArray.includes(page.id)}
                          onChange={async (
                            checked: boolean,
                            event: React.SyntheticEvent,
                          ) => {
                            event.stopPropagation();

                            if (checked) {
                              setDataModalArray(prev => [...prev, page.id]);
                              handleAddSelectedPagesClick(page, event);
                            } else {
                              setDataModalArray(prev =>
                                prev.filter(id => id !== page.id),
                              );

                              if (companyId) {
                                try {
                                  disableTool(
                                    stateTool[
                                      TYPES_OF_SOCIAL_NETWORKS.INSTAGRAM
                                    ].id,
                                  ).then(() => {
                                    dispatchTool({
                                      type: TYPES_TOOLS.DEACTIVATE_INSTAGRAM,
                                    });
                                    invalidateQuery?.();
                                  });
                                  invalidateQuery?.();
                                } catch (error) {
                                  // console.error('Error disabling tool:', error);
                                }
                              } else {
                                dispatchTool({
                                  type: TYPES_TOOLS.DEACTIVATE_INSTAGRAM,
                                  payload: page.id,
                                });
                              }
                            }
                          }}
                          style={{
                            backgroundColor: dataModalArray.includes(page.id)
                              ? '#dd2256'
                              : '#cccccc',
                            transition: 'background-color 0.3s ease',
                          }}
                        />
                      </FlexboxGrid.Item>
                    </CardPage>
                  );
                })}
              </div>
            ) : (
              <>
                <NoAccount>No Instagram Business Page found</NoAccount>
                <NoAdsAccountDescription>
                  You need to be administrator of the Instagram Business pages
                  you want to connect
                </NoAdsAccountDescription>
              </>
            )}
          </ModalTools>
        )}

        {switchData && step === 'switch' && (
          <fieldset disabled>
            {' '}
            {/* Disables everything inside */}
            <ModalTools
              setDataModal={setSwitchData}
              dataModal={switchData}
              title="Switch Instagram page"
              description="Select the Instagram page to switch to"
              setStep={setStep}
              step="page">
              <div>
                {switchData?.map((page: any) => {
                  return (
                    <CardPage align="middle" key={page.pageId}>
                      <FlexboxGrid.Item xs={20} componentClass={Col}>
                        <span>{page.pageName}</span>
                      </FlexboxGrid.Item>
                      <FlexboxGrid.Item xs={4} componentClass={Col}>
                        <Button
                          disabled={page.enabled}
                          onClick={(event: React.SyntheticEvent) => {
                            event.stopPropagation();
                            handleSelectPage(page);
                          }}>
                          {page.enabled ? 'Activated' : 'Activate'}
                        </Button>
                        {/* <Toggle
                            checked={page.enabled}
                            disabled={page.enabled}
                            onChange={(
                              checked: boolean,
                              event: React.SyntheticEvent,
                            ) => {
                              event.stopPropagation();

                              if (checked) {
                                handleSelectPage(page);
                              }
                              invalidateQuery?.();
                            }}
                            style={{
                              backgroundColor: page.enabled
                                ? '#dd2256'
                                : '#cccccc',
                              transition: 'background-color 0.3s ease',
                            }}
                          /> */}
                        {/* <input type='checkbox'
                      onChange={(e) => handlePageCheck(page, e.target.checked)}
                      checked={selectedPages.some(p => p.id === page.id)}
                      /> */}
                      </FlexboxGrid.Item>
                    </CardPage>
                  );
                })}
              </div>
            </ModalTools>
          </fieldset>
        )}

        {signup ? (
          <>
            {activated && (
              <Container
                style={{
                  // display: 'flex',
                  // flexDirection: signup ? 'column' : 'row',
                  justifyContent: signup ? 'end' : 'space-between',
                  // alignItems: 'center',
                  // padding: signup ? '20px' : '10px',
                  // borderRadius: signup ? '10px' : '0',
                  // width: signup ? '20%' : '100%',
                  // margin: '0 auto',
                  // gap: signup ? '10px' : '0',
                }}>
                {/* <ButtonAdd 
              bgColor={signup ? 'black' : theme.colors.crimson}
              color={signup ? 'black' : theme.colors.crimson}
              width={signup ? '30px' : 'auto'}
              onClick={handleShowPages}
              style={{
                width: signup ? '120px' : 'auto',
                marginBottom: signup ? '5px' : '0', // Add margin between buttons in column layout
              }}  >
              Switch
            </ButtonAdd> */}
                {/* <TextContainer>
              <ParagraphColor status={1}>Connected</ParagraphColor>
            </TextContainer> */}
                <ButtonAdd
                  bgColor={signup ? '#fff' : theme.colors.grey} // Set background color based on signup
                  // color={signup ? 'black' : theme.colors.crimson}
                  width={signup ? '30px' : 'auto'}
                  onClick={(event: React.SyntheticEvent) => {
                    event.stopPropagation();
                    disableTool(
                      stateTool[TYPES_OF_SOCIAL_NETWORKS.INSTAGRAM].id,
                    ).then(() => {
                      dispatchTool({ type: TYPES_TOOLS.DEACTIVATE_INSTAGRAM });
                      invalidateQuery?.();
                    });
                    // .catch(error => {
                    //   console.log('Error:', error);
                    // });
                  }}
                  style={{
                    width: signup ? '120px' : 'auto',
                    color: signup ? '#64E000' : 'inherit',
                    fontSize: signup ? '14px' : 'inherit', // Set font size based on signup condition
                    fontFamily: signup ? "'Inter', sans-serif" : 'inherit', // Set the font family
                    padding: signup ? '2px 15px' : 'inherit',
                  }}>
                  Connected
                </ButtonAdd>
                {/* <ButtonAdd bgColor={theme.colors.green} disabled>
              Connected
            </ButtonAdd> */}
              </Container>
            )}
            {!activated && (
              <ButtonContainer signup={signup}>
                <ButtonAdd
                  bgColor={signup ? theme.colors.red : theme.colors.grey}
                  onClick={(event: React.SyntheticEvent) => {
                    event.stopPropagation();
                    handleLoginInstagram();
                  }}
                  pad={!activated ? '8px 12px' : '8px 4px'}
                  signup={signup}>
                  Connect
                </ButtonAdd>
              </ButtonContainer>
            )}
          </>
        ) : (
          // When signup is false, show Switch and Disconnect
          <>
            {activated && (
              <Container
                style={{
                  display: 'flex',
                  flexDirection: signup ? 'column' : 'row', // Stack buttons vertically if signup is true
                  justifyContent: signup ? 'center' : 'space-between',
                  alignItems: 'center',
                  padding: signup ? '20px' : 'inherit',
                  borderRadius: signup ? '10px' : 'inherit',
                  width: signup ? '20%' : '', // Set width to 40% when signup is true
                  margin: '0 auto', // Center the container
                  gap: signup ? '10px' : '5px', // Add gap between buttons in column layout
                }}>
                {activated && socialNetworkPages?.instagram && (
                  <>
                    <ButtonAdd
                      bgColor={signup ? 'black' : theme.colors.crimson}
                      color={signup ? 'black' : theme.colors.crimson}
                      pad={!activated ? '8px 12px' : ''}
                      width={signup ? '30px' : 'auto'}
                      onClick={(event: React.SyntheticEvent) => {
                        event.stopPropagation();
                        handleShowPages();
                        refetch();
                      }}
                      style={{
                        width: signup ? '120px' : 'auto',
                        marginBottom: signup ? '5px' : '0', // Add margin between buttons in column layout
                      }}>
                      Switch
                    </ButtonAdd>
                  </>
                )}
                {/* <TextContainer>
              <ParagraphColor status={1}>Connected</ParagraphColor>
            </TextContainer> */}
                <ButtonAdd
                  bgColor={signup ? 'black' : theme.colors.crimson}
                  color={signup ? 'black' : theme.colors.crimson}
                  width={signup ? '30px' : 'auto'}
                  pad={!activated ? '8px 12px' : ''}
                  onClick={(event: React.SyntheticEvent) => {
                    event.stopPropagation();
                    disableTool(
                      stateTool[TYPES_OF_SOCIAL_NETWORKS.INSTAGRAM].id,
                      'Instagram',
                    ).then(() => {
                      dispatchTool({ type: TYPES_TOOLS.DEACTIVATE_INSTAGRAM });
                      invalidateQuery?.();
                      refetch();
                    });
                    // .catch(error => {
                    //   console.log('Error:', error);
                    // });
                  }}
                  style={{
                    width: signup ? '120px' : '',
                  }}>
                  Disconnect
                </ButtonAdd>
                {/* <ButtonAdd bgColor={theme.colors.green} disabled>
              Connected
            </ButtonAdd> */}
              </Container>
            )}
            {!activated && (
              <ButtonContainer signup={signup}>
                <ButtonAdd
                  bgColor={theme.colors.grey}
                  onClick={(event: React.SyntheticEvent) => {
                    event.stopPropagation();
                    handleLoginInstagram();
                  }}
                  pad={!activated ? '8px 12px' : 'inherit'}
                  signup={signup}>
                  Connect
                </ButtonAdd>
              </ButtonContainer>
            )}
          </>
        )}
        {/* <Toggle
        checked={activated}
        onChange={(check: boolean) =>
          check
            ? handleLoginInstagram()
            : disableTool(
                stateTool[TYPES_OF_SOCIAL_NETWORKS.INSTAGRAM].id,
              ).then(() => {
                dispatchTool({ type: TYPES_TOOLS.DEACTIVATE_INSTAGRAM });
                invalidateQuery?.();
              })
        }
      /> */}
      </>
    );
  },
);

export default InstagramToogle;