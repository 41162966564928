/* eslint-disable jsx-a11y/media-has-caption */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-console */

import { useEffect, useRef, useState } from 'react';
import { Divider, Modal } from 'rsuite';
import EmojiPicker from 'emoji-picker-react';
import { Send } from '@rsuite/icons';
import {
  BottomSection,
  Button,
  ButtonsContainer,
  CommentCardWrapper,
  CommentInnerText,
  CommentWrapper,
  CountWrapper,
  DisplayCommentSection,
  Icon,
  IconsWrapper,
  Image,
  ImageContainer,
  ImageTextContainer,
  ImageWrapper,
  Input,
  InputCommentSection,
  InputSection,
  LikeWrapper,
  LikesButton,
  LikesSection,
  PageName,
  ReplyButton,
  SelectIconSection,
  ShareWrapper,
  SingleCommentWrapper,
  SocialWrapper,
  TimeWrapper,
  Wrapper,
  IconSmiley,
  StyledModal,
  ModalContent,
  CommentsSection,
  PostImageBackground,
  CommentIconsWrapper,
} from './FaceBookPreview.style';
import { formatDate, formatTimeDifference } from '../../../lib/format';
import { MEDIA_TYPE } from '../../../lib/globalValues';
import DefaultImage from '../../../assets/images/default.jpg';
import FacebookLikesImage from '../../../assets/icons/fb-likes.svg';
import FacebookLikedThumbImage from '../../../assets/icons/fb-liked.svg';
import FacebookCommentImage from '../../../assets/icons/fb-comment.svg';
import FacebookUnLikeThumbImage from '../../../assets/icons/fb-gray.svg';
import FacebookShareImage from '../../../assets/icons/fb-share.svg';
import {
  getPostCommentsById,
  likeCommentBycommentId,
  sendCommentInCommentById,
  sendCommentInPostById,
  unLikeCommentBycommentId,
} from '../../../services/facebookComments';
import { notification } from '../../../lib/notification';
import { likePostById, unLikePostById } from '../../../services/facebookLike';
import { useCurrentPostStore } from '../../../store';
import smileIcon from '../../../assets/images/smile.png';

const FacebookCommentModal = ({ show, onHide, showDrawer, setUpdate }: any) => {
  const { setComments, post } = useCurrentPostStore();
  const [isEmojiPickerOpen, setIsEmojiPickerOpen] = useState(false);
  const [isMainEmojiPickerOpen, setIsMainEmojiPickerOpen] = useState(false);
  const [isReplyEmojiPickerOpen, setIsReplyEmojiPickerOpen] = useState(false);
  const [isSubReplyEmojiPickerOpen, setIsSubReplyEmojiPickerOpen] =
    useState(false);
  const emojiPickerRef = useRef<HTMLDivElement>(null);

  const [userInput, setUserInput] = useState('');
  const [userCommentInput, setUserCommentInput] = useState('');
  const [isCommentId, setIsCommentId] = useState('');
  const [formatedComments, setFormatedComments] = useState<any[]>([]);
  const [replyComments, setReplyComments] = useState<{
    [key: string]: boolean;
  }>({});
  // const emojiPickerRef = useRef<HTMLDivElement>(null);
  const commentInputRef = useRef<HTMLInputElement | null>(null);

  const toggleEmojiPicker = (picker: string) => {
    if (picker === 'main') setIsMainEmojiPickerOpen(prev => !prev);
    if (picker === 'reply') setIsReplyEmojiPickerOpen(prev => !prev);
    if (picker === 'subReply') setIsSubReplyEmojiPickerOpen(prev => !prev);
  };

  const closeEmojiPicker = (picker: string) => {
    if (picker === 'main') setIsMainEmojiPickerOpen(false);
    if (picker === 'reply') setIsReplyEmojiPickerOpen(false);
    if (picker === 'subReply') setIsSubReplyEmojiPickerOpen(false);
  };

  const handleFocusCommentInput = () => {
    commentInputRef.current?.focus();
  };

  const [likedByMe, setLikeByMe] = useState(false);
  const [fbLikes, setfbLikes] = useState(post?.totalLikes ?? 0);

  const { comments } = useCurrentPostStore();

  console.log('before comments : ', comments);

  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchComments = async () => {
      setIsLoading(true);
      const { data } = await getPostCommentsById(showDrawer.id);
      setComments(data?.data || []);
      setIsLoading(false);
    };
    if (show) fetchComments();
  }, [show, showDrawer.id]);

  const handleLikeClick = async (commentId: string, likesValue: any) => {
    if (!likesValue.summary.has_liked) {
      const res = await likeCommentBycommentId(commentId);
      if (res) {
        setUpdate((prev: any) => prev + 1);
        notification({
          type: 'success',
          title: 'FaceBook Comment',
          description: 'Comment Liked Successfully.',
        });
      }
    } else {
      const res = await unLikeCommentBycommentId(commentId);
      if (res) {
        setUpdate((prev: any) => prev + 1);
        notification({
          type: 'success',
          title: 'FaceBook Comment',
          description: 'Comment UnLiked Successfully.',
        });
      }
    }
  };

  const handleReplyClick = async (commentId: string) => {
    setIsCommentId(commentId);
    setReplyComments(prevReplyComments => ({
      ...prevReplyComments,
      [commentId]: !prevReplyComments[commentId],
    }));
  };

  const handleInput = (e: any) => {
    const { value } = e.target;
    setUserInput(value);
  };

  const handleSubInput = (e: any) => {
    const { value } = e.target;
    setUserCommentInput(value);
  };

  const handleUserCommentInputEmojiClick = (emojiObject: any) => {
    setUserCommentInput(prev => prev + emojiObject.emoji);
  };

  const handleSendSubComment = async () => {
    if (userCommentInput === '') {
      notification({
        type: 'info',
        title: 'FaceBook Post Comment',
        description: 'Type Something...!',
      });
    }
    if (userCommentInput !== '') {
      const res = await sendCommentInCommentById(isCommentId, userCommentInput);
      if (res) {
        setUserCommentInput('');
        if (res.data && res.data.id && res.data.message) {
          setComments([res.data, ...comments]);
        }
        setUpdate((prev: any) => prev + 1);
        notification({
          type: 'success',
          title: 'FaceBook Post Comment',
          description: 'Comment Posted Successfully.',
        });
      }
    }
  };

  const handleSendComment = async () => {
    if (userInput === '') {
      notification({
        type: 'info',
        title: 'FaceBook Post Comment',
        description: 'Type Something...!',
      });
    }
    if (userInput !== '') {
      const res = await sendCommentInPostById(showDrawer.id, userInput);
      console.log('Res : ', res);
      if (res) {
        setUserInput('');
        if (res.data && res.data.id && res.data.message) {
          setComments([res.data, ...comments]);
        }
        setUpdate((prev: any) => prev + 1);
        notification({
          type: 'success',
          title: 'FaceBook Post Comment',
          description: 'Comment Posted Successfully.',
        });
      }
    }
  };

  const handleSubmitLike = async (id: string) => {
    if (!likedByMe) {
      setLikeByMe(true);
      const { data } = await likePostById(id);
      if (data) {
        setfbLikes(post?.totalLikes ?? 0);
        notification({
          type: 'success',
          title: 'FaceBook Post',
          description: 'Post Liked successfully.',
        });
      } else {
        setLikeByMe(false);
      }
    } else {
      setLikeByMe(false);
      const { data } = await unLikePostById(id);
      if (data) {
        setfbLikes((post?.totalLikes ?? 1) - 1);
        notification({
          type: 'success',
          title: 'FaceBook Post',
          description: 'Post UnLiked successfully.',
        });
      } else {
        setLikeByMe(true);
      }
    }
  };

  useEffect(() => {
    formateComments(comments ?? 0);
  }, [comments]);

  const formateComments = async (data: any) => {
    const updatedComments: any[] = [];
    const allInnerComments: any[] = [];
    data?.forEach((element: any) => {
      const { comments: commentsL2 } = element;
      if (commentsL2 != null) {
        const l2Comments: any[] = [];
        //  Level 2
        commentsL2?.data.forEach((e2: any) => {
          const element2 = data.find((d2: any) => d2.id === e2.id);

          //  Level 3
          const { comments: commentsL3 } = element2;
          if (commentsL3 != null) {
            const l3Comments: any[] = [];
            commentsL3?.data.forEach((e3: any) => {
              const element3 = data.find((d2: any) => d2.id === e3.id);

              l3Comments.push(element3);
              allInnerComments.push(element3);
            });
            const newL2Element = { ...element2, comments: l3Comments };
            l2Comments.push(newL2Element);
            allInnerComments.push(element2);
          } else {
            l2Comments.push(element2);
            allInnerComments.push(element2);
          }
        });
        const newElement = { ...element, comments: l2Comments };
        updatedComments.push(newElement);
      } else {
        updatedComments.push(element);
      }
    });

    const filterd = updatedComments.filter(
      item => !allInnerComments.some(item2 => item2.id === item.id),
    );
    setFormatedComments(filterd);
  };

  const handleEmojiClick = (emojiObject: any) => {
    setUserInput(prev => prev + emojiObject.emoji);
  };

  if (isLoading) return <div>Loading comments...</div>;

  console.log('comments : ', comments);

  return (
    <StyledModal size="sm" show={show} onHide={onHide} className="custom-modal">
      <Modal size="sm" show={show} onHide={onHide} className="custom-modal">
        <Modal.Header>
          {/* <Modal.Title>{`${showDrawer?.pageName} Post`}</Modal.Title> */}
        </Modal.Header>
        {/* <Divider /> */}
        <Wrapper>
          <Image src={showDrawer?.pageProfilePicture} alt="Page Image" />
          <TimeWrapper>
            <PageName>{showDrawer?.pageName || ''}</PageName>
            <span>{formatDate(showDrawer?.publishedAt)}</span>
          </TimeWrapper>
        </Wrapper>
        <Wrapper>
          <CommentInnerText>{showDrawer?.text}</CommentInnerText>
        </Wrapper>

        <Modal.Body>
          <ModalContent ref={emojiPickerRef}>
            <ImageContainer>
              {showDrawer?.media?.type === MEDIA_TYPE.IMAGE && (
                <PostImageBackground src={showDrawer.media.image} />
              )}
              {showDrawer?.media?.type === MEDIA_TYPE.VIDEO && (
                <video controls autoPlay style={{ width: '100%' }}>
                  <source src={showDrawer.media.video} type="video/mp4" />
                  Your browser does not support the video tag.
                </video>
              )}
              {showDrawer?.media?.type === MEDIA_TYPE.TEXT && (
                <PostImageBackground src={DefaultImage} />
              )}
            </ImageContainer>
            <BottomSection>
              <LikesSection>
                <ImageWrapper>
                  <img src={FacebookLikesImage} alt="Like Icon" />
                  <span>{fbLikes}</span>
                </ImageWrapper>
                <CountWrapper>
                  <span>{comments?.length} Comments</span>
                </CountWrapper>
              </LikesSection>
              <Divider />
              <SocialWrapper>
                <Button onClick={() => handleSubmitLike(showDrawer?.id)}>
                  <LikeWrapper color={likedByMe ? '#0284c7' : ''}>
                    <img
                      src={
                        likedByMe
                          ? FacebookLikedThumbImage
                          : FacebookUnLikeThumbImage
                      }
                      alt="like"
                    />
                    <span>Like</span>
                  </LikeWrapper>
                </Button>
                <Button onClick={handleFocusCommentInput}>
                  <CommentWrapper>
                    <img src={FacebookCommentImage} alt="Comment" />
                    <span>Comment</span>
                  </CommentWrapper>
                </Button>
              </SocialWrapper>
              <Divider />
            </BottomSection>
            <CommentsSection>
              <DisplayCommentSection>
                {formatedComments?.map(
                  ({
                    from,
                    message,
                    id,
                    likes,
                    created_time,
                    comments: l2Comments,
                  }: any) => (
                    <CommentCardWrapper key={from?.id}>
                      <img src={from?.picture?.data?.url} alt={from?.name} />
                      <ImageTextContainer>
                        <SingleCommentWrapper>
                          <span>{from?.name}</span>
                          <p>{message}</p>
                        </SingleCommentWrapper>
                        <ButtonsContainer>
                          <span>{formatTimeDifference(created_time)}</span>
                          <LikesButton
                            liked={likes.summary.total_count > 0}
                            onClick={() => handleLikeClick(id, likes)}>
                            Like
                          </LikesButton>
                          <ReplyButton onClick={() => handleReplyClick(id)}>
                            Reply
                          </ReplyButton>
                          {likes.summary.total_count > 0 && (
                            <img src={FacebookLikesImage} alt="Like Icon" />
                          )}
                        </ButtonsContainer>
                        {replyComments[id] && (
                          <InputCommentSection>
                            <img
                              src={showDrawer?.pageProfilePicture}
                              alt="page"
                            />
                            <InputSection>
                              <Input
                                value={userCommentInput}
                                placeholder={`Comment as ${showDrawer?.pageName}`}
                                onChange={e => handleSubInput(e)}
                              />
                              <CommentIconsWrapper>
                                <div>
                                  <IconSmiley
                                    src={smileIcon}
                                    alt="smiley icon"
                                    onClick={() => toggleEmojiPicker('main')}
                                    className="smileIcon"
                                  />
                                </div>
                                {isMainEmojiPickerOpen ? (
                                  <EmojiPicker
                                    lazyLoadEmojis
                                    skinTonesDisabled
                                    onEmojiClick={emoji => {
                                      handleUserCommentInputEmojiClick(emoji);
                                      closeEmojiPicker('main');
                                    }}
                                  />
                                ) : (
                                  <></>
                                )}
                                <div>
                                  <Send
                                    style={{ fontSize: 24, color: 'black' }}
                                    onClick={handleSendSubComment}
                                  />
                                </div>
                              </CommentIconsWrapper>
                            </InputSection>
                          </InputCommentSection>
                        )}

                        {l2Comments &&
                          l2Comments?.map(
                            ({
                              from: from2,
                              message: message2,
                              id: id2,
                              likes: likes2,
                              created_time: created_time2,
                              comments: l3Comments,
                            }: any) => (
                              <CommentCardWrapper key={from2?.id}>
                                <img
                                  src={from2?.picture?.data?.url}
                                  alt={from2?.name}
                                />
                                <ImageTextContainer>
                                  <SingleCommentWrapper>
                                    <span>{from2?.name}</span>
                                    <p>{message2}</p>
                                  </SingleCommentWrapper>
                                  <ButtonsContainer>
                                    <span>
                                      {formatTimeDifference(created_time2)}
                                    </span>
                                    <LikesButton
                                      liked={likes2.summary.total_count > 0}
                                      onClick={() =>
                                        handleLikeClick(id2, likes2)
                                      }>
                                      Like
                                    </LikesButton>
                                    <ReplyButton
                                      onClick={() => handleReplyClick(id2)}>
                                      Reply
                                    </ReplyButton>
                                    {likes2.summary.total_count > 0 && (
                                      <img
                                        src={FacebookLikesImage}
                                        alt="Like Icon"
                                      />
                                    )}
                                  </ButtonsContainer>

                                  {replyComments[id2] && (
                                    <InputCommentSection>
                                      <img
                                        src={showDrawer?.pageProfilePicture}
                                        alt="page"
                                      />
                                      <InputSection>
                                        <Input
                                          value={userCommentInput}
                                          placeholder={`Comment as ${showDrawer?.pageName}`}
                                          onChange={e => handleSubInput(e)}
                                        />
                                        <IconsWrapper>
                                          <div>
                                            <IconSmiley
                                              src={smileIcon}
                                              alt="smiley icon"
                                              onClick={() =>
                                                toggleEmojiPicker('subReply')
                                              }
                                              className="smileIcon"
                                            />
                                          </div>
                                          {isSubReplyEmojiPickerOpen ? (
                                            <EmojiPicker
                                              lazyLoadEmojis
                                              skinTonesDisabled
                                              onEmojiClick={emoji => {
                                                handleEmojiClick(emoji);
                                                closeEmojiPicker('subReply');
                                              }}
                                            />
                                          ) : (
                                            <></>
                                          )}
                                          <div>
                                            <Send
                                              style={{
                                                fontSize: 24,
                                                color: 'black',
                                              }}
                                              onClick={handleSendSubComment}
                                            />
                                          </div>
                                        </IconsWrapper>
                                      </InputSection>
                                    </InputCommentSection>
                                  )}

                                  {l3Comments &&
                                    l3Comments?.map(
                                      ({
                                        from: from3,
                                        message: message3,
                                        id: id3,
                                        likes: likes3,
                                        created_time: created_time3,
                                      }: any) => (
                                        <CommentCardWrapper key={from3?.id}>
                                          <img
                                            src={from3?.picture?.data?.url}
                                            alt={from3?.name}
                                          />
                                          <ImageTextContainer>
                                            <SingleCommentWrapper>
                                              <span>{from3?.name}</span>
                                              <p>{message3}</p>
                                            </SingleCommentWrapper>
                                            <ButtonsContainer>
                                              <span>
                                                {formatTimeDifference(
                                                  created_time3,
                                                )}
                                              </span>
                                              <LikesButton
                                                liked={
                                                  likes3.summary.total_count > 0
                                                }
                                                onClick={() =>
                                                  handleLikeClick(id3, likes3)
                                                }>
                                                Like
                                              </LikesButton>

                                              {likes3.summary.total_count >
                                                0 && (
                                                <img
                                                  src={FacebookLikesImage}
                                                  alt="Like Icon"
                                                />
                                              )}
                                            </ButtonsContainer>
                                          </ImageTextContainer>
                                        </CommentCardWrapper>
                                      ),
                                    )}
                                </ImageTextContainer>
                              </CommentCardWrapper>
                            ),
                          )}
                      </ImageTextContainer>
                    </CommentCardWrapper>
                  ),
                )}
              </DisplayCommentSection>
            </CommentsSection>
          </ModalContent>
        </Modal.Body>
        <Modal.Footer>
          <InputCommentSection>
            <img src={showDrawer?.pageProfilePicture} alt="page" />
            <InputSection>
              <Input
                ref={commentInputRef}
                value={userInput}
                placeholder={`Comment as ${showDrawer?.pageName}`}
                onChange={e => handleInput(e)}
              />
              <IconsWrapper>
                <div>
                  <IconSmiley
                    src={smileIcon}
                    alt="smiley icon"
                    onClick={() => toggleEmojiPicker('reply')}
                    className="smileIcon"
                  />
                </div>
                {isReplyEmojiPickerOpen ? (
                  <EmojiPicker
                    lazyLoadEmojis
                    skinTonesDisabled
                    onEmojiClick={emoji => {
                      handleEmojiClick(emoji);
                      closeEmojiPicker('reply');
                    }}
                  />
                ) : (
                  <></>
                )}
                <div>
                  <Send
                    style={{ fontSize: 24, color: 'black' }}
                    onClick={handleSendComment}
                  />
                </div>
              </IconsWrapper>
            </InputSection>
          </InputCommentSection>
        </Modal.Footer>
      </Modal>
    </StyledModal>
  );
};

export default FacebookCommentModal;
