import React from 'react';

type DisplayTrialEndProps = {
  trialEndDate: string | null;
  payment: {
    subscriptionStatus: string;
    selectedPlan: string;
  };
  hasAccess: boolean | undefined;
};

const DisplayTrialEnd = ({
  trialEndDate,
  payment,
  hasAccess,
}: DisplayTrialEndProps) => {
  const calculateDaysLeft = (endDate: string): number => {
    const now = new Date();
    const end = new Date(endDate);
    const difference = end.getTime() - now.getTime();
    return Math.floor(difference / (1000 * 60 * 60 * 24));
  };

  const daysLeft = trialEndDate ? calculateDaysLeft(trialEndDate) : null;

  const annualPriceId = process.env.REACT_APP_STRIPE_ANNUAL_PRICE_ID || '';
  const monthlyPriceId = process.env.REACT_APP_STRIPE_MONTHLY_PRICE_ID || '';

  const formatDate = (dateString: string): string => {
    const date = new Date(dateString);
    return date.toLocaleDateString('en-US', {
      month: 'long',
      day: 'numeric',
      year: 'numeric',
    });
  };

  if (payment.subscriptionStatus === 'trial_expired' && !hasAccess) {
    return <p>Your Free Trial has ended.</p>;
  }

  if (payment?.subscriptionStatus === 'active_lifetime') {
    return <p>You have Lifetime access.</p>;
  }

  if (
    payment.subscriptionStatus !== 'trialing' &&
    payment.selectedPlan === annualPriceId &&
    daysLeft !== null &&
    daysLeft > 0
  ) {
    const formattedDate = trialEndDate ? formatDate(trialEndDate) : '';
    return (
      <p>
        Your Annual subscription ends in {daysLeft} day
        {daysLeft > 1 ? 's' : ''}, on {formattedDate}.
      </p>
    );
  }

  if (
    payment.subscriptionStatus !== 'trialing' &&
    daysLeft !== null &&
    daysLeft > 0
  ) {
    const formattedDate = trialEndDate ? formatDate(trialEndDate) : '';
    return (
      <p>
        Your Monthly Subscription ends in {daysLeft} day
        {daysLeft > 1 ? 's' : ''}, on {formattedDate}.
      </p>
    );
  }

  if (trialEndDate && daysLeft !== null && daysLeft > 0) {
    const formattedDate = formatDate(trialEndDate);
    return <p>Your free trial ends on {formattedDate}.</p>;
  }

  return <p>Your trial has ended.</p>;
};

export default DisplayTrialEnd;
