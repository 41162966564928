import { TYPES_OF_SOCIAL_NETWORKS } from '../../lib/globalValues';

export function getWelcomeMessage(socialName: string): string {
  switch (socialName) {
    case TYPES_OF_SOCIAL_NETWORKS.FACEBOOK:
      return 'Connect your Facebook business pages. You need to be an administrator of the page.';
    case TYPES_OF_SOCIAL_NETWORKS.INSTAGRAM:
      return 'Connect you Instagram business pages. They need to be connected to a Facebook business page.';
    case TYPES_OF_SOCIAL_NETWORKS.GOOGLE:
      return 'Connect your Google my Business pages. You need to be an administrator of the page.';
    case TYPES_OF_SOCIAL_NETWORKS.TWITTER:
      return 'Post your tweets and respond to messages';
    default:
      return '';
  }
}

export const getTitleForStep = (step: number): string => {
  switch (step) {
    case 0:
      return 'Create an account';
    // return "Sign in or Sign up";
    case 1:
      return 'Create an account';
    case 2:
      return 'Create an account';
    case 3:
      return 'Connect your accounts';
    case 4:
      return 'Finish!';
    default:
      return 'Welcome';
  }
};

export const getTextForStep = (step: number): string => {
  switch (step) {
    case 0:
      return `Exciting times ahead with Dinabite! You will be managing your Facebook, Instagram, Google my Business, TikTok and Twitter/X like a pro.
        Let’s get started!`;
    // return "Exciting times ahead with Dinabite.ai! You'll be posting on Facebook, Instagram, Twitter/X, and Google My Business like a pro. Let's get started";
    case 1:
      return `Exciting times ahead with Dinabite!You'll be posting, managing messages, reviews, and promotions on all social media and marketing platforms.All very easily, and like a pro.
        Let’s get started!`;
    // return "Dinabite.ai is your personal assistant for growing your online presence with powerful analytics and easy post scheduling. Ready to rock?";
    case 2:
      return `Exciting times ahead with Dinabite! You will be managing your Facebook, Instagram, Google my Business, TikTok and Twitter/X like a pro.
        Let’s get started!`;
    // return "Connect your social media accounts to enhance your online presence with our unique AI, easy post scheduling, and a lot more.Ready to rock";
    case 3:
      return `Connect your social media accounts to enhance your online presence with our unique AI, easy post scheduling, and a lot more.
        Ready to rock?`;
    // return "Dinabite is your personal asistant for growing your online presence with powerfull analytics and easy post scheduling. Ready to rock?";
    case 4:
      return 'Dinabite is your personal assistant for growing your online presence with powerful analytics and easy post scheduling. Ready to rock?';
    default:
      return 'Dinabite is your personal assistant for growing your online presence with powerful analytics and easy post scheduling. Ready to rock?';
  }
};

// socialMediaMessages.ts

export const socialMediaMessages = {
  facebook: {
    text: 'Connect your Facebook account to get started with social media integration.',
  },
  instagram: {
    text: 'Connect your Instagram account to enhance your social media presence.',
  },
  google: {
    text: 'Connect your Google account for better search engine visibility.',
  },
  twitter: {
    text: 'Connect your Twitter account to reach more people with your tweets.',
  },
};
