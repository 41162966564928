import axios from '../lib/axios';
import {
  notificationError,
  notificationWithSimpleNodes,
} from '../lib/notification';

export const disableTool = async (
  toolId: number | undefined,
  toolName?: string,
) => {
  try {
    if (!toolId) {
      throw new Error('Could not be disabled.');
    }
    const response = await axios.put(`/tools/disabled/${toolId}`);
    notificationWithSimpleNodes({
      type: 'open',
      title: '',
      description: `${toolName} was disabled`,
    });
    return response;
  } catch (error) {
    notificationWithSimpleNodes({
      type: 'open',
      title: '',
      description: 'Error',
    });
    return error;
  }
};

export const disableToolid3 = async (
  toolId: number | undefined,
  pageId: number | undefined,
  companyId: number | undefined,
) => {
  try {
    if (!toolId || !pageId || !companyId) {
      throw new Error('Tool ID, Page ID, or Company ID is missing.');
    }
    const response = await axios.put(
      `/tools/disabledid/${toolId}/${pageId}/${companyId}`,
    );

    notificationWithSimpleNodes({
      type: 'open',
      title: '',
      description: 'This social network was disabled',
    });

    return response;
  } catch (error) {
    notificationError({
      title: 'Error',
      description: 'Error disabling the tool.',
    });

    return error;
  }
};
