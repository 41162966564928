import { TYPES_TOOLS } from '../actions/toolsActions';
import axios from '../lib/axios';
import { TYPES_OF_SOCIAL_NETWORKS } from '../lib/globalValues';
import {
  notificationError,
  notificationWithSimpleNodes,
} from '../lib/notification';
import { assignTool } from './assignTool';

/* eslint-disable @typescript-eslint/no-explicit-any */
export const handleTwitterToolChange = async ({
  setIsTwitter,
  dispatchTool,
  companyId,
  invalidateQuery,
}: {
  setIsTwitter: (arg: boolean) => void;
  dispatchTool: any;
  companyId?: number;
  invalidateQuery?: () => void;
}) => {
  let done = false;
  setIsTwitter(true);
  await localStorage.removeItem(TYPES_OF_SOCIAL_NETWORKS.TWITTER);
  let twitterStorage = localStorage.getItem(TYPES_OF_SOCIAL_NETWORKS.TWITTER);
  if (twitterStorage) {
    await sendTwitterToken({
      dispatchTool,
      companyId,
      twitterStorage,
      invalidateQuery,
    });
    await localStorage.removeItem(TYPES_OF_SOCIAL_NETWORKS.TWITTER);
  } else {
    await window.addEventListener(
      'storage',
      async e => {
        twitterStorage = localStorage.getItem(TYPES_OF_SOCIAL_NETWORKS.TWITTER);
        if (twitterStorage && !done && e.key === 'twitter') {
          await sendTwitterToken({
            dispatchTool,
            companyId,
            twitterStorage,
            invalidateQuery,
          });
          await localStorage.removeItem(TYPES_OF_SOCIAL_NETWORKS.TWITTER);
          done = true;
        }
      },
      { once: false },
    );
  }

  setIsTwitter(false);
};

const sendTwitterToken = async ({
  dispatchTool,
  companyId,
  twitterStorage,
  invalidateQuery,
}: {
  dispatchTool: any;
  companyId?: number;
  twitterStorage: string;
  invalidateQuery?: () => void;
}) => {
  try {
    const twitterData = JSON.parse(twitterStorage);
    const defaultContent = [
      {
        type: TYPES_OF_SOCIAL_NETWORKS.TWITTER,
        accessToken: twitterData.accessToken,
        userId: twitterData.userId,
        enabled: true,
        profilePic: twitterData.profilePic,
      },
    ];

    if (companyId) {
      const response = await assignTool({
        companyId,
        tool: defaultContent,
      });

      if (response.data) {
        dispatchTool({
          type: TYPES_TOOLS.ACTIVATE_TWITTER,
          payload: response.data[0],
        });
      }
    } else {
      dispatchTool({
        type: TYPES_TOOLS.ACTIVATE_TWITTER,
        payload: defaultContent[0],
      });
    }

    invalidateQuery?.();

    notificationWithSimpleNodes({
      type: 'open',
      title: '',
      description: 'Twitter was enabled',
    });
  } catch (error) {
    dispatchTool({ type: TYPES_TOOLS.DEACTIVATE_TWITTER });
    notificationError({
      title: 'Error',
      description:
        error?.response?.data?.message || 'The twitter could not be added.',
    });
  }
};

export const getTwitterPost = async (postId: string | undefined) => {
  try {
    const dataPost = axios.get(`/posts/twitter/${postId}`);
    return dataPost;
  } catch (err) {
    notificationError({
      title: 'Error',
      description: 'Error in obtaining the data of the publication',
    });

    return err;
  }
};
