/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-nested-ternary */

import { useState, useEffect } from 'react';
import { useFormik } from 'formik';
import { Col, FlexboxGrid, Row, Toggle } from 'rsuite';
import axios from '../../lib/axios';
import Countries from '../../assets/data/countries.json';
import AdressIcon from '../../assets/icons/address.svg';
import CityIcon from '../../assets/icons/city.svg';
import EmailIcon from '../../assets/icons/email.svg';
import KeyIcon from '../../assets/icons/key.svg';
import PhoneIcon from '../../assets/icons/phone.svg';
import SaveIcon from '../../assets/icons/save.svg';
import UserIcon from '../../assets/icons/user.svg';
import WebIcon from '../../assets/icons/web.svg';
import IconInput from '../../components/inputs/IconInput/IconInput';
import { useAuth } from '../../context/AuthContext';
import { formatDate } from '../../lib/format';
import { changePassword } from '../../services/changePassword';
import { updateCompany } from '../../services/updateCompany';
import { updateUser } from '../../services/updateUser';
import {
  BillingContainer,
  Card,
  ColSelect,
  Container,
  FeaturesCardContainer,
  GroupSelect,
  HeaderContainer,
  IconSelect,
  PError,
  PlanContainer,
  PriceCardContainer,
  PriceCardWrapper,
  SaveButton,
  SelectStyle,
  TitleWrapper,
  ToogleContainer,
} from './Account.styles';
import {
  billingEmailSchema,
  changePasswordSchema,
  userCompanySchema,
  userInfoSchema,
} from './validation';
import RectangleButton from '../../components/rectangleButton/RectangleButton';
import { updateStripeCustomer } from '../../services/updateStripeCustomer';
import { notificationError } from '../../lib/notification';
import BasicPlan from '../plans/basicPlan/BasicPlan';
import PremiumPlan from '../plans/premiumPlan/PremiumPlan';
import PlanCard from '../plans/planCard/PlanCard';
import { freePlanFeatures } from '../../assets/data/planFeatures';
import {
  ButtonContainer,
  FlexContainer,
  FlexContainer2,
  FlexContainer3,
  Heading,
  para,
} from '../plans/planCard/PlanCard.style';
import CheckCircleIcon from '../../assets/icons/checkCircle.svg';

const Account = () => {
  const { user, setUser, companySelected } = useAuth();
  const [displayPremiumPlan, setDisplayPremiumPlan] = useState<boolean>(false);
  const [billingEmail, setBillingEmail] = useState<string>('');
  const [lastUpdateTime, setLastUpdateTime] = useState<string | null>(null);
  const [loading, setLoading] = useState({
    monthly: false,
    annual: false,
    lifetime: false,
  });
  const [isAnnual, setIsAnnual] = useState(false);
  const [pricingData, setPricingData] = useState({
    country: '',
    prices: [] as { currency: string; price: string; type: string }[],
  });

  useEffect(() => {
    const { subscriptionStatus, invoiceStatus } =
      user?.companies?.[0]?.payment ?? {};

    if (subscriptionStatus === 'active_lifetime' && invoiceStatus === 'paid') {
      setDisplayPremiumPlan(false);
    } else if (subscriptionStatus === 'active' && invoiceStatus === 'paid') {
      console.log('subscriptionStatus', subscriptionStatus);
      setDisplayPremiumPlan(true);
    } else {
      setDisplayPremiumPlan(false);
    }
  }, [user]);

  useEffect(() => {
    const getPayment = async () => {
      if (companySelected) {
        const response = await axios.get(
          `stripe/customer?companyId=${companySelected?.id}`,
        );

        setBillingEmail(response.data.email);
      }
    };
    getPayment();
  }, [companySelected, billingEmail]);

  const userInfoFormik = useFormik({
    initialValues: {
      firstName: user?.firstName || '',
      lastName: user?.lastName || '',
      id: user?.id || '',
      email: user?.email || '',
    },
    onSubmit: async values => {
      const data = { ...values };
      const { id, ...rest } = data;

      const updatedUser = await updateUser(+id, rest);
      if (updatedUser.data) {
        setUser({ ...data, companies: user?.companies });
        setLastUpdateTime(formatDate(new Date())); // Update the last update time
      }
    },
    enableReinitialize: true,
    validationSchema: userInfoSchema(user?.email),
  });

  const userBillingInfoFormik = useFormik({
    initialValues: {
      billingEmail: billingEmail || '',
    },
    onSubmit: async values => {
      const data = { ...values };

      const updatedStripeCustomer = await updateStripeCustomer(
        companySelected?.id,
        data.billingEmail,
      );

      if (updatedStripeCustomer.data) {
        setBillingEmail(updatedStripeCustomer.data?.billingEmail);
      }
    },
    enableReinitialize: true,
    validationSchema: billingEmailSchema,
  });

  const changePasswordFormik = useFormik({
    initialValues: {
      oldPassword: '',
      password: '',
      repeatPassword: '',
    },
    onSubmit: async values => {
      const changedPassword = await changePassword(values);
      if (changedPassword.data) {
        changePasswordFormik.resetForm();
      }
    },
    validationSchema: changePasswordSchema,
  });

  const companyInfoFormik = useFormik({
    initialValues: {
      id: user?.companies[0].id || 0,
      name: user?.companies[0].name || '',
      phone: user?.companies[0].phone || '',
      phoneCode: user?.companies[0].phoneCode || '',
      address: user?.companies[0].address || '',
      country: user?.companies[0].country || '',
      city: user?.companies[0].city || '',
      zipCode: user?.companies[0].zipCode || '',
      webLink: user?.companies[0].webLink || '',
    },
    onSubmit: async (values: {
      id: number;
      name: string;
      phone: string;
      phoneCode: string;
      address: string;
      country: string;
      city: string;
      zipCode: string;
      webLink: string;
    }) => {
      if (user) {
        const { id, ...data } = values;
        const updatedCompany = await updateCompany(id, data);
        if (updatedCompany.data) {
          const selectUpdated = user.companies.filter(
            company => company.id !== id,
          );

          setUser({
            ...user,
            companies: [...selectUpdated, values],
          });
          setLastUpdateTime(formatDate(new Date())); // Update the last update time
        }
      }
    },
    enableReinitialize: true,
    validationSchema: userCompanySchema,
  });

  const handleSave = () => {
    if (userInfoFormik.initialValues !== userInfoFormik.values) {
      userInfoFormik.submitForm();
    }

    if (companyInfoFormik.initialValues !== companyInfoFormik.values) {
      companyInfoFormik.submitForm();
    }

    if (userBillingInfoFormik.initialValues !== userBillingInfoFormik.values) {
      userBillingInfoFormik.submitForm();
    }

    if (changePasswordFormik.values.password.length > 0) {
      changePasswordFormik.submitForm();
    }
  };

  async function handlePlanSelection(
    newPriceId: string,
    planType: 'monthly' | 'annual' | 'lifetime',
  ) {
    setLoading(prev => ({ ...prev, [planType]: true }));
    try {
      if (companySelected?.id) {
        const response = await axios.post('stripe/create-checkout-session', {
          companyId: companySelected.id,
          priceId: newPriceId,
        });

        window.location.href = response.data.url;
      }
    } catch (error) {
      console.error('Error upgrading plan:', error);
      alert('Failed to switch plans. Please try again.');
    } finally {
      setLoading(prev => ({ ...prev, [planType]: false }));
    }
  }

  const handleManageBillingSubmit = async () => {
    try {
      if (companySelected?.id) {
        const ManageBillingURL = await axios.post(
          `stripe/create-customer-portal-session`,
          { companyId: companySelected.id },
        );

        window.location.href = ManageBillingURL.data;
      }
    } catch (err) {
      notificationError({
        title: 'Error',
        description: 'Error',
      });
    }
  };

  // Define price IDs
  const monthlyPriceId: string =
    pricingData?.country === 'India'
      ? process.env.REACT_APP_STRIPE_INR_MONTHLY_PRICE_ID ?? ''
      : process.env.REACT_APP_STRIPE_USD_MONTHLY_PRICE_ID ?? '';

  const annualPriceId: string =
    pricingData?.country === 'India'
      ? process.env.REACT_APP_STRIPE_INR_ANNUAL_PRICE_ID ?? ''
      : process.env.REACT_APP_STRIPE_USD_ANNUAL_PRICE_ID ?? '';

  const lifetimePriceId: string =
    pricingData?.country === 'India'
      ? process.env.REACT_APP_STRIPE_INR_LIFETIME_PRICE_ID ?? ''
      : process.env.REACT_APP_STRIPE_USD_LIFETIME_PRICE_ID ?? '';

  // const selectedPriceId = isAnnual ? annualPriceId : monthlyPriceId;

  const payment = user?.companies?.[0]?.payment;
  const userSelectedPlan = payment?.selectedPlan;
  const subscriptionStatus = payment?.subscriptionStatus;

  useEffect(() => {
    if (payment) {
      const toogleTrue =
        userSelectedPlan === annualPriceId && userSelectedPlan !== '';
      setIsAnnual(toogleTrue);
    } else {
      setIsAnnual(false);
    }
  }, [payment]);

  useEffect(() => {
    const fetchPricingData = async () => {
      try {
        const response = await axios.get(`stripe/pricing`);
        if (response?.data) {
          setPricingData(response.data);
        }
      } catch (error) {
        console.error('Failed to fetch pricing data:', error);
      }
    };

    fetchPricingData();
  }, []);

  console.log('pricingData', pricingData);

  const selectedPlan = pricingData?.prices?.find(plan =>
    isAnnual ? plan.type === 'annually' : plan.type === 'monthly',
  );

  const lifetimePlan = pricingData?.prices?.find(
    plan => plan.type === 'Lifetime',
  );

  return (
    <Container>
      <BillingContainer>
        <Col xs={24} md={24}>
          <TitleWrapper>
            <Heading>Plans & Billing</Heading>
            <div>
              {' '}
              {(() => {
                const calculateDaysLeft = (endDate: string): number => {
                  const now = new Date();
                  const end = new Date(endDate);
                  const difference = end.getTime() - now.getTime();
                  return Math.max(
                    0,
                    Math.ceil(difference / (1000 * 60 * 60 * 24)),
                  ); // Prevent negative days
                };

                const formatDateFunc = (dateString: string): string => {
                  const date = new Date(dateString);
                  if (Number.isNaN(date.getTime())) {
                    // Use Number.isNaN
                    return 'Invalid Date';
                  }
                  return date.toLocaleDateString('en-US', {
                    month: 'long',
                    day: 'numeric',
                    year: 'numeric',
                  });
                };

                const daysLeft = payment?.trialEndDate
                  ? calculateDaysLeft(payment?.trialEndDate)
                  : null;

                if (subscriptionStatus === 'active_lifetime') {
                  return <p>You have lifetime access.</p>;
                }

                if (subscriptionStatus === 'trialing') {
                  if (
                    payment?.trialEndDate &&
                    daysLeft !== null &&
                    daysLeft > 0
                  ) {
                    const formattedDate = formatDateFunc(payment?.trialEndDate);
                    return (
                      <p>
                        Your Free Trial ends in {daysLeft} day
                        {daysLeft > 1 ? 's' : ''}, on {formattedDate}.
                      </p>
                    );
                  }
                  return <p>Your Free Trial has ended.</p>;
                }

                if (subscriptionStatus === 'active' && payment?.trialEndDate) {
                  const formattedDate = formatDateFunc(payment?.trialEndDate);
                  const planType =
                    userSelectedPlan === annualPriceId ? 'Annual' : 'Monthly';
                  return (
                    <p>
                      Your {planType} subscription renews on {formattedDate}.
                    </p>
                  );
                }

                if (
                  subscriptionStatus === 'trial_expired' &&
                  !payment?.hasAccess
                ) {
                  return <p>Your Free Trial has ended.</p>;
                }

                // Default case: No valid status or trial end date
                return (
                  <p style={{ padding: '7px' }}>
                    No subscription or trial information available.
                  </p>
                );
              })()}
            </div>
            {displayPremiumPlan && (
              <RectangleButton
                title="Manage Subscriptions"
                onClick={handleManageBillingSubmit}
              />
            )}
          </TitleWrapper>
        </Col>
        <PriceCardWrapper>
          <FeaturesCardContainer>
            <h3>Features</h3>
            {freePlanFeatures.map(feature => (
              <FlexContainer key={feature.heading}>
                <FlexContainer2>
                  <img src={CheckCircleIcon} alt="circle with tick icon" />
                  <p>
                    <strong>{feature.heading}:</strong>
                  </p>
                </FlexContainer2>
                <FlexContainer3>
                  <p>{feature.description}</p>
                </FlexContainer3>
              </FlexContainer>
            ))}
          </FeaturesCardContainer>
          <PriceCardContainer>
            <h3>Pricing</h3>

            <ToogleContainer
              disabled={subscriptionStatus === 'active_lifetime'}>
              <span style={{ padding: '5px' }}>Monthly</span>
              <Toggle
                checked={isAnnual}
                onChange={setIsAnnual}
                disabled={subscriptionStatus === 'active_lifetime'}
              />
              <span>
                Annual{' '}
                <i>Save {pricingData.country === 'India' ? '31%' : '37%'}</i>
              </span>
            </ToogleContainer>
            {isAnnual ? (
              <h3>{selectedPlan?.price} per annum</h3>
            ) : (
              <h3>{selectedPlan?.price} per month</h3>
            )}
            <RectangleButton
              disabled={
                loading.annual ||
                loading.monthly ||
                subscriptionStatus === 'active_lifetime' ||
                (subscriptionStatus !== 'trialing' &&
                  subscriptionStatus !== 'trial_expired' &&
                  (userSelectedPlan === '' ||
                    (isAnnual && userSelectedPlan === annualPriceId) ||
                    (!isAnnual && userSelectedPlan === monthlyPriceId)))
              }
              title={
                loading.annual || loading.monthly
                  ? 'Processing...'
                  : subscriptionStatus === 'trialing' ||
                    subscriptionStatus === 'trial_expired'
                  ? 'Upgrade'
                  : userSelectedPlan === ''
                  ? 'Select Plan'
                  : (isAnnual && userSelectedPlan === annualPriceId) ||
                    (!isAnnual && userSelectedPlan === monthlyPriceId)
                  ? 'Current Plan'
                  : 'Upgrade'
              }
              onClick={() => {
                if (
                  subscriptionStatus === 'trialing' ||
                  (isAnnual && userSelectedPlan !== annualPriceId) ||
                  (!isAnnual && userSelectedPlan !== monthlyPriceId) ||
                  userSelectedPlan === ''
                ) {
                  handlePlanSelection(
                    isAnnual ? annualPriceId : monthlyPriceId,
                    isAnnual ? 'annual' : 'monthly',
                  );
                }
              }}
            />

            <h3>Or</h3>
            <h3>Lifetime {lifetimePlan?.price}</h3>
            <RectangleButton
              disabled={
                loading.lifetime || subscriptionStatus === 'active_lifetime'
              }
              onClick={() => handlePlanSelection(lifetimePriceId, 'lifetime')}
              title={
                loading.lifetime
                  ? 'Processing...'
                  : subscriptionStatus === 'active_lifetime'
                  ? 'Current Plan'
                  : 'Go Lifetime'
              }
              color
            />
          </PriceCardContainer>
        </PriceCardWrapper>

        {/* <NewPlanCard /> */}
        {/* <Col xs={24} md={12} style={{ marginTop: 20 }}>
          <PlanContainer>
            <PlanCard
              planTitle="Free"
              planName="Basic Plan"
              features={freePlanFeatures}
              buttonText={
                user?.companies?.[0]?.payment.selectedPlan ===
                'price_1QHovmG2PRFcrotTVZV5y7Yz'
                  ? 'Current Plan'
                  : loading
                  ? 'Processing...'
                  : 'Upgrade'
              }
              disabled={
                user?.companies?.[0]?.payment.selectedPlan ===
                  'price_1QHovmG2PRFcrotTVZV5y7Yz' || loading
              }
              onUpgradeClick={() =>
                handlePlanSelection('price_1QHovmG2PRFcrotTVZV5y7Yz')
              }
            />
          </PlanContainer>
        </Col> */}
        {/* <Col xs={24} md={12} style={{ marginTop: 20 }}>
          <PlanContainer>
            <PlanCard
              planTitle="$24.99 (£19) per month - Cancel anytime"
              planName="Monthly Plan"
              features={freePlanFeatures}
              buttonText={
                user?.companies?.[0]?.payment.selectedPlan ===
                'price_1QRhbvG2PRFcrotTs7YddG1g'
                  ? 'Current Plan'
                  : loading
                  ? 'Processing...'
                  : 'Upgrade'
              }
              disabled={
                user?.companies?.[0]?.payment.selectedPlan ===
                  'price_1QRhbvG2PRFcrotTs7YddG1g' || loading
              }
              onUpgradeClick={() =>
                handlePlanSelection('price_1QRhbvG2PRFcrotTs7YddG1g')
              }
            />
          </PlanContainer>
        </Col> */}
        {/* <Col xs={24} md={12} style={{ marginTop: 20 }}>
          <PlanContainer>
            <PlanCard
              planTitle="$190 (£149) ($16/£12.50 per Month) 4 months for free - cancel after the first 12 mos"
              planName="Annual Plan"
              features={freePlanFeatures}
              buttonText={
                user?.companies?.[0]?.payment.selectedPlan ===
                'price_1QRhc2G2PRFcrotTujWFqQ5F'
                  ? 'Current Plan'
                  : loading
                  ? 'Processing...'
                  : 'Upgrade'
              }
              disabled={
                user?.companies?.[0]?.payment.selectedPlan ===
                  'price_1QRhc2G2PRFcrotTujWFqQ5F' || loading
              }
              onUpgradeClick={() =>
                handlePlanSelection('price_1QRhc2G2PRFcrotTujWFqQ5F')
              }
            />
          </PlanContainer>
        </Col> */}
        {/* <Col xs={24} md={12} style={{ marginTop: 20 }}>
          <PlanContainer>
            <PlanCard
              planTitle="Lifetime = $240 (£180)"
              planName="Lifetime Plan"
              features={freePlanFeatures}
              buttonText={
                user?.companies?.[0]?.payment.selectedPlan ===
                'price_1QRhc8G2PRFcrotTaFOqA7D2'
                  ? 'Current Plan'
                  : loading
                  ? 'Processing...'
                  : 'Upgrade'
              }
              disabled={
                user?.companies?.[0]?.payment.selectedPlan ===
                  'price_1QRhc8G2PRFcrotTaFOqA7D2' || loading
              }
              onUpgradeClick={() =>
                handlePlanSelection('price_1QRhc8G2PRFcrotTaFOqA7D2')
              }
            />
          </PlanContainer>
        </Col> */}
      </BillingContainer>
      <HeaderContainer justify="space-between">
        <h1>{`${user?.firstName} ${user?.lastName}`}</h1>
        <div>
          <SaveButton onClick={handleSave}>
            Save Changes
            <img src={SaveIcon} alt="s" style={{ width: 16, marginLeft: 20 }} />
          </SaveButton>
          <span>
            Last Update:{' '}
            {lastUpdateTime || (user?.updatedAt && formatDate(user.updatedAt))}
          </span>
        </div>
      </HeaderContainer>
      <Card>
        <h2>Edit profile</h2>
        <h1>Account Details</h1>
        <form>
          <FlexboxGrid>
            <Col xs={24} md={12}>
              <IconInput
                icon={UserIcon}
                iconAlt="User Icon"
                inputType="text"
                value={userInfoFormik.values.firstName}
                onChange={userInfoFormik.handleChange('firstName')}
                placeholder="First name"
                error={userInfoFormik.errors.firstName}
              />
            </Col>
            <Col xs={24} md={12}>
              <IconInput
                icon={UserIcon}
                iconAlt="User Icon"
                inputType="text"
                value={userInfoFormik.values.lastName}
                onChange={userInfoFormik.handleChange('lastName')}
                placeholder="Last Name"
                error={userInfoFormik.errors.lastName}
              />
            </Col>

            <Col xs={24} md={12}>
              <IconInput
                icon={EmailIcon}
                iconAlt="Address Icon"
                inputType="email"
                value={userInfoFormik.values.email}
                onChange={userInfoFormik.handleChange('email')}
                placeholder="Email"
                error={userInfoFormik.errors.email}
                disabled
              />
            </Col>
            <Col xs={24} md={12}>
              <IconInput
                icon={EmailIcon}
                iconAlt="Address Icon"
                inputType="email"
                value={userBillingInfoFormik.values.billingEmail}
                onChange={userBillingInfoFormik.handleChange('billingEmail')}
                placeholder="Billing Email"
                error={userBillingInfoFormik.errors.billingEmail}
              />
            </Col>
          </FlexboxGrid>
        </form>
      </Card>

      <Card>
        <Row>
          <Col xs={24} md={12}>
            <h2>Edit business </h2>
          </Col>
        </Row>
        <h1>Business Details</h1>
        <form>
          <FlexboxGrid>
            <Col xs={24} md={8}>
              <IconInput
                icon={UserIcon}
                iconAlt="User Icon"
                inputType="text"
                value={companyInfoFormik.values.name}
                onChange={companyInfoFormik.handleChange('name')}
                placeholder="Business name*"
                error={companyInfoFormik.errors.name}
              />
            </Col>

            <Col xs={24} md={8}>
              <IconInput
                icon={AdressIcon}
                iconAlt="Adress Icon"
                inputType="text"
                value={companyInfoFormik.values.address}
                onChange={companyInfoFormik.handleChange('address')}
                placeholder="Address"
                error={companyInfoFormik.errors.address}
              />
            </Col>

            <Col xs={24} md={8}>
              <IconInput
                icon={CityIcon}
                iconAlt="City Icon"
                inputType="text"
                value={companyInfoFormik.values.city}
                onChange={companyInfoFormik.handleChange('city')}
                placeholder="City"
                error={companyInfoFormik.errors.city}
              />
            </Col>

            <Col xs={24} md={8}>
              <IconInput
                icon={AdressIcon}
                iconAlt="Post Code Icon"
                inputType="text"
                value={companyInfoFormik.values.zipCode}
                onChange={companyInfoFormik.handleChange('zipCode')}
                placeholder="Post code"
                error={companyInfoFormik.errors.zipCode}
              />
            </Col>

            <ColSelect xs={24} md={8}>
              <GroupSelect>
                <IconSelect>
                  <img src={WebIcon} alt="country icon" />
                </IconSelect>
                <SelectStyle
                  options={Countries}
                  placeholder={
                    <span>
                      {companyInfoFormik.initialValues.country === '' ? (
                        'Country*'
                      ) : (
                        <span style={{ color: 'black' }}>
                          {companyInfoFormik.initialValues.country}
                        </span>
                      )}
                    </span>
                  }
                  onChange={(option: any) => {
                    companyInfoFormik.setFieldValue(
                      'country',
                      option?.value,
                      true,
                    );
                    companyInfoFormik.setFieldValue(
                      'phoneCode',
                      option?.phoneCode,
                      true,
                    );
                  }}
                />
              </GroupSelect>
              <PError>{companyInfoFormik.errors.country}</PError>
            </ColSelect>

            <Col xs={24} md={8}>
              <IconInput
                icon={PhoneIcon}
                iconAlt="Country code Icon"
                inputType="text"
                value={companyInfoFormik.values.phoneCode}
                readOnly
                onChange={companyInfoFormik.handleChange('phoneCode')}
                placeholder="Country code*"
                error={companyInfoFormik.errors.phoneCode}
              />
            </Col>
            <Col xs={24} md={8}>
              <IconInput
                icon={PhoneIcon}
                iconAlt="Phone Icon"
                inputType="text"
                value={companyInfoFormik.values.phone}
                onChange={companyInfoFormik.handleChange('phone')}
                placeholder="Phone"
                error={companyInfoFormik.errors.phone}
              />
            </Col>

            <Col xs={24} md={8}>
              <IconInput
                icon={WebIcon}
                iconAlt="Website Icon"
                inputType="text"
                value={companyInfoFormik.values.webLink}
                onChange={companyInfoFormik.handleChange('webLink')}
                placeholder="Website"
                error={companyInfoFormik.errors.webLink}
              />
            </Col>
          </FlexboxGrid>
        </form>
      </Card>
      <HeaderContainer justify="end">
        <div>
          <SaveButton onClick={handleSave}>
            Save Changes
            <img src={SaveIcon} alt="s" style={{ width: 16, marginLeft: 20 }} />
          </SaveButton>
          <span>
            Last Update:{' '}
            {lastUpdateTime || (user?.updatedAt && formatDate(user.updatedAt))}
          </span>
        </div>
      </HeaderContainer>
      <Card>
        <h2>Security</h2>
        <h1>Password & Security</h1>
        <form>
          <Row>
            <Col xs={24} md={8}>
              <IconInput
                icon={KeyIcon}
                iconAlt="Key Icon"
                inputType="password"
                value={changePasswordFormik.values.oldPassword}
                onChange={changePasswordFormik.handleChange('oldPassword')}
                placeholder="Old Password"
                error={changePasswordFormik.errors.oldPassword}
              />
            </Col>
            <Col xs={24} md={8}>
              <IconInput
                icon={KeyIcon}
                iconAlt="Key Icon"
                inputType="password"
                value={changePasswordFormik.values.password}
                onChange={changePasswordFormik.handleChange('password')}
                placeholder="New Password"
                error={changePasswordFormik.errors.password}
              />
            </Col>
            <Col xs={24} md={8}>
              <IconInput
                icon={KeyIcon}
                iconAlt="Key Icon"
                inputType="password"
                value={changePasswordFormik.values.repeatPassword}
                onChange={changePasswordFormik.handleChange('repeatPassword')}
                placeholder="Repeat Password"
                error={changePasswordFormik.errors.repeatPassword}
              />
            </Col>
          </Row>
        </form>
      </Card>
    </Container>
  );
};

export default Account;
