/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { ReactNode } from 'react';
import { Modal } from 'rsuite';
import {
  ModalPosition,
  ModalSubtitle,
  ModalTitle,
  Button2,
  Button3,
} from './ModalTools.styles';

interface Props {
  children: ReactNode;
  setDataModal: any;
  dataModal: any;
  description?: string;
  title?: string;
  step?: string | null;
  setStep?: any;
  setDataModalArray?: any;
  disableButton?: boolean;
  mar?: boolean;
}

const ModalTools: React.FunctionComponent<Props> = ({
  children,
  setDataModal,
  dataModal,
  description,
  title,
  step,
  setStep,
  setDataModalArray,
  disableButton,
  mar,
}: Props) => {
  const hideModal = (event?: React.SyntheticEvent) => {
    event?.stopPropagation();
    setDataModal(null);
    if (setDataModalArray) setDataModalArray([]);

    if (step === 'page') setStep('page');
  };

  return (
    <ModalPosition show={!!dataModal} onHide={hideModal}>
      <Modal.Header>
        <ModalTitle>{title || 'Connect profiles'}</ModalTitle>
        <ModalSubtitle>{description || ''} </ModalSubtitle>
      </Modal.Header>
      <Modal.Body>{children}</Modal.Body>
      <Modal.Footer>
        <Button2
          onClick={(event?: React.SyntheticEvent) => {
            event?.stopPropagation();
            hideModal();
          }}
          appearance="subtle"
          mar={mar}>
          {step &&
            (step === 'ads' ||
              step === 'page' ||
              step === 'no-ads' ||
              'no-fb-pages') &&
            'Back'}
        </Button2>
        {step && step !== 'no-fb-pages' && (
          <Button3
            disabled={!disableButton}
            onClick={(event?: React.SyntheticEvent) => {
              event?.stopPropagation();
              if (step === 'no-ads') {
                hideModal();
              } else if (step === 'ads') {
                setStep(step);
              }
            }}
            appearance="subtle">
            Next Step
          </Button3>
        )}
      </Modal.Footer>
    </ModalPosition>
  );
};

export default ModalTools;
