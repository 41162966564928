import React, { useEffect, useReducer } from 'react';
import { useQueryClient } from 'react-query';
import ToogleTools from '../../components/toogleTools/ToogleTools';
import { initialToolsState, toolsReducer } from '../../reducers/toolsReducer';
import {
  Title,
  Subtitle,
  Button,
  BackButton,
  BackButtondiv,
  BackButtonnext,
  ContentContainer,
  DesktopBackButtonContainer,
  NextButtonContainer,
  ToogleToolsWrapper,
  Titlembile,
  Subtitlemobile,
} from './step3.styles';
import { useAuth } from '../../context/AuthContext';

interface Step3Props {
  onNext: () => Promise<void>;
}
const Step3: React.FC<Step3Props> = ({ onNext }: Step3Props) => {
  const queryClient = useQueryClient();
  const { companySelected } = useAuth();

  const [stateTool, dispatchTool] = useReducer(toolsReducer, initialToolsState);

  useEffect(() => {
    // Fetch user data from local storsage
    const user = localStorage.getItem('user');

    if (user) {
      //  console.log("user",user)
    }
  }, []);

  return (
    <>
      <ContentContainer>
        <Title>
          Start connecting your <br />
          Social Media pages
        </Title>
        <Titlembile>Get started by connecting your accounts</Titlembile>
        <br />
        <Subtitle>You can do this now, or skip and set it up later</Subtitle>
        <Subtitlemobile>
          You can do this now, or skip and set it up later
        </Subtitlemobile>
        <ToogleToolsWrapper backgroundColor="#fff">
          <ToogleTools
            card
            signup
            stateTool={stateTool}
            dispatchTool={dispatchTool}
            invalidateQuery={() => {
              queryClient.invalidateQueries(['MESSAGES']);
              queryClient.invalidateQueries(['TOOLS']);
            }}
            companyId={companySelected?.id}
          />
        </ToogleToolsWrapper>
      </ContentContainer>

      {/* Desktop Buttons */}
      <DesktopBackButtonContainer>
        <NextButtonContainer>
          <Button onClick={onNext}>Next Step</Button>
        </NextButtonContainer>
      </DesktopBackButtonContainer>

      {/* Mobile Buttons */}
      <BackButtondiv>
        <BackButton onClick={onNext}>Skip For later</BackButton>
        <BackButtonnext onClick={onNext}>Next</BackButtonnext>
      </BackButtondiv>
    </>
  );
};

export default Step3;
