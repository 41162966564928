import styled from 'styled-components';

// interface StepCircleProps {
//     active: boolean;
//   }

export const Title = styled.h2`
  font-family: 'Inter', sans-serif;
  font-size: 30x;
  color: #dd2256;
  font-family: 'Inter', sans-serif;
  text-align: center;
  width: 70%;
  margin: 0 auto;
  line-height: 40px;

  @media (max-width: 1480px) {
    line-height: 35px;
    width: 80%;
    margin: 0 auto;
    font-size: 28px;
  }
  @media (max-width: 1280px) {
    line-height: 35px;
    width: 70%;
    margin-bottom: 10px;
    margin: 0 auto;
    font-size: 30px;
  }
  @media (max-width: 1024px) {
    line-height: 35px;

    font-size: 20px;
  }
  @media (max-width: 780px) {
    display: none;
  }
`;
export const Subtitle = styled.p`
  font-family: 'Inter', sans-serif;
  font-size: 16px;
  color: #666;
  margin-bottom: 10px;
  width: 70%;
  margin: 0 auto;
  margin-bottom: 20px;
  text-align: center;
  @media (max-width: 1480px) {
    width: 70%;
    margin: 0 auto;
    font-size: 14px;
    color: #666;
  }
  @media (max-width: 1280px) {
    font-size: 12px;
    width: 70%;
  }
  @media (max-width: 1024px) {
    font-size: 12px;
    width: 75%;
  }

  @media (max-width: 780px) {
    display: none;
  }
`;
export const Titlembile = styled.h2`
  display: none;

  @media (max-width: 780px) {
    display: flex; /* Use flexbox to center content */
    justify-content: center; /* Center horizontally */
    align-items: center; /* Center vertically */
    font-family: 'Inter', sans-serif;
    width: 60%;
    font-size: 22px;
    color: #dd2256;
    text-align: center; /* Center text within the container */
    margin: 0 auto; /* Center the element horizontally in its parent container */
    line-height: 35px;
    @media (max-width: 380px) {
      width: 70%;
      font-size: 18px;
    }
  }
`;

export const Subtitlemobile = styled.p`
  display: none;
  text-align: center; /* Center the text */

  @media (max-width: 780px) {
    width: 70%;
    font-family: 'Inter', sans-serif;
    display: block;
    font-size: 14px;
    color: #666;
    text-align: left; /* Center the text */
    margin: 0 auto;
    margin-bottom: 5px;
  }

  @media (max-width: 480px) {
    width: 82%;
    padding: 0 20px;
    font-size: 12px;
  }
  @media (max-width: 380px) {
    width: 83%;
    padding: 0 20px;
    font-size: 12px;
  }
`;

export const StyledInput = styled.input<{ isError?: boolean }>`
  // border-color: ${props => (props.isError ? 'red' : 'initial')};
  border-color: #ffd6df;
  background-color: #ffd6df !important;
  font-family: 'Inter', sans-serif;
  width: 70%;
  padding: 0.9rem 2rem;
  margin: 0.8rem auto;
  border-radius: 10px;
  border: none;
  // background-color: ${({ isError }) => (isError ? 'black' : '#ffd6df')};
  font-size: 1rem;
  color: #dd2256 !important;
  outline: none;

  &::placeholder {
    color: #dd2256 !important;
  }
  ::-ms-input-placeholder {
    color: #dd2256 !important;
  }
  &:focus {
    border-color: #f857a6 !important;
  }
  &:-webkit-autofill {
    background-color: #ffd6df !important;
    -webkit-box-shadow: 0 0 0px 1000px #ffd6df inset !important;
    -webkit-text-fill-color: #dd2256 !important;
  }

  @media (max-width: 1480px) {
    padding: 0.6rem 2rem;
    margin: 0.7rem auto;
    font-size: 14px;

    width: 70%;
  }
  @media (max-width: 1020px) {
    padding: 0.6rem 2rem;
    margin: 1rem auto;

    width: 70%;
  }
  @media (max-width: 780px) {
    width: 75%;
  }
  @media (max-width: 380px) {
    width: 75%;
    font-size: 12px;
  }
`;
export const StyledInput2 = styled.input`
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 16px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);

  &:focus {
    border-color: #007bff; /* Change border color on focus */
    outline: none; /* Remove default outline */
    box-shadow: 0 0 5px rgba(0, 123, 255, 0.5); /* Add a shadow on focus */
  }

  &::placeholder {
    color: #aaa; /* Placeholder text color */
  }
  &:-webkit-autofill {
    background-color: #ffd6df !important;
    -webkit-box-shadow: 0 0 0px 1000px #ffd6df inset !important;
    -webkit-text-fill-color: #dd2256 !important;
  }
`;

export const BackButtondiv = styled.div`
  display: none;

  @media (max-width: 780px) {
    font-family: 'Inter', sans-serif;

    display: flex;
    width: 85%;
    position: fixed;
    bottom: 0;
    left: 50%; /* Positioning to the center */
    transform: translateX(-50%); /* Center alignment */
    background: #fff;
    justify-content: space-between;
    align-items: center;
    padding: 10px; /* Padding inside the container */
    z-index: 1000;
    min-height: 100px; /* Set the minimum height here */
  }

  @media (max-width: 380px) {
    min-height: 90px;  /* Set the minimum height here */
    width: 75%;

    padding: 0;
  }
`;

export const BackButton = styled.button`
  // background-color: transparent;
  background-color: #ffd6df;
  font-family: 'Inter', sans-serif;
  color: #DD2256;

  font:bold;
  border: 1px solid #DD2256;
  border-radius: 20px;
  font-size: 20px;
  cursor: pointer;
  // margin: 0 10px; 

  &:hover {
    background: linear-gradient(180deg, #dd2256 0%, #e43c39 100%);
    color: #fff;
  }

  @media (max-width: 780px) {
    width: 140px;
    height: 40px;
    font-size: 18px;
    border-radius: 15px;
    margin: 0 5px; /* Adjust spacing for smaller screens */
  }
  @media (max-width: 480px) {
    width: 140px;
    height: 40px;
    font-size: 186x;
    border-radius: 15px;
    margin: 0 5px; /* Adjust spacing for smaller screens */
  }

  @media (max-width: 380px) {
    width: 120px;
    height: 40px;
    font-size: 14px; /* Slightly smaller font for very small screens */
    border-radius: 15px;
    margin-bottom:20px;

  }
`;

export const BackButtonnext = styled.button`
  font-family: 'Inter', sans-serif;
  color: #fff;
  width: 140px;
  height: 50px;
  border: 1px solid #dd2256;
  border-radius: 20px;
  font-size: 20px;
  cursor: pointer;
  background-color: ${props => (props.disabled ? '#ffd6df' : '#dd2256')};
  color: ${props => (props.disabled ? '#dd2256' : '#fff')};
  margin: 0;  /* No margin shifting */
  box-sizing: border-box;  /* Include padding/border in total size */

  &:hover {
    background-color: ${props => (props.disabled ? 'none' : '#c51e4a')};
    // color: #fff;
  }

  &:focus, &:active {
    outline: none;  /* Remove default focus outline */
  }

  @media (max-width: 780px) {
    width: 160px;
    height: 40px;
    font-size: 18px;
    border-radius: 15px;
    margin: 0 5px; 
  }

  @media (max-width: 480px) {
    width: 140px;
    height: 40px;
    font-size: 16px;
    border-radius: 15px;
    margin: 0 5px; 
  }

  @media (max-width: 380px) {
    width: 120px;
    height: 40px;
    font-size: 14px;  
    border-radius: 15px;
    margin-bottom: 20px;
  }
`;




export const ContentContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center; /* Center all children horizontally */
  justify-content: space-between; /* Space between top and bottom elements */
  overflow-y: auto; /* Allow scrolling if content overflows */
  @media (max-width: 780px) {
    overflow-y: auto;
    margin-bottom: 170px;
  }
  @media (max-width: 420px) {
    margin-bottom: 90px;
  }
  @media (max-width: 320px) {
    margin-bottom: 150px;
  }
`;

export const DesktopBackButtonContainer = styled.div`
  display: flex;
  flex-direction: row-reverse;
  justify-content: center;
  align-items: center; /* Centers the buttons vertically */
  width: 70%;
  margin: 0 auto;
  background: #fff;
  z-index: 1000;
  border-radius: 15px 15px 0 0;
  font-family: 'Inter', sans-serif;
  margin-top: 40px;
  gap: 30px;

  @media (max-width: 780px) {
    display: none;
  }
`;

export const NextButtonContainer = styled.div`
  width: 50%;
  display: flex;
  justify-content: start;
  align-items: center; /* Ensures the button is centered vertically */
`;
export const Button = styled.button`
  width: 100%; /* Makes the button fill its container */
  background-color: ${props => (props.disabled ? '#ffd6df' : '#dd2256')};  /* Default red color */
  color: ${props => (props.disabled ? '#dd2256' : '#fff')};  /* Default red color */
  padding: 10px 0; /* Standardizes padding to ensure the same height */
  border: none;
  border-radius: 12px;
  font-size: 18px;
  cursor: pointer;
  margin: 0; /* Removes top margin to prevent height differences */
  transition: background-color 0.3s ease;

  &:hover {
    // background-color: #c51e4a;
     background-color: ${props => (props.disabled ? 'none' : '#c51e4a')};  /* Pink on hover when enabled */

  }

  @media (max-width: 1480px) {
    font-size: 14px;
    border-radius: 10px;
    padding: 8px 0; /* Standardizes padding to ensure the same height */
  }
  @media (max-width: 1020px) {
    font-size: 12px;
    border-radius: 10px;
    padding: 8px 0; /* Standardizes padding to ensure the same height */
  }

  @media (max-width: 780px) {
    display: none;
  }
`;

export const BackCancelContainer = styled.div`
  width: 50%;
  display: flex;
  justify-content: end;
  align-items: center; /* Ensures the button is centered vertically */
  border: 0.1em solid #ffffff;
  border-radius: 10px;
  box-sizing: border-box;
  text-decoration: none;
  font-weight: 300;
  color: #ffffff;
  text-align: center;
  transition: all 0.2s;

  @media (max-width: 780px) {
    display: none;
  }
`;

// Styled button for desktop back action with hover and responsive styling
export const DesktopBackButton = styled.button`
  width: 100%; /* Makes the button fill its container */
  font-family: 'Inter', sans-serif;
  background-color: #fff;
  color: #dd2256;
  padding: 10px 0; /* Standardizes padding to ensure the same height */
  border: 1px solid #dd2256;
  border-radius: 12px;
  font-size: 18px;
  cursor: pointer;
  margin: 0; /* Removes top margin to prevent height differences */
  transition: background-color 0.3s ease, color 0.3s ease, transform 0.3s ease;

  &:hover {
    background: linear-gradient(180deg, #dd2256 0%, #e43c39 100%);
    color: #fff;
  }

  @media (max-width: 1480px) {
    font-size: 14px;
    border-radius: 10px;
    padding: 8px 0; /* Standardizes padding to ensure the same height */
  }
  @media (max-width: 1020px) {
    font-size: 12px;
    border-radius: 10px;
    padding: 8px 0; /* Standardizes padding to ensure the same height */
  }
  @media (max-width: 780px) {
    display: none;
  }
`;
export const DesktopCancelButton = styled.button`
  background-color: #fff;
  color: #dd2256;
  margin: 0 auto;
  padding: 15px 35px;
  border-radius: 15px;
  font-size: 16px;
  font-weight: bold;
  cursor: pointer;
  border: 2px solid #dd2256;
  transition: background-color 0.3s ease, color 0.3s ease, transform 0.3s ease;
  &:hover {
    background-color: #dd2256;
    color: #fff;
    transform: translateY(-2px); /* Slight lift on hover */
  }
  &:active {
    transform: translateY(0); /* Reset lift on click */
  }
`;
export const StyledErrorMessage = styled.div`
  color: red;
  font-size: 10px;
  font-weight: bold;
  width: 65%;
  color: red;
  @media (max-width: 1480px) {
    margin-top: none;
    margin-bottom: none;
    line-height: 0px;
  }
  @media (max-width: 780px) {
  }
`;
export const StyledErrorMessage2 = styled.div`
  color: red;
  font-size: 10px;
  font-weight: bold;
  width: 75%;
  margin-left: 20px;
  color: red;
  @media (max-width: 1480px) {
    margin-top: none;
    margin-bottom: none;
    line-height: 0px;
  }
  @media (max-width: 780px) {
  }
`;
export const Subtitlemobilebottom = styled.p`
  display: none;

  @media (max-width: 780px) {
    display: block;
    width: 85%;
    margin: 0 auto;
    font-family: 'Inter', sans-serif;
    font-size: 12px;
    color: #666;
    text-align: left;
    margin: 0;
    padding: 0 40px;
    margin-top: 10px;
  }
  @media (max-width: 480px) {
    display: block;
    width: 93%;
    margin: 0 auto;
    font-family: 'Inter', sans-serif;
    font-size: 12px;
    color: #666;
    text-align: left;
    margin: 0;
    padding: 0 40px;
    margin-top: 10px;
  }
  @media (max-width: 380px) {
    display: block;
    width: 90%;
    // margin: 0 auto;
    // font-family: 'Inter', sans-serif;
    // font-size: 12px;
    // color: #666;
    // text-align: left;
    // margin: 0;
    // padding: 0 40px;
    // margin-top:0px;
  }
`;
export const SearchContainer = styled.div`
  width: 70%; /* Default width */

  @media (max-width: 1200px) {
    width: 70%; /* For medium screens */
  }

  @media (max-width: 768px) {
    width: 75%; /* For small screens */
  }
`;
// border-color: ${props => (props.isError ? 'red' : 'initial')};

export const header = styled.p`
  font-size: 20px; // Slightly larger than before
  font-weight: 600; // Make it bold
  color: #333; // Darker color for better readability
  text-align: center; // Center it
  margin: 30px 0; // Increase space around it for better separation
  letter-spacing: 1px; // Slight spacing between letters for a clean look
  background: linear-gradient(
    to right,
    #ff7e5f,
    #feb47b
  ); // Subtle gradient for emphasis

  -webkit-background-clip: text;
  color: transparent;
  font-style: italic;
`;
export const PhoneInputContainer = styled.div`
  display: flex;
  align-items: center;
  width: 70%;
  @media (max-width: 1480px) {
    width: 70%;
  }
  @media (max-width: 1020px) {
    width: 70%;
  }
  @media (max-width: 780px) {
    width: 75%;
  }
  @media (max-width: 380px) {
    width: 75%;
  }
`;
export const AsteriskNote = styled.p`
  width: 65%;
  font-size: 0.9em;
  color: #666;
  margin-bottom: 1em;
`;
