import { Drawer } from 'rsuite';
import styled from 'styled-components';

interface ButtonProps {
  disBtn: string;
}

interface ContainerProps {
  w?: string;
  color?: string;
}

export const DrawerBorder = styled(Drawer)`
  max-width: 100%;
  font-family: 'Poppins';

  .rs-drawer-dialog .rs-drawer-content {
    border-radius: 20px 0px 0px 20px;
    padding: 0px 5vw;
    background: #edf2f7;
    overflow-y: scroll;

    .rs-drawer-header {
      margin: 20px;
    }

    .rs-drawer-header h4 {
      font-size: ${props => props.theme.fonts.title};
      font-weight: bolder;
      color: ${props => props.theme.colors.black};
    }
    .rs-drawer-body {
      display: flex;
      flex-direction: column;
      margin: 0;
      /* height: auto !important; */
    }

    .rs-drawer-header button {
      font-size: ${props => props.theme.fonts.bigSubtitle};
      color: ${props => props.theme.colors.grey};
    }
  }

  /* @media screen and (max-width: 987px) {
    .rs-modal-lg {
      display: flex;
      width: 100%;
    }
  } */

  .rs-divider-horizontal {
    margin: 17px 0;
  }
`;

export const SocialLogo = styled.div`
  display: flex;
  justify-content: flex-end;
  margin: 5px;

  img {
    width: 6%;
  }
`;

export const Image = styled.img`
  width: 10%;
  height: 100%;
  object-fit: cover;
  border-radius: 50px;
`;

export const PreviewWrapper = styled.div`
  display: flex;
  flex-direction: column;
  background: #fff;
  border-radius: 0.375rem;
  border-color: #e2e8f0;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.08);
`;

export const InstagramModalWrapper = styled.div`
  @media (max-width: 968px) {
    max-width: 900px;
    margin: 0 50px;
  }
`;

export const PageImageWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 0px 16px;

  img {
    object-fit: cover;
  }
`;

export const PageNameWrapper = styled.div`
  word-break: break-word;
  text-align: start;

  span {
    font-weight: 600;
    margin-right: 5px;
  }
`;
export const TimeWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const PageName = styled.span`
  font-size: 18px;
  font-weight: 800;
`;

export const ImageContainer = styled.div`
  position: relative;
  display: flex;
`;

export const PostImage = styled.img`
  width: 100%;
  max-height: 100vh;
  object-fit: cover;
`;

export const BottomSection = styled.div`
  display: flex;
  flex-direction: column;
  padding: 10px;

  .rs-divider-horizontal {
    margin: 7px 0px;
  }
`;

export const BottomModalSection = styled.div`
  display: flex;
  flex-direction: column;
  padding: 16px;

  .rs-divider-horizontal {
    margin: 7px 0px;
  }
`;

export const LikesSection = styled.div`
  display: flex;
  align-items: center;

  img {
    width: 5%;
    margin-right: 12px;
  }

  @media screen and (max-width: 968px) {
    img {
      width: 26px;
      height: 26px;
    }
  }
`;

export const LikesModalSection = styled.div`
  display: flex;
  align-items: center;

  img {
    width: 8%;
    margin-right: 12px;
  }

  @media screen and (max-width: 968px) {
    .image-size {
      width: 26px;
      height: 26px;
    }
  }
`;

export const Button = styled.button`
  background: none;
  border: none;
  padding: 0;
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  justify-content: center;

  img {
    width: 31px;
    height: 31px;
  }
`;

export const ModalButton = styled.button`
  background: none;
  border: none;
  padding: 0;
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  justify-content: center;

  img {
    width: 29px;
    height: 29px;
  }
`;

export const SaveIconWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 100%;
  margin-left: auto;

  img {
    margin-right: 0; /* Optional: Remove margin for the last icon */
  }
`;
export const CountWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: baseline;
  padding: 10px 0px 0px;
  gap: 5px;

  span {
    font-size: 16px;
    font-weight: 800;
    color: black;
  }

  span:nth-child(2) {
    font-weight: 900;
  }
`;

export const ViewMore = styled.button`
  display: flex;
  border: none;
  background: transparent;
  cursor: pointer;
  font-size: 16px;
  padding: 10px 0px;
`;

export const ImageWrapper = styled.div`
  margin-right: 2px;
  width: 2.375rem;
  display: block;
  border-width: 1px;
  border-color: #e2e8f0;
  border-radius: 9999px;
`;

export const SocialWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const LikeWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;

  img {
    width: 18%;
  }

  span {
    font-size: 14px;
  }
`;
export const CommentWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;

  img {
    width: 18%;
  }

  span {
    font-size: 14px;
  }
`;
export const ShareWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;

  img {
    width: 17%;
  }

  span {
    font-size: 14px;
  }
`;

export const InputCommentSection = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  /* margin: 10px 0px; */
  /* padding: 0px 10px; */
  width: 100%;

  /* img {
    border-radius: 50%;
    width: 40px;
    height: 40px;
    margin-right: 10px;
  } */
`;

export const ModalInputCommentSection = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  /* margin: 10px 0px; */
  /* padding: 0px 10px; */
  width: 100%;

  /* img {
    border-radius: 50%;
    width: 40px;
    height: 40px;
    margin-right: 10px;
  } */
`;

export const ModalInputSection = styled.div`
  display: flex;
  align-items: center;
  /* flex-direction: column; */
  /* border-radius: 20px; */
  /* background: ${props => props.theme.colors.lightGrey}; */
  width: 100%;
  padding: 15px;
  gap: 15px;
`;

export const InputSection = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 0px 8px 15px;
  gap: 15px;
`;

export const CommentSendButton = styled.button`
  border: none;
  background: transparent;
  color: #0a7cff;
  font-size: 17px;
  font-weight: 800;
  margin-right: 15px;
  transform: translate(0px, 2px);
  cursor: pointer;
`;

export const ModalCommentSendButton = styled.button<ButtonProps>`
  margin-left: auto;
  border: none;
  background: transparent;
  color: #0a7cff;
  font-size: 17px;
  font-weight: 800;
  /* margin-right: 15px; */
  transform: translate(0px, 2px);
  opacity: ${props => props.disBtn};
  cursor: pointer;
`;

export const ModalInput = styled.input`
  font-size: 16px;
  height: 35px;
  background: transparent;
  border: none;
  color: ${props => props.theme.colors.black};
  width: 100%;

  &:focus {
    outline: none;
    border: none;
    box-shadow: none;
  }

  &::placeholder {
    color: ${props => props.theme.colors.darkHardGrey};
    font-size: 14px;
  }
`;

export const Input = styled.input`
  font-size: 18px;
  height: 35px;
  background: transparent;
  border: none;
  color: ${props => props.theme.colors.black};

  &:focus {
    outline: none;
    border: none;
    box-shadow: none;
  }

  &::placeholder {
    color: ${props => props.theme.colors.darkHardGrey};
    font-size: 18px;
  }
`;

export const IconsWrapper = styled.div`
  position: relative;
  display: flex;
  justify-content: space-between;

  .EmojiPickerReact {
    position: absolute !important;
    transform: translate(-10px, -500px);
    z-index: 9;
  }
`;
export const IconSmiley = styled.img`
  width: 25px;
  height: 25px;
  cursor: pointer;
`;

export const ModalContainer = styled.div<ContainerProps>`
  width: ${({ w }) => w};
  background: ${({ color }) => color};
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .rs-modal-header {
    padding: 16px 0px;
  }

  .rs-modal-body {
    &::-webkit-scrollbar {
      display: none;
    }
  }
  .rs-modal-body {
    margin: 0px;
    padding: 0px 16px 16px;
    -ms-overflow-style: none;
    scrollbar-width: none;
  }

  .rs-divider-horizontal {
    margin: 24px 0 0px;
  }

  .rs-modal-footer {
    .rs-divider-horizontal {
      margin: 0px;
    }
  }

  @media (max-width: 968px) {
    width: 100%;
  }
`;

export const ModalBodyWrapper = styled.div`
  display: flex;
  width: 100%;
  gap: 10px;
  height: 100%;
`;

export const ModalCommentWrapper = styled.div`
  display: flex;
  width: 100%;
  gap: 10px;
`;

export const ImagesModal = styled.div`
  width: 11%;

  img {
    width: 45px;
    height: 45px;
    border-radius: 50%;
    object-fit: contain;
  }
`;

export const TextModal = styled.div`
  width: 89%;
`;

export const ModalWrapper = styled.div`
  display: flex;
  width: 100%;
  height: 600px;

  @media screen and (min-width: 1380px) {
    height: 735px;
  }

  @media screen and (max-width: 987px) {
    flex-direction: column;
    overflow-y: scroll;
    align-items: center;
    height: inherit;
  }
`;

export const ImgWrapper = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

export const PostImageBackground = styled.div<{ src: string }>`
  width: 100%;
  height: 100%;
  background-image: url(${props => props.src});
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  aspect-ratio: 1 / 1;
`;

export const DisplayCommentSection = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 20px;
  gap: 20px;
  height: 192px;
  overflow-y: scroll;

  @media (min-width: 1380px) {
    height: 260px;
  }

  @media (min-width: 1400px) {
    height: 303px;
  }

  &::-webkit-scrollbar {
    display: none;
  }
`;

export const DisplayRecentCommentsSection = styled.div`
  display: flex;
  flex-direction: column;
  /* margin-top: 20px; */
  gap: 39px;
  width: 100%;
`;

export const CommentCardWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  /* padding: 10px; */

  .image {
    width: 35px;
    height: 35px;
  }

  img {
    border-radius: 50%;
    width: 45px;
    height: 45px;
    margin-right: 10px;
  }
`;
export const RepliesWrapper = styled.button`
  display: flex;
  align-items: center;
  padding-top: 20px;
  gap: 20px;
  border: none;
  background: transparent;
  cursor: pointer;

  hr {
    margin: 0;
    width: 25px;
    background-color: #575757;
    height: 1px;
  }
`;

export const ImageTextContainer = styled.div`
  display: flex;
  flex-direction: column;
  /* gap: 10px; */
`;

export const ButtonsContainer = styled.div`
  display: flex;
  gap: 20px;
  /* padding: 0px 15px; */

  button {
    padding: 0;
    font-size: 15px;
  }
`;

export const LikesButton = styled.button`
  background: none;
  border: none;
  padding: 0;
  cursor: pointer;
`;

export const ReplyButton = styled.button`
  background: none;
  border: none;
  padding: 0;
  cursor: pointer;
`;

export const SingleCommentWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 5px;
  height: 30px;

  span {
    font-size: 15px;
    font-weight: 800;
    color: ${props => props.theme.colors.black};
  }
  p {
    font-size: 16px;
    color: ${props => props.theme.colors.black};
  }
`;

export const ModalCommentButton = styled.button`
  background: none;
  border: none;
  padding: 0;
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  margin-left: auto;

  img {
    width: 16px;
    height: 16px;
    border-radius: 0px;
    margin-right: 0px;
  }
`;
