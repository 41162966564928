import axios from '../lib/axios';
import { STATUS_PROMOTIONS } from '../lib/globalValues';
import { PRIVILEGIES_SCOPE } from '../lib/initFacebookSdk';
import {
  notificationError,
  notificationWithSimpleNodes,
} from '../lib/notification';

export const facebookConnect = async (
  loginStatusResponse: fb.StatusResponse,
) => {
  try {
    const response = await axios.post('/tools/facebook-connect', {
      userToken: loginStatusResponse.authResponse.accessToken,
    });
    return response;
  } catch (error) {
    const { message } = error;

    if (message && message !== 'loginStatusResponse.authResponse is null') {
      notificationError({
        title: 'Error',
        description: message,
      });
      return error;
    }

    notificationError({
      title: 'Error',
      description: error?.response?.data?.message || 'Do not close the popup',
    });
    return error;
  }
};

export const facebookPageSelected = async (
  pages: FacebookPage[],
  //  facebookUserId: string,
) => {
  try {
    const response = await axios.post('/tools/facebook-specific', {
      pageData: pages,
      //      facebookUserId
    });
    notificationWithSimpleNodes({
      type: 'open',
      title: '',
      description: 'Facebook was enabled',
    });
    return response.data;
  } catch (error) {
    notificationError({
      title: 'Error',
      description:
        error?.response?.data?.message || 'The page could not be added.',
    });
    return error;
  }
};

export const facebookLogin = async () => {
  return new Promise((resolve, reject) => {
    FB.login(
      response => {
        if (response.authResponse) return resolve(response);
        return reject(Error());
      },
      { scope: PRIVILEGIES_SCOPE },
    );
  });
};

export const getFacebookPost = async (postId: string | undefined) => {
  try {
    const dataPost = axios.get(`/posts/facebook/${postId}`);
    return dataPost;
  } catch (err) {
    notificationError({
      title: 'Error',
      description: 'Error in obtaining the data of the publication',
    });

    return err;
  }
};

export const updateStatusAdvert = async ({
  status,
  adId,
}: {
  status: string;
  adId: string;
}) => {
  try {
    const { data } = await axios.put(`/promotions/update-status`, {
      status:
        status === STATUS_PROMOTIONS.ACTIVE ||
        status === STATUS_PROMOTIONS.IN_PROCESS ||
        status === STATUS_PROMOTIONS.PENDING_REVIEW
          ? STATUS_PROMOTIONS.PAUSED
          : STATUS_PROMOTIONS.ACTIVE,
      adId,
    });
    return data;
  } catch (error) {
    notificationError({
      title: 'Error',
      description:
        error?.response?.data?.message || 'The page could not be updated.',
    });
    return error;
  }
};
