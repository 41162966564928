/* eslint-disable @typescript-eslint/no-explicit-any */

import { useEffect, useRef, useState } from 'react';
import { Divider, Modal } from 'rsuite';
import EmojiPicker from 'emoji-picker-react';

import {
  BottomModalSection,
  ButtonsContainer,
  CommentCardWrapper,
  CountWrapper,
  DisplayCommentSection,
  IconSmiley,
  IconsWrapper,
  Image,
  ImageTextContainer,
  ImagesModal,
  LikesModalSection,
  ModalBodyWrapper,
  ModalButton,
  ModalCommentButton,
  ModalCommentSendButton,
  ModalContainer,
  ModalInput,
  ModalInputCommentSection,
  ModalInputSection,
  ModalWrapper,
  PageImageWrapper,
  PageName,
  PostImageBackground,
  RepliesWrapper,
  ReplyButton,
  SaveIconWrapper,
  SingleCommentWrapper,
  TextModal,
} from './InstagramPreview.style';
import InstagramGrayHeartImage from '../../../assets/icons/instagramHeartGray.svg';
import InstagramCommentImage from '../../../assets/icons/InstaComment.svg';
import InstagramShareImage from '../../../assets/icons/InstaShare.svg';
import InstagramSaveImage from '../../../assets/icons/instaSave.svg';
import InstagramHeartLike from '../../../assets/icons/instagramHeartLikeLogo.svg';
import smileIcon from '../../../assets/images/smile.png';

import { notification } from '../../../lib/notification';
import { formatTimeDifference, instaFormatDate } from '../../../lib/format';
import {
  getPostCommentsById,
  sendCommentInCommentById,
  sendCommentInPostById,
} from '../../../services/instagramComments';
import DisplayRepliesComments from './DisplayRepliesComments';

const InstagramCommentModal = ({ show, showDrawer, onHide }: any) => {
  const [userInput, setUserInput] = useState('');
  const [isShow, setIsShow] = useState(false);
  const [replyTo, setReplyTo] = useState<string | null>(null);
  const [commentsUpdate, setCommentsUpdate] = useState(0);
  const [modalSize, setModalSize] = useState<'sm' | 'lg'>('sm');

  const [comments, setComments] = useState<Comment[]>([]);
  const commentInputRef = useRef<HTMLInputElement | null>(null);
  const [isEmojiPickerOpen, setIsEmojiPickerOpen] = useState(false);
  const [isEmojiPickerSelected, setIsEmojiPickerSelected] = useState(false);

  const handleFocusCommentInput = () => {
    commentInputRef.current?.focus();
  };

  const getCommentsDataByPostId = async () => {
    if (showDrawer?.id) {
      const { data } = await getPostCommentsById(showDrawer?.id);
      setComments(data);
    }
  };

  useEffect(() => {
    getCommentsDataByPostId();
  }, [showDrawer?.id, commentsUpdate]);

  const handleInput = (e: any) => {
    const { value } = e.target;
    setUserInput(value);
  };

  const handleSendComment = async () => {
    if (userInput === '') {
      notification({
        type: 'info',
        title: 'Instagram Post Comment',
        description: 'Type Something...!',
      });
      return;
    }
    if (replyTo) {
      const res = await sendCommentInCommentById(replyTo, userInput);
      if (res) {
        setUserInput('');
        setReplyTo(null);
        notification({
          type: 'success',
          title: 'Instagram Comment Reply',
          description: 'Reply Posted Successfully.',
        });
        setCommentsUpdate((prev: any) => prev + 1);
      }
    } else if (showDrawer?.id) {
      // Posting a new comment
      const res = await sendCommentInPostById(showDrawer?.id, userInput);
      if (res) {
        setUserInput('');
        notification({
          type: 'success',
          title: 'Instagram Post Comment',
          description: 'Comment Posted Successfully.',
        });
        setCommentsUpdate((prev: any) => prev + 1);
      }
    }
  };

  const handleReplyClick = (username: string, commentId: string) => {
    setUserInput(`@${username} `);
    setReplyTo(commentId);
  };

  const handleShowRepliesComments = () => {
    setIsShow(!isShow);
  };

  useEffect(() => {
    const updateModalSize = () => {
      if (window.innerWidth >= 768) {
        setModalSize('lg');
      } else {
        setModalSize('sm');
      }
    };

    updateModalSize();

    window.addEventListener('resize', updateModalSize);

    return () => window.removeEventListener('resize', updateModalSize);
  }, []);

  useEffect(() => {
    if (isEmojiPickerSelected) {
      closeEmojiPicker();
      setIsEmojiPickerSelected(false);
    }
  }, [isEmojiPickerSelected]);

  const toggleEmojiPicker = () => {
    setIsEmojiPickerOpen(!isEmojiPickerOpen);
  };
  const closeEmojiPicker = () => {
    setIsEmojiPickerOpen(false);
  };

  const handleEmojiClick = (emojiObject: any) => {
    setIsEmojiPickerSelected(true);
    setUserInput(prev => prev + emojiObject.emoji);
  };

  return (
    <Modal
      size={modalSize}
      show={show}
      onHide={onHide}
      className="instagram-modal">
      <ModalWrapper>
        <ModalContainer w="65%" color="black">
          <PostImageBackground src={showDrawer?.media?.image} />
        </ModalContainer>
        <ModalContainer w="35%">
          <div>
            <Modal.Header>
              <PageImageWrapper>
                <Image src={showDrawer?.pageProfilePicture} alt="Page Image" />
                <PageName>{showDrawer?.pageName || ''}</PageName>
              </PageImageWrapper>
              <Divider />
            </Modal.Header>
            <Modal.Body>
              <ModalBodyWrapper>
                <ImagesModal>
                  <Image
                    src={showDrawer?.pageProfilePicture}
                    alt="Page Image"
                  />
                </ImagesModal>
                <TextModal>{showDrawer?.text}</TextModal>
              </ModalBodyWrapper>
              <DisplayCommentSection>
                {comments?.map(
                  ({
                    username,
                    text,
                    id,
                    timestamp,
                    user,
                    like_count,
                    replies,
                  }: any) => (
                    <>
                      <CommentCardWrapper key={id}>
                        <img
                          src={user?.profile_picture_url}
                          alt={user?.username}
                        />
                        <ImageTextContainer>
                          <SingleCommentWrapper>
                            <span>{username}</span>
                            <p>{text}</p>
                          </SingleCommentWrapper>
                          <ButtonsContainer>
                            <span>{formatTimeDifference(timestamp)}</span>
                            {like_count === 1 && like_count > 0 && (
                              <span>{like_count} like</span>
                            )}
                            {like_count > 0 && like_count > 1 && (
                              <span>{like_count} likes</span>
                            )}
                            <ReplyButton
                              onClick={() => handleReplyClick(username, id)}>
                              Reply
                            </ReplyButton>
                          </ButtonsContainer>
                          {replies.length > 0 && (
                            <RepliesWrapper onClick={handleShowRepliesComments}>
                              <hr />
                              {isShow ? (
                                <span>Hide replies</span>
                              ) : (
                                <span>View replies {replies.length}</span>
                              )}
                            </RepliesWrapper>
                          )}
                          {isShow && replies.length > 0 && (
                            <>
                              <DisplayRepliesComments replies={replies} />
                            </>
                          )}
                        </ImageTextContainer>
                        <ModalCommentButton>
                          <img
                            src={
                              like_count > 0
                                ? InstagramHeartLike
                                : InstagramGrayHeartImage
                            }
                            alt="Like Icon"
                          />
                        </ModalCommentButton>
                      </CommentCardWrapper>
                    </>
                  ),
                )}
              </DisplayCommentSection>
            </Modal.Body>
          </div>
          <Modal.Footer>
            <Divider />
            <BottomModalSection>
              <LikesModalSection>
                <ModalButton>
                  <img
                    src={
                      showDrawer?.totalLikes > 0
                        ? InstagramHeartLike
                        : InstagramGrayHeartImage
                    }
                    alt="Like Icon"
                    className="image-size"
                  />
                </ModalButton>
                <ModalButton onClick={handleFocusCommentInput}>
                  <img
                    src={InstagramCommentImage}
                    className="image-size"
                    alt="Comment Icon"
                  />
                </ModalButton>
              </LikesModalSection>
              <CountWrapper>
                {showDrawer?.totalLikes > 0 && (
                  <span> {`${showDrawer?.totalLikes} like`}</span>
                )}
                {showDrawer?.totalLikes > 0 && showDrawer?.totalLikes > 1 && (
                  <span> {`${showDrawer?.totalLikes} likes`}</span>
                )}
                <p>{instaFormatDate(showDrawer?.publishedAt)}</p>
              </CountWrapper>
            </BottomModalSection>
            <Divider />
            <ModalInputCommentSection>
              <ModalInputSection>
                <IconsWrapper>
                  <div>
                    <IconSmiley
                      src={smileIcon}
                      alt="smiley icon"
                      onClick={toggleEmojiPicker}
                      className="smileIcon"
                    />
                  </div>
                  {isEmojiPickerOpen ? (
                    <EmojiPicker
                      lazyLoadEmojis
                      skinTonesDisabled
                      onEmojiClick={handleEmojiClick}
                    />
                  ) : (
                    <></>
                  )}
                </IconsWrapper>
                <ModalInput
                  ref={commentInputRef}
                  value={userInput}
                  placeholder="Add a comment..."
                  onChange={(e: any) => handleInput(e)}
                />
                <ModalCommentSendButton
                  disBtn={userInput === '' ? '0.3' : '1'}
                  disabled={userInput === ''}
                  onClick={handleSendComment}>
                  Post
                </ModalCommentSendButton>
              </ModalInputSection>
            </ModalInputCommentSection>
          </Modal.Footer>
        </ModalContainer>
      </ModalWrapper>
    </Modal>
  );
};

export default InstagramCommentModal;
