import React, { useState, useEffect, useRef, useCallback } from 'react';
import { Formik, Field, Form, ErrorMessage,FormikHelpers } from 'formik';
import { Loader, FlexboxGrid } from 'rsuite';
import {
  useJsApiLoader,
  StandaloneSearchBox,
} from '@react-google-maps/api';
import * as Yup from 'yup';

import {
  Button,
  BackButton,
  BackButtondiv,
  BackButtonnext,
  ContentContainer,
  DesktopBackButtonContainer,
  NextButtonContainer,
  BackCancelContainer,
  DesktopBackButton,
  StyledErrorMessage,
  Title,
  Subtitle,
  Subtitlemobilebottom,
  Titlembile,
  StyledInput,
  SearchContainer,
  StyledErrorMessage2,
  PhoneInputContainer,
  AsteriskNote,
} from './step2.style';
import SelectCountry from '../../components/forms/formSignUp/selectCountry/SelectCountry';

const validationSchema = Yup.object().shape({
  companyName: Yup.string().required('Company Name is required'),
  address: Yup.string().required('Address is required'),
  city: Yup.string().required('City is required'),
  postcode: Yup.string().required('Postcode is required'),
});
type AddressFormValues = {
  address: string;
  street: string;
  city: string;
  postcode: string;
  country: string;
};

interface FormValues {
  email: string;
  password: string;
  confirmPassword?: string;
  companyName?: string;
  country?: string;
  name?: string;
  accountType?: string;
  address?: string;
  street?: string;
  postcode?: string;
  city?: string;
  phone?: string;
  wensite?: string;
}

interface Step2Props {
  onNext: (values: FormValues) => Promise<void>;
  onBack: () => void;
  onCancel: () => void;
  initialValues: FormValues;
  loginProvider: any;
}
const Step2: React.FC<Step2Props> = ({
  onNext,
  onBack,
  onCancel,
  initialValues,
  loginProvider,
}: Step2Props) => {
  const [isLoading, setIsLoading] = useState(false);
  const [phoneCode, setPhoneCode] = useState('');
  const [formValues, setFormValues] = useState<FormValues>(initialValues);
  const isFormInitialized = useRef(false);
  const inputRef = useRef<google.maps.places.SearchBox | null>(null);
  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_API_KEY || '',
    libraries: ['places'],
  });

  useEffect(() => {
    // Check if user data exists in local storage
    const user = localStorage.getItem('user');
    if (user) {
      const parsedUser = JSON.parse(user);
      if (parsedUser.companies && parsedUser.companies.length > 0) {
        // const companyId = parsedUser.companies[0].id;
      } else {
        //
      }
    } else {
      //
    }
  }, []);

  useEffect(() => {
    if (loginProvider && loginProvider.data && !isFormInitialized.current) {

      const { name, email } = loginProvider.data;
      const [firstName = '', lastName = ''] = name ? name.split(' ') : [];
      setFormValues(prevValues => ({
        ...prevValues,
        email: email || '',
        firstName: firstName || '',
        lastName: lastName || '',
        companyName: '',
        country: '',
        password: '',
      }));
      isFormInitialized.current = true;
    }

    return () => {
      isFormInitialized.current = false;
    };
  }, [loginProvider]);

  const handleCountryChange = useCallback((country: string, code: string) => {
    setPhoneCode(code);
    // setCountry(code); 

  }, []);

  useEffect(() => {
    const userData = localStorage.getItem('user');
    if (userData) {
      // const parsedUser = JSON.parse(userData);
      
    }
  }, []);

  const handlePlacesChanged = (
    setFieldValue: FormikHelpers<AddressFormValues>['setFieldValue'],
    setFieldTouched: FormikHelpers<AddressFormValues>['setFieldTouched'],
  ) => {
    if (inputRef.current) {
      const places = inputRef.current.getPlaces();
      if (places && places.length > 0) {
        const place = places[0];
        const address = place.formatted_address || '';
        const street =
          place.address_components?.find(comp => comp.types.includes('route'))
            ?.long_name || '';
        const city =
          place.address_components?.find(comp =>
            comp.types.includes('locality'),
          )?.long_name || '';
        const postcode =
          place.address_components?.find(comp =>
            comp.types.includes('postal_code'),
          )?.long_name || '';
        const country =
          place.address_components?.find(comp => comp.types.includes('country'))
            ?.long_name || '';

        // console.log('address', address);
        // console.log('street', street);
        // console.log('city', city);
        // console.log('postcode', postcode);
        // console.log('country', country);

        setFieldValue('address', address);
        setFieldValue('street', street);
        setFieldValue('city', city);
        setFieldValue('postcode', postcode);
        setFieldValue('country', country);
        setFieldValue('country', country);  
        setFieldTouched('country', true);



      }
    }
  };

  const handleSearchBoxLoad = (ref: google.maps.places.SearchBox) => {
    inputRef.current = ref;
  };
  return (
    <Formik
      initialValues={
        loginProvider && loginProvider.data ? formValues : initialValues
      }
      validationSchema={validationSchema}
      enableReinitialize
      onSubmit={async (
        values,
         { setTouched, setFieldTouched ,resetForm},
      ) => {
        // console.log('values is', values);
        setTouched({
          email: true,
          companyName: true,
          country: true,
          name: true,
          phone: true,
          address: true,
        });
        setFieldTouched('address', true);
        setFieldTouched('street', true);
        setFieldTouched('city', true);
        setFieldTouched('postcode', true);
        setFieldTouched('country', true);
        setFieldTouched('postcode', true);
        setIsLoading(true);
        try {
          await onNext(values);
          setIsLoading(true);

          resetForm();
        } catch (error) {
          setIsLoading(true);
        } finally {
          setIsLoading(false);
        }
      }}>
      {({
        setFieldValue,
        setFieldTouched,
        touched,
        errors,
        values,
        isValid,
        dirty,
      }) => (
        <>
          <Form>
            <ContentContainer>
              <Title>Welcome to the Future of Marketing</Title>
              <Subtitle>
                Please enter some extra information about your business so you
                can have a better service and a more effective AI support .
              </Subtitle>
              <Titlembile>Let’s get to know each other.</Titlembile>
              <Subtitlemobilebottom>
                Please enter some extra information about your business so you
                can have a better service and a more effective AI support.
              </Subtitlemobilebottom>
              <Field
                name="companyName"
                type="text"
                placeholder="Business name*"
                as={StyledInput}

              />
              <ErrorMessage name="companyName" component={StyledErrorMessage} />

              <SearchContainer>
                {isLoaded && (
                  // <StandaloneSearchBox onLoad={handleSearchBoxLoad} onPlacesChanged={handlePlacesChanged}>
                  // <StandaloneSearchBox onLoad={handleSearchBoxLoad} onPlacesChanged={() => handlePlacesChanged(setFieldValue)}>
                  <StandaloneSearchBox
                    onLoad={handleSearchBoxLoad}
                    onPlacesChanged={() =>
                      handlePlacesChanged(
                        setFieldValue,
                        setFieldTouched,
                      )
                    }>
                    <Field
                      name="address"
                      as={StyledInput}
                      placeholder="Address*"
                      style={{ width: '100%' }}
                    />
                  </StandaloneSearchBox>
                )}
                <ErrorMessage name="address" component={StyledErrorMessage2} />
              </SearchContainer>

              <Field
                name="city"
                type="text"
                placeholder="City*"
                as={StyledInput}
              />
              <ErrorMessage name="city" component={StyledErrorMessage} />

              <Field
                name="postcode"
                type="text"
                placeholder="Postcode*"
                as={StyledInput}

              />
              <ErrorMessage name="postcode" component={StyledErrorMessage} />

              <Field
                name="country"
                component={SelectCountry}
                menuPlacement="top"
                setFieldValue={setFieldValue}
                setFieldTouched={setFieldTouched}
                touched={touched}
                errors={errors}
                selectedCountry={values.country}
                onCountryChange={handleCountryChange}
              />

              <StyledErrorMessage>
                {touched.country && errors.country && errors.country}
              </StyledErrorMessage>

              <PhoneInputContainer>
                <Field
                  name="phoneCode"
                  type="tel"
                  placeholder="Code"
                  as={StyledInput}
                  value={phoneCode} 
                  style={{ width: '30%', marginRight: '5px' }} 
                  disabled
                />
                <Field
                  name="phone"
                  type="number"
                  placeholder="Phone"
                  as={StyledInput}
                  style={{ width: '70%', flexGrow: 1 }} 
                />
              </PhoneInputContainer>
              <ErrorMessage name="phone" component={StyledErrorMessage} />

              <Field
                name="website"
                type="text"
                placeholder="Website"
                as={StyledInput}
              />
              <ErrorMessage name="website" component={StyledErrorMessage} />
              <AsteriskNote>* Required fields</AsteriskNote>

              <DesktopBackButtonContainer>
                <NextButtonContainer>
                  <Button
                    type="submit"
                    disabled={isLoading || !isValid || !dirty}>
                    {isLoading ? 'Signing Up...' : 'Next Step'}
                  </Button>
                </NextButtonContainer>
                <BackCancelContainer>
                  <DesktopBackButton onClick={onCancel}>
                    Cancel
                  </DesktopBackButton>
                </BackCancelContainer>
              </DesktopBackButtonContainer>
            </ContentContainer>

            <BackButtondiv>
              <BackButton onClick={onBack}>Back</BackButton>
              <BackButtonnext
                type="submit"
                disabled={isLoading || !isValid || !dirty}>
                {isLoading ? 'Signing Up...' : 'Next Step'}
              </BackButtonnext>
            </BackButtondiv>
          </Form>

          {isLoading && (
            <FlexboxGrid
              justify="center"
              align="middle"
              style={{
                position: 'fixed',
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                backgroundColor: 'rgba(0, 0, 0, 0.5)',
                zIndex: 1000,
              }}>
              <FlexboxGrid.Item>
                <Loader size="lg" content="Signing Up..." />
              </FlexboxGrid.Item>
            </FlexboxGrid>
          )}
        </>
      )}
    </Formik>
  );
};

export default Step2;
