/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable camelcase */
import React, {
  useEffect,
  useState,
  forwardRef,
  useImperativeHandle,
} from 'react';
import { useTheme } from 'styled-components';
import {
  ButtonAdd,
  ButtonContainer,
  Container,
  ParagraphColor,
  TextContainer,
} from '../Toogle.styles';
import axios from '../../../lib/axios';
import { TYPES_TOOLS } from '../../../actions/toolsActions';
import { TYPES_OF_SOCIAL_NETWORKS } from '../../../lib/globalValues';
import { disableTool } from '../../../services/disableTool';
import { notificationError } from '../../../lib/notification';
import { handleTiktokToolChange } from '../../../services/tiktokTools';
import TiktokWindow from '../../socialWindows/TiktokWindow';

interface Props {
  companyId?: number;
  dispatchTool: any;
  stateTool: any;
  invalidateQuery?: () => void;
  signup?: boolean;
  newFormat?: boolean;
  clickedSocial?: string;
  socialNetworkPages?: {
    facebook: boolean;
    google: boolean;
    instagram: boolean;
    twitter: boolean;
    tiktok: boolean;
  };
}
interface TiktokToogleRef {
  handleClick: () => void;
}

const TiktokToogle = forwardRef<TiktokToogleRef, Props>(
  (
    {
      companyId,
      stateTool,
      dispatchTool,
      invalidateQuery,
      signup = false,
      newFormat = false,
      clickedSocial,
      socialNetworkPages,
    }: Props,
    ref,
  ) => {
    const theme = useTheme();
    const [hasFocus, setHasFocus] = useState(true);
    const [isTiktok, setIsTiktok] = useState<boolean>(false);
    const [authUrl, setAuthUrl] = useState<string>('');
    const activated =
      stateTool[TYPES_OF_SOCIAL_NETWORKS.TIKTOK]?.enabled === true;

    const tiktokChecked = async () => {
      try {
        const { success, auth_url } = await startOAuth2Flow();
        if (success) {
          setAuthUrl(auth_url);
          setIsTiktok(true);
          handleTiktokToolChange({
            setIsTiktok,
            dispatchTool,
            companyId,
            invalidateQuery,
          });
        }
      } catch (error) {
        // eslint-disable-next-line no-console
        console.log('error', error);
        //  console.error(`OAuth 2.0 flow failed: ${error.message}`);
      }
    };
    useImperativeHandle(
      ref,
      () => ({
        handleClick: () => {
          if (clickedSocial === 'TIKTOK') {
            tiktokChecked();
          }
        },
      }),
      [clickedSocial, tiktokChecked],
    );
    const startOAuth2Flow = async () => {
      const response = await axios.get('/tools/startTiktokAuth');

      return response.data;
    };

    useEffect(() => {
      const handleFocus = () => setHasFocus(prev => !prev);
      window.addEventListener('focus', handleFocus);
      return () => window.removeEventListener('focus', handleFocus);
    }, []);

    useEffect(() => {
      const rawError = localStorage.getItem('tiktokError');
      const storedError = rawError ? JSON.parse(rawError) : null;

      if (storedError && storedError.access_denied) {
        notificationError({
          title: 'Error',
          description: 'The TikTok account could not be added.',
        });
        localStorage.removeItem('tiktokError');
      }
    }, [hasFocus]);

    return (
      <>
        {isTiktok && <TiktokWindow auth_url={authUrl} />}

        {activated ? (
          <Container
            style={{
              justifyContent: signup ? 'end' : 'space-between',
            }}>
            {signup && (
              <ButtonAdd
                onClick={(event: React.SyntheticEvent) => {
                  event.stopPropagation();
                  if (companyId) {
                    disableTool(
                      stateTool[TYPES_OF_SOCIAL_NETWORKS.TIKTOK].id,
                    ).then(() => {
                      dispatchTool({ type: TYPES_TOOLS.DEACTIVATE_TIKTOK });
                      invalidateQuery?.();
                    });
                  } else {
                    dispatchTool({ type: TYPES_TOOLS.DEACTIVATE_TIKTOK });
                  }
                }}
                disabled={signup}
                style={{
                  width: signup ? '120px' : 'auto',
                  color: signup ? '#64E000' : 'inherit',
                  fontFamily: signup ? "'Inter', sans-serif" : 'inherit',
                  cursor: signup ? 'not-allowed' : 'pointer',
                }}>
                Connected
              </ButtonAdd>
            )}
            {!signup && !newFormat && (
              <TextContainer>
                <ParagraphColor status={1}>Connected</ParagraphColor>
              </TextContainer>
            )}
            {!signup && (
              <ButtonAdd
                bgColor={signup ? 'black' : theme.colors.newThemeColor}
                onClick={(event: React.SyntheticEvent) => {
                  event.stopPropagation();
                  disableTool(
                    stateTool[TYPES_OF_SOCIAL_NETWORKS.TIKTOK].id,
                    'TikTok',
                  ).then(() => {
                    dispatchTool({ type: TYPES_TOOLS.DEACTIVATE_TIKTOK });
                    invalidateQuery?.();
                  });
                }}
                pad={!activated ? '8px 12px' : '8px 4px'}
                style={{
                  width: signup ? '120px' : 'auto',
                }}>
                Disconnect
              </ButtonAdd>
            )}
          </Container>
        ) : (
          <ButtonContainer signup={signup}>
            <ButtonAdd
              signup={signup}
              newFormat={newFormat}
              bgColor={theme.colors.grey}
              pad={!activated ? '8px 12px' : '8px 4px'}
              onClick={(event: React.SyntheticEvent) => {
                event.stopPropagation();
                tiktokChecked();
              }}>
              Connect
            </ButtonAdd>
          </ButtonContainer>
        )}
      </>
    );
  },
);

export default TiktokToogle;
