import { useEffect, useRef } from 'react';
import { useQuery } from 'react-query';
import { Loader } from 'rsuite';
import axios from '../../lib/axios';
import Message2 from '../message/Message2';
import {Container} from './messege.styles';

const fetchMessages = async (): Promise<TMessage[]> => {
  try {
    const { data } = await axios.get(`/tools/messages`);
    return data;
  } catch (err) {
    throw new Error(err);
  }
};
interface Messages2Props {
  onMessagesUpdate: (messages: TMessage[]) => void;
}
const Messages2 = ({ onMessagesUpdate }:Messages2Props) => {
  const {
    isLoading,
    isError,
    isFetching,
    refetch,
    data: messages,
  } = useQuery(`MESSAGES`, fetchMessages, {
    staleTime: 10 * 60 * 1000,
    notifyOnChangePropsExclusions: ['isStale'],
    refetchOnWindowFocus: false,
    retry: false,
  });
  // used to keep a check of initial component render
  const initialData = useRef(messages);

  useEffect(() => {
    initialData.current = messages;
    if (messages) {
      onMessagesUpdate(messages); // Notify parent of updated messages
    }
  }, [messages, onMessagesUpdate]);
  const handleUpdate = () => {
    /**
     * Perform any necessary updates to the data
     * & Refetch the messages
     *  */
    refetch();
  };

  // used to keep a check of initial component render

  useEffect(() => {
    refetch();
    if (messages) {
      onMessagesUpdate(messages); // Notify parent of updated messages
    }
  }, [messages]);

  if (!initialData.current && (isLoading || isFetching)) {
    return <Loader content="loading" />;
  }

  setTimeout(refetch, 600 * 1000);
  // const totalMessages = messages?.length || 0;

  return (
    <Container>
      {/* <div>Total Messages: {totalMessages}</div> */}

      {!isError &&
        messages?.map(message => (
          <Message2
            handleUpdate={handleUpdate}
            key={message.senderId}
            messages={message}
          />
        ))}
    </Container>
  );
};

export default Messages2;
