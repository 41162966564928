import styled from 'styled-components';

export const Title = styled.p`
  font-size: ${props => props.theme.fonts.bigSubtitle};
  font-weight: bold;
  text-align: center;
  margin-bottom: 15px;
`;

export const SpaceDiv = styled.div`
  margin-bottom: 15%;
`;
