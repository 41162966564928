import { Button, Modal } from 'rsuite';
import styled from 'styled-components';

export const ModalTitle = styled.p`
  font-weight: bolder;
  font-size: ${props => props.theme.fonts.title};
`;

export const ModalSubtitle = styled.p`
  font-size: ${props => props.theme.fonts.subtitle};
`;

export const ModalPosition = styled(Modal)`
  @media ${props => props.theme.breakpoints.xs} {
    position: absolute;
    bottom: 0px;
    width: 100%;
  }

  .rs-modal-header-close {
    display: none;
  }
`;

export const Button2 = styled(Button)`
  color: ${props => props.theme.colors.red};
  background-color: ${props => props.theme.colors.white};
  padding: 8px 40px;
  border-radius: 7px;
  border: 1px solid ${props => props.theme.colors.red};
  color: ${props => props.theme.colors.red};
  font-size: 14px;
  font-family: 'Inter', sans-serif;
  margin: ${props => (props.mar ? '10px 10px' : '10px 10px')};
  /* margin: 10px 0px; */
  min-width: 80px;
  &:hover {
    background-color: ${props => props.theme.colors.red};
    color: ${props => props.theme.colors.white};
  }
`;
export const Button3 = styled(Button)`
  color: ${props => props.theme.colors.white};
  background-color: ${props => props.theme.colors.red};
  padding: 10px 34px;
  border-radius: 7px;
  border: none;
  font-size: 14px;
  font-family: 'Inter', sans-serif;
  margin: 0px 10px;
  min-width: 80px;

  &:disabled {
    background-color: ${props => props.theme.colors.lightPink};
    color: ${props => props.theme.colors.red};

    &:hover {
      color: ${props => props.theme.colors.red};
      background-color: ${props => props.theme.colors.lightPink};
    }
  }

  &:hover {
    color: ${props => props.theme.colors.white};
    background-color: ${props => props.theme.colors.red};
  }
`;
export const ModalBody = styled(Modal.Body)`
  padding: 20px;
`;
