import { useState } from 'react';
import { useTheme } from 'styled-components';
import { SpaceBetween, CircleButton } from './ColorsContent.styles';

interface Props {
  componentColor: {
    background: string;
    colorTitle: string;
    colorSubtitle: string;
  };
  typePosition: 'background' | 'colorTitle' | 'colorSubtitle';

  setComponentColor: React.Dispatch<
    React.SetStateAction<{
      background: string;
      colorTitle: string;
      colorSubtitle: string;
    }>
  >;
}

const ColorsContent = ({
  componentColor,
  typePosition,
  setComponentColor,
}: Props) => {
  const theme = useTheme();
  const [selectedColor, setSelectedColor] = useState<string | null>(null);

  const handleChangeColor = (color: string) => {
    setComponentColor({ ...componentColor, [typePosition]: color });
    setSelectedColor(color);
  };

  return (
    <SpaceBetween>
      {[
        theme.colors.orange,
        theme.colors.blue,
        theme.colors.green,
        theme.colors.white,
        theme.colors.black,
      ].map(color => (
        <CircleButton
          key={color}
          type="button"
          bgColor={color}
          onClick={() => handleChangeColor(color)}
          style={{
            border: selectedColor === color ? '2px solid red' : 'none',
          }}
        />
      ))}
    </SpaceBetween>
  );
};

export default ColorsContent;
