/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable no-console */
/* eslint-disable no-unused-expressions */

import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { ScrollMenu } from 'react-horizontal-scrolling-menu';
import { useQuery } from 'react-query';
import { Col, FlexboxGrid, Loader, Row } from 'rsuite';
import FacebookIcon from '../../assets/icons/facebook.svg';
import FacebookGrey from '../../assets/icons/facebookGrey.svg';
import InstagramIcon from '../../assets/icons/instagram.png';
import InstagramGrey from '../../assets/icons/instagramGrey.svg';
import Advert from '../../components/advert/Advert';
import { LeftArrow, RightArrow } from '../../components/arrows/Arrows';
import BasicCard from '../../components/cards/basicCard/BasicCard';
import BoostableModal from '../../components/modals/boostableModal/BoostableModal';
import RoundedButton from '../../components/roundedButton/RoundedButton';
import axios from '../../lib/axios';
import { formatNumber, getCurrencySymbol } from '../../lib/helpers';
import { Container } from '../../styles/generalStyles';
import {
  HeaderContent,
  InfoContent,
  NewPromotionContainer,
  PlatformFilterContainer,
  PlatformIcon,
  SelectorContainer,
} from './Promotions.styles';
import { useAuth } from '../../context/AuthContext';

const CONTENT_SELECT_BUTTONS = [
  {
    value: 'yesterday',
    content: 'Yesterday',
  },
  {
    value: 'last7Days',
    content: 'Last 7 Days',
  },
  {
    value: 'last30Days',
    content: 'Last 30 Days',
  },
];

const fetchAdverts = async (): Promise<AdAccount> => {
  try {
    const { data } = await axios.get(`/promotions`);
    return data;
  } catch (err) {
    throw new Error(err);
  }
};

const Promotions = () => {
  const [filter, setFilter] = useState(CONTENT_SELECT_BUTTONS[2].value);
  const [showModal, setShowModal] = useState(false);
  const [selectedPlatforms, setSelectedPlatforms] = useState<string[]>([
    'facebook',
    'instagram',
  ]);
  const [filteredAds, setFilteredAds] = useState<Advert[]>([]);
  const { user } = useAuth();
  const [isActive, setIsActive] = useState<any>([]);

  useEffect(() => {
    if (user) {
      const userTools = user?.companies?.[0]?.tools;

      const activeTool = userTools?.filter(
        item => item.enabled && item.type === 'facebook',
      );

      setIsActive(activeTool);
    }
  }, [user]);

  const fetchStatistics = async (): Promise<{
    insights?: UserInsight | undefined;
    currency: string;
  }> => {
    try {
      const { data } = await axios.get(
        `promotions/statistics?filter=${filter}`,
      );
      return data;
    } catch (err) {
      throw new Error(err);
    }
  };

  const updateSelectedPlatforms = (
    clickedPlatform: 'facebook' | 'instagram',
  ) => {
    if (selectedPlatforms.includes(clickedPlatform)) {
      const newPlatforms = selectedPlatforms.filter(
        platform => platform !== clickedPlatform,
      );
      setSelectedPlatforms([...newPlatforms]);
    } else {
      setSelectedPlatforms([...selectedPlatforms, clickedPlatform]);
    }
  };

  const {
    isLoading: isLoadingAdverts,
    isError: isErrorAdverts,
    isFetching: isFetchingAdverts,
    data: ads,
  } = useQuery(`ADVERTS`, fetchAdverts, {
    retry: false,
    staleTime: 5000,
    notifyOnChangePropsExclusions: ['isStale'],
    refetchOnWindowFocus: false,
  });

  const {
    isLoading: isLoadingStatistics,
    isError: isErrorStatistics,
    isFetching: isFetchingStatistics,
    data: statistics,
  } = useQuery(`ADVERTS-STATISTICS-${filter}`, fetchStatistics, {
    staleTime: 5000,
    notifyOnChangePropsExclusions: ['isStale'],
    refetchOnWindowFocus: false,
  });

  useEffect(() => {
    /* https://developers.facebook.com/docs/marketing-api/audiences/reference/placement-targeting/
  if you do not select any publisher_platforms, Facebook considers all default publisher_platforms.
  to show ads published using the facebook ads manager
  */
    const newAds = ads?.ads?.data?.filter(advert => {
      return selectedPlatforms.some(
        platform =>
          advert.targeting?.publisher_platforms
            ? advert.targeting.publisher_platforms.includes(
                platform as 'facebook' | 'instagram',
              )
            : true, // Include the ad if publisher_platforms doesn't exist
      );
    });

    if (newAds) {
      setFilteredAds(newAds);
    } else {
      setFilteredAds([]);
    }
  }, [ads, selectedPlatforms]);

  return (
    <Container>
      <BoostableModal show={showModal} onClose={() => setShowModal(false)} />
      <HeaderContent style={{ alignItems: 'center' }}>
        <Col xs={24} md={12} />
        {/* <Col xs={24} md={12}>
          <FlexboxGrid justify="end">
            <SelectorContainer>
              <SelectButtons
                content={CONTENT_SELECT_BUTTONS}
                defaultValue={filter}
                setPage={setFilter}
              />
              {filter !== CONTENT_SELECT_BUTTONS[0].value && (
                <span>{`Note: Does not include today\n's data`}</span>
              )}
            </SelectorContainer>
          </FlexboxGrid>
        </Col> */}
      </HeaderContent>
      {(isLoadingStatistics || isFetchingStatistics) && !isErrorStatistics && (
        <Loader content="loading" />
      )}
      {!isLoadingStatistics &&
        !isFetchingStatistics &&
        !isErrorStatistics &&
        statistics && (
          <Row>
            <Col xs={24} md={5}>
              <BasicCard
                title="Total spend"
                value={`${getCurrencySymbol(
                  statistics?.currency as string,
                )}${formatNumber(
                  statistics?.insights?.data[0]?.spend || 0,
                  2,
                )}`}
              />
            </Col>
            <Col xs={24} md={5}>
              <BasicCard
                title="Reach"
                value={formatNumber(statistics?.insights?.data[0]?.reach || 0)}
              />
            </Col>
            <Col xs={24} md={5}>
              <BasicCard
                title="Link Clicks"
                value={formatNumber(
                  statistics?.insights?.data[0]?.inline_link_clicks || 0,
                )}
              />
            </Col>
            <Col xs={24} md={5}>
              <BasicCard
                title="Cost per click"
                value={`${getCurrencySymbol(
                  statistics?.currency as string,
                )}${formatNumber(
                  statistics?.insights?.data[0]?.cost_per_inline_link_click ||
                    0,
                  2,
                )}`}
              />
            </Col>
          </Row>
        )}

      {(isLoadingAdverts || isFetchingAdverts) && !isErrorAdverts && (
        <Loader center content="loading" />
      )}
      {isErrorAdverts && (
        <InfoContent>
          <p>
            {isActive && isActive?.length > 0
              ? 'To manage your promotions you have to connect your facebook Ads account.'
              : 'Your Facebook Account is currently not connected. To access Promotions, go to Tools and connect your Facebook business pages and your Ads Account.'}
          </p>
          <p>
            Please click{' '}
            {isActive && isActive?.length > 0 ? (
              <Link
                to={{
                  pathname: '/user-panel/tools',
                  state: { enableAdsTool: true },
                }}>
                here
              </Link>
            ) : (
              <Link to="/user-panel/tools">here</Link>
            )}{' '}
            to proceed
          </p>
        </InfoContent>
      )}
      {!isLoadingAdverts && !isFetchingAdverts && !isErrorAdverts && ads && (
        <>
          <Row>
            <FlexboxGrid justify="start">
              <PlatformFilterContainer>
                <h5>Filter by platform:</h5>
                <PlatformIcon
                  src={
                    selectedPlatforms.includes('facebook')
                      ? FacebookIcon
                      : FacebookGrey
                  }
                  alt="facebook icon"
                  onClick={() => updateSelectedPlatforms('facebook')}
                />
                <PlatformIcon
                  src={
                    selectedPlatforms.includes('instagram')
                      ? InstagramIcon
                      : InstagramGrey
                  }
                  alt="instagram icon"
                  onClick={() => updateSelectedPlatforms('instagram')}
                />
              </PlatformFilterContainer>
            </FlexboxGrid>
          </Row>

          <NewPromotionContainer onClick={() => setShowModal(true)}>
            <div>
              <RoundedButton />
              <h5>New promotion on Facebook (and Instagram)</h5>
            </div>
          </NewPromotionContainer>

          {filteredAds.length ? (
            <ScrollMenu LeftArrow={LeftArrow} RightArrow={RightArrow}>
              {filteredAds.map(advert => (
                <Advert
                  advert={advert}
                  key={advert.id}
                  currency={ads.currency}
                  itemId={advert.id}
                />
              ))}
            </ScrollMenu>
          ) : (
            <h4>No promotions for selected filters</h4>
          )}
        </>
      )}
    </Container>
  );
};

export default Promotions;
