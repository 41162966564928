import { TYPES_TOOLS } from '../actions/toolsActions';
import { TYPES_OF_SOCIAL_NETWORKS } from '../lib/globalValues';
import {
  notificationError,
  notificationWithSimpleNodes,
} from '../lib/notification';
import { assignTool } from './assignTool';

/* eslint-disable @typescript-eslint/no-explicit-any */
export const handleTiktokToolChange = async ({
  setIsTiktok,
  dispatchTool,
  companyId,
  invalidateQuery,
}: {
  setIsTiktok: (arg: boolean) => void;
  dispatchTool: any;
  companyId?: number;
  invalidateQuery?: () => void;
}) => {
  let done = false;
  setIsTiktok(true);
  await localStorage.removeItem(TYPES_OF_SOCIAL_NETWORKS.TIKTOK);
  let tiktokStorage = localStorage.getItem(TYPES_OF_SOCIAL_NETWORKS.TIKTOK);
  if (tiktokStorage) {
    await sendTiktokToken({
      dispatchTool,
      companyId,
      tiktokStorage,
      invalidateQuery,
    });
    await localStorage.removeItem(TYPES_OF_SOCIAL_NETWORKS.TIKTOK);
  } else {
    await window.addEventListener(
      'storage',
      async e => {
        tiktokStorage = localStorage.getItem(TYPES_OF_SOCIAL_NETWORKS.TIKTOK);
        if (tiktokStorage && !done && e.key === 'tiktok') {
          await sendTiktokToken({
            dispatchTool,
            companyId,
            tiktokStorage,
            invalidateQuery,
          });
          await localStorage.removeItem(TYPES_OF_SOCIAL_NETWORKS.TIKTOK);
          done = true;
        }
      },
      { once: false },
    );
  }

  setIsTiktok(false);
};

const sendTiktokToken = async ({
  dispatchTool,
  companyId,
  tiktokStorage,
  invalidateQuery,
}: {
  dispatchTool: any;
  companyId?: number;
  tiktokStorage: string;
  invalidateQuery?: () => void;
}) => {
  try {
    const tiktokData = JSON.parse(tiktokStorage);
    const defaultContent = [
      {
        type: TYPES_OF_SOCIAL_NETWORKS.TIKTOK,
        accessToken: tiktokData.accessToken,
        pageId: tiktokData.id,
        pageName: `${tiktokData.name}`,
        userId: tiktokData.userId,
        enabled: true,
        profilePic: tiktokData.profilePic,
      },
    ];

    if (companyId) {
      const response = await assignTool({
        companyId,
        tool: defaultContent,
      });

      if (response.data) {
        dispatchTool({
          type: TYPES_TOOLS.ACTIVATE_TIKTOK,
          payload: response.data[0],
        });
      }
    } else {
      dispatchTool({
        type: TYPES_TOOLS.ACTIVATE_TIKTOK,
        payload: defaultContent[0],
      });
    }

    invalidateQuery?.();

    notificationWithSimpleNodes({
      type: 'open',
      title: '',
      description: 'TikTok was enabled',
    });
  } catch (error) {
    dispatchTool({ type: TYPES_TOOLS.DEACTIVATE_TIKTOK });
    notificationError({
      title: 'Error',
      description:
        error?.response?.data?.message || 'The tiktok could not be added.',
    });
  }
};
