import { Button, Divider, FlexboxGrid } from 'rsuite';
import styled from 'styled-components';

export const Title = styled.p`
  font-size: ${props => props.theme.fonts.bigSubtitle};
  font-weight: bold;
  text-align: center;
  margin-bottom: 15px;
`;

export const SubTitle = styled.p`
  font-size: ${props => props.theme.fonts.bigSubtitle};
  font-weight: bold;
  margin: 5% 0px;
  text-align: center;

  span {
    color: ${props => props.theme.colors.black};
    text-align: center;
  }
`;

export const ButtonSignSocial = styled(Button)<{ $color?: string }>`
  background-color: ${props =>
    props.$color === 'green'
      ? props.theme.colors.green
      : props.theme.colors.roseRush};
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);

  margin: 10px 0px;
  border-radius: 50px;
  width: 100%;
  padding: 20px 0;
  color: ${props => props.theme.colors.white};
  font-weight: bold;
  font-size: 1.6em !important;

  @media ${props => props.theme.breakpoints.xs} {
    font-size: 1.4em !important;
  }

  &:hover {
    background-color: ${props =>
      props.$color === 'green'
        ? props.theme.colors.green
        : props.theme.colors.grey};
  }

  div {
    padding: 0px !important;
  }
`;

export const ImgSvg = styled.img`
  width: 100%;
`;

export const ButtonGroupSpace = styled(FlexboxGrid)`
  margin-top: 40px;
  @media ${props => props.theme.breakpoints.sm} {
    gap: 17px;
    transform: translate(0px, 15px);
  }
`;

export const SpaceDiv = styled.div`
  margin-bottom: 15%;
`;

export const BorderDivider = styled(Divider)`
  font-weight: bold;
  padding: 25px 0 15px 0;
  font-size: ${props => props.theme.fonts.subtitle};
`;
