import { Link } from 'react-router-dom';
import styled, { keyframes } from 'styled-components';
import { FlexboxGrid as RSFlexboxGrid } from 'rsuite';


export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  height: 100vh;
  overflow-y: auto;
`;

export const ContentContainer = styled.div`
  width: 90%;
  height: 95%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;

  @media (max-width: 780px) {
    width: 100%;
    // overflow-y: auto;
  }
`;

export const Title = styled.h2`
  height: 10%;
  margin-top: 10px;
  font-family: 'Inter', sans-serif;
  font-size: 35px;
  color: #dd2256;
  margin: 0 auto 30px auto;
  padding: 0 60px;
  font-family: 'Inter', sans-serif;
  text-align: center !important;
  margin-top: 40px;
  @media (max-width: 1480px) {
    font-size: 30px;
    margin: 0 auto 15px auto;
    padding: 0 60px;
    margin-top: 80px;
  }
  @media (max-width: 1280px) {
    font-size: 22px;
    margin: 0 auto 15px auto;
    padding: 0 60px;
  }
  @media (max-width: 1024px) {
    font-size: 22px;
    margin: 0 auto 10px auto;
    padding: 0 60px;
  }
  @media (max-width: 780px) {
    display: none;
  }
`;
export const Subtitle = styled.p`
  height: 10%;
  font-family: 'Inter', sans-serif;
  width: 85%;
  font-size: 16px;
  color: #666;
  margin-bottom: 60px;
  padding: 0 50px;
  text-align: center; /* Center the text */
  @media (max-width: 1480px) {
    margin-bottom: 50px;

    font-size: 14px;
    color: #666;
    padding: 0 50px;
  }
  @media (max-width: 1280px) {
    font-size: 14px;
    padding: 0 50px;
    width: 80%;
  }
  @media (max-width: 1024px) {
    font-size: 14px;
    padding: 0 50px;
  }
  @media (max-width: 780px) {
    display: none;
  }
`;
export const Titlembile = styled.h2`
  display: none;

  @media (max-width: 780px) {
    display: flex;
    width: 100%;
    justify-content: center;
    flex-direction: row;
    font-family: 'Inter', sans-serif;
    display: block;
    font-size: 26px;
    color: #dd2256;
    font-family: 'Inter', sans-serif;
    text-align: center;
    @media (max-width: 480px) {
      display: flex;

      font-size: 22px;
      justify-content: center;
    }
    @media (max-width: 380px) {
      display: flex;

      font-size: 22px;
      justify-content: center;
    }
  }
`;
export const Subtitlemobile = styled.p`
  display: none;

  @media (max-width: 780px) {
    width: 65%;
    font-family: 'Inter', sans-serif;
    display: block;
    font-size: 14px;
    color: #666;
    text-align: left;
    margin-bottom: 5px;
    margin-top: 10px;
  }

  @media (max-width: 480px) {
    width: 85%;
    padding: 0 20px;
    font-size: 12px;
    margin-bottom: 5px;
    margin-top: 10px;
  }
  @media (max-width: 380px) {
    width: 90%;
    padding: 0 20px;
    font-size: 12px;
    margin-bottom: 5px;
    margin-top: 10px;
  }
`;

export const socialcontainers = styled.div`
  display: flex;
  position: absolute;
  bottom: 0;
  left: 0;
  flex-direction: column;
  width: 100%;
  background: #fff;
  justify-content: space-between;
  justify-content: space-between;
  align-items: flex-start;
  z-index: 1000;
  border-radius: 15px 15px 0 0;
  min-height: 150px;
  box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.1);
  margin: 0 auto;
  @media (max-width: 780px) {
    display: none;
  }
`;
export const SocialButton = styled.button`
  display: flex;
  margin: 0 auto;
  justify-content: center;
  align-items: center;
  font-family: 'Inter', sans-serif;
  width: 70%;
  background-color: white;
  color: #dd2256;
  border: 1px solid #dd2256;
  padding: 15px;
  border-radius: 15px;
  cursor: pointer;
  transition: background-color 0.3s ease-in, color 0.3s ease-in;
  gap: 18px;
  margin-bottom: 10px;
  &:hover {
    background-color: #dd2256;
    color: white;
  }

  @media (max-width: 1480px) {
    width: 60%;
    padding: 7px;
    border-radius: 12px;
  }
  @media (max-width: 1280px) {
    width: 65%;
    padding: 10px;
  }
  @media (max-width: 780px) {
    width: 65%;
    margin: 10px auto;
    padding: 10px;
  }
  @media (max-width: 480px) {
    width: 80%;
    padding: 10px;
  }
  @media (max-width: 380px) {
    padding: 10px;
  }
`;

export const SocialText = styled.span`
  font-family: 'Inter', sans-serif;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-size: 1rem;
  display: flex;
  justify-content: center;
  // align-items: center;

  @media (max-width: 1480px) {
    font-size: 0.8rem;
  }
  @media (max-width: 1020px) {
    font-size: 0.8rem;
  }
  @media (max-width: 780px) {
    font-size: 18px;
    font-size: 0.8rem;
  }
  @media (max-width: 480px) {
    font-size: 0.8rem;
  }
  @media (max-width: 320px) {
    font-size: 0.8rem;
  }
`;
const moveUpDown = keyframes`
  0%, 100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-10px);
  }
`;

export const ImageContainer = styled.div`
  height: auto;
  width: 80%;
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  justify-content: center;

  img {
    animation: ${moveUpDown} 2s ease-in-out infinite;
  }
`;
export const ImageContainermobile = styled.div`
  display: none;

  @media (max-width: 780px) {
    display: block;
    max-width: 40%;
    max-height: 20%;
    margin: 0 auto;
  }
  @media (max-width: 480px) {
    display: block;
    max-width: 40%;
    min-height: 15%;
  }
`;
export const StyledImage = styled.img`
  width: 80%;
  margin: 0 auto;

  max-height: 100%;
  object-fit: cover;
  margin-bottom: 40px;
  @media (max-width: 1280px) {
    width: 80%;
  }
  @media (max-width: 1020px) {
    width: 70%;
  }
  @media (max-width: 780px) {
    width: 60%;
  }
  @media (max-width: 780px) {
    width: 90%;
  }
`;
export const DinabiteText = styled.p`
  white-space: pre-wrap;

  font-size: 1.2rem;
  height: 40px;
  width: 75%;
  margin: 0 8rem;
  color: grey;
  text-align: center;
  font-family: 'Inter', sans-serif;
  font-size: 16px;

  @media (max-width: 1480px) {
    margin-top: 40px;
    margin: 0 6rem;
    font-size: 14px;
  }
  @media (max-width: 1280px) {
    margin-top: 40px;
    font-size: 14px;
  }
  @media (max-width: 1024px) {
    margin-top: 60px;
    font-size: 14px;
    margin: 0 4rem;
  }
  @media (max-width: 780px) {
    display: none;
  }
`;

export const LeftPane = styled.div`
  width: 40%;
  height: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  background-color: #fff;
  overflow-y: auto;
  overflow-x: hidden;

  @media (max-width: 1024px) {
    width: 50%;
    overflow-y: auto;
  }

  @media (max-width: 780px) {
    width: 100%;
    overflow-y: auto;
  }
`;

export const RightPane = styled.div`
  width: 60%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow-x: hidden;
  background-color: #fff !important;
  @media (max-width: 1024px) {
    width: 50%;
  }

  @media (max-width: 780px) {
    display: none;
  }
`;
export const FlexboxGrid = styled(RSFlexboxGrid)`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  width: 100%;
  border-radius: 8px;

  @media (max-width: 780px) {
    width: 100%;
  }
  &:hover {
    color: black;
  }
`;

export const FlexboxGridItem = styled(RSFlexboxGrid.Item)`
  background-color: #ffffff;
  width: 100%;
  display: flex;
  justify-content: center;
  &:hover {
    cursor: pointer;
  }
  @media (max-width: 1480px) {
    width: 100%;
  }
  @media (max-width: 780px) {
    width: 100%;
  }
`;
export const LinkForm = styled(Link)`
  font-size: 12px;
  color: #4a4a4a;
  cursor: pointer;
  text-decoration: none !important;
  font-weight: 200;
  font-family: 'Inter', sans-serif;
  border-bottom: 0.2px solid #b2b2bd; /* Light gray line */
  :hover {
    color: ${props => props.theme.colors.red};
  }
`;
export const LinkForm2 = styled(Link)`
  /* font-size: ${props => props.theme.fonts.subtitle}; */
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  font-size: 14px;
  font-weight: bold;
  color: #dd2256;
  cursor: pointer;
  text-decoration: none !important;
  font-family: 'Inter', sans-serif;
  :hover {
    color: ${props => props.theme.colors.red};
  }
  margin-top: 40px;
  @media (max-width: 1480px) {
    font-size: 14px;
  }
  @media (max-width: 1280px) {
    font-size: 18px;
  }
  @media (max-width: 1024px) {
    font-size: 16px;
  }
  @media (max-width: 780px) {
    font-size: 16px;
  }
`;
export const StyledInput = styled.input<{ isError?: boolean }>`
  border-color: #ffd6df;
  background-color: #ffd6df;
  font-family: 'Inter', sans-serif;
  width: 68%;
  padding: 1.2rem 2rem;
  margin: 1.4rem auto;
  border-radius: 15px;
  border: none;
  font-size: 1rem;
  color: #dd2256;
  outline: none;
  &:-webkit-autofill {
    background-color: #ffd6df !important;
    -webkit-box-shadow: 0 0 0px 1000px #ffd6df inset !important;
    -webkit-text-fill-color: #dd2256 !important;
  }
  &::placeholder {
    color: #dd2256;
  }
  ::-ms-input-placeholder {
    color: #dd2256;
  }
  &:focus {
    border-color: #f857a6;
  }
  @media (max-width: 1480px) {
    padding: 0.9rem 2rem;

    width: 72%;
  }
  @media (max-width: 1020px) {
    padding: 0.9rem 2rem;

    width: 70%;
  }
  @media (max-width: 780px) {
    width: 96%;
  }
  @media (max-width: 380px) {
    width: 95%;
    font-size: 14px;
  }
`;
export const ErrorMessage = styled.p`
  color: red;
  font-size: 10px;
  margin-top: 0;
  margin-bottom: 10px;
  font-weight: bold;
  width: 65%;
  position: relative;
  visibility: visible;
  @media (max-width: 780px) {
    font-size: 12px;
    margin-bottom: 5px;
    width: 90%;
  }
`;
export const MobileImageContent = styled.img`
  display: none;

  @media (max-width: 768px) {
    display: block;
    max-width: 30%;
    height: auto;
    margin: 0 auto;
  }
  @media (max-width: 428px) {
    display: block;
    max-width: 40%;
    height: auto;
    margin: 0 auto;
  }
`;
export const BackButtondiv = styled.div`
  display: none;

  @media (max-width: 780px) {
    font-family: 'Inter', sans-serif;

    display: flex;
    width: 80%;
    position: fixed;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    background: #fff;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    z-index: 1000;
    min-height: 140px;
  }

  @media (max-width: 480px) {
    padding: 0;
  }
`;

export const BackButton = styled.button`
  background-color: #ffd6df;
  font-family: 'Inter', sans-serif;
  color: #dd2256;
  width: 140px;
  height: 50px;
  font: bold;
  border: 1px solid #dd2256;
  border-radius: 20px;
  font-size: 20px;
  cursor: pointer;
  margin: 0 10px;

  &:hover {
    background: linear-gradient(180deg, #dd2256 0%, #e43c39 100%);
    color: #fff;
  }

  @media (max-width: 780px) {
    width: 140px;
    height: 40px;
    font-size: 18px;
    border-radius: 12px;
    margin: 0 5px;
  }
  @media (max-width: 480px) {
    width: 140px;
    height: 50px;
    font-size: 186x;
    border-radius: 15px;
    margin: 0 5px;
  }

  @media (max-width: 380px) {
    width: 120px;
    height: 40px;
    font-size: 14px;
    border-radius: 15px;
  }
`;

export const BackButtonnext = styled.button`
  font-family: 'Inter', sans-serif;

  color: #fff;
  width: 140px;
  height: 50px;
  border: 1px solid #dd2256;
  border-radius: 20px;
  font-size: 20px;
  cursor: pointer;
  margin: 0 10px;
  background: linear-gradient(180deg, #dd2256 0%, #e43c39 100%);

  &:hover {
    background-color: #c51e4a;
    color: #fff;
  }

  @media (max-width: 780px) {
    width: 140px;
    height: 40px;
    font-size: 18px;
    border-radius: 12px;
    margin: 0 5px;
  }
  @media (max-width: 480px) {
    width: 140px;
    height: 50px;
    font-size: 16px;
    border-radius: 15px;
    margin: 0 5px;
  }

  @media (max-width: 380px) {
    width: 120px;
    height: 40px;
    font-size: 14px;
    border-radius: 15px;
  }
`;
export const DesktopBackButtonContainer = styled.div`
  display: flex;
  flex-direction: row-reverse;
  justify-content: center;
  align-items: center;
  width: 75%;
  margin: 0 auto;
  background: #fff;
  z-index: 1000;
  border-radius: 15px 15px 0 0;
  font-family: 'Inter', sans-serif;
  margin-top: 40px;

  @media (max-width: 780px) {
    display: none;
  }
`;

export const NextButtonContainer = styled.div`
  width: 50%;
  padding: 0 10px;
  display: flex;
  justify-content: end;
  align-items: center;
`;

export const BackCancelContainer = styled.div`
  width: 50%;
  padding: 0 10px;
  display: flex;
  justify-content: start;
  align-items: center;
  margin: 0 10px;
  border: 0.1em solid #ffffff;
  border-radius: 10px;
  box-sizing: border-box;
  text-decoration: none;
  font-weight: 300;
  color: #ffffff;
  text-align: center;
  transition: all 0.2s;

  @media (max-width: 780px) {
    margin-bottom: 20px;
  }
`;

export const Button = styled.button`
  width: 55%;
  background: linear-gradient(180deg, #dd2256 0%, #e43c39 100%);
  color: white;
  padding: 10px 0;
  border: none;
  border-radius: 12px;
  font-size: 18px;
  cursor: pointer;
  margin: 0;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #c51e4a;
  }

  @media (max-width: 1480px) {
    font-size: 16px;
    border-radius: 10px;
    padding: 8px 0;
  }

  @media (max-width: 780px) {
    display: none;
  }
`;

export const DesktopBackButton = styled.button`
  width: 55%;
  font-family: 'Inter', sans-serif;
  background-color: #fff;
  color: #dd2256;
  padding: 10px 0;
  border: 1px solid #dd2256;
  border-radius: 12px;
  font-size: 18px;
  cursor: pointer;
  margin: 0;
  transition: background-color 0.3s ease, color 0.3s ease, transform 0.3s ease;

  &:hover {
    background: linear-gradient(180deg, #dd2256 0%, #e43c39 100%);
    color: #fff;
  }

  @media (max-width: 1480px) {
    font-size: 16px;
    border-radius: 10px;
    padding: 8px 0;
  }

  @media (max-width: 780px) {
    display: none;
  }
`;
export const StyledErrorMessage = styled.div`
  color: red;
  font-size: 10px;
  margin-bottom: 10px;
  font-weight: bold;
  width:65%;
  color:red;
  @media (max-width: 1480px) {
    margin-top: none;
    margin-bottom: none;
    line-height:0px;

  }
  @media (max-width: 780px) {

  }
`;