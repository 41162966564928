import styled from 'styled-components';
import { Field, Form } from 'formik';
import { Button, InputGroup } from 'rsuite';

export const InputIcon = styled(Field)<{ error: boolean; signUp: boolean }>`
  padding: ${({ signUp }) => (signUp ? '1.1rem 2rem' : '1.2rem 2rem')};

  width: ${({ signUp }) => (signUp ? '87%' : '100%')};
  margin: ${({ signUp }) => (signUp ? '0 auto' : '0')};
  margin-top: 3px;
  border-radius: 10px;
  border: 1px solid ${({ error }) => (error ? '#dd2256' : '#ffd6df')};
  background-color: ${({ error }) => (error ? '#ffe6e6' : '#ffd6df')};
  color: #dd2256;
  font-family: 'Inter', sans-serif;
  font-size: 14px;
  outline: none;
  box-shadow: 0px 3px 10px 5px #0000000a inset;

  ::placeholder {
    color: #dd2256;
    color: ${({ signUp }) => (signUp ? '#DD2256' : '#dd2256')};
  }

  ::-ms-input-placeholder {
    color: #dd2256;
  }
  &:-webkit-autofill {
    background-color: #ffd6df !important;
    -webkit-box-shadow: 0 0 0px 1000px #ffd6df inset !important;
    -webkit-text-fill-color: #dd2256 !important;
  }
  &:focus {
    border-color: #f857a6;
  }

  @media (max-width: 1480px) {
    margin-top: 2px;

    width: ${({ signUp }) => (signUp ? '93%' : '100%')};
    padding: ${({ signUp }) => (signUp ? '0.9rem 1.8rem' : '1.2rem 2rem')};

    font-size: 14px;
  }
  @media (max-width: 780px) {
    width: ${({ signUp }) => (signUp ? '100%' : '100%')};

    font-size: 14px;
  }
  @media (max-width: 480px) {
    width: ${({ signUp }) => (signUp ? '100%' : '100%')};
  }
  @media (max-width: 380px) {
    font-size: 14px;
  }
`;

export const IconInInput = styled(InputGroup.Addon)`
  padding: 0.5rem 1rem;
`;

export const FormContainer = styled(Form)`
  > *:not(.rs-input-group-inside) {
    margin-bottom: 20px;

    @media (max-width: 375px) {
      margin-bottom: 10px;
    }
  }
`;

export const ButtonSign = styled(Button)`
  background-color: ${props => props.theme.colors.red};
  color: ${props => props.theme.colors.white};
  font-weight: bold;
  border-radius: 50px;
  padding: 1.2rem 2rem;
  font-size: ${props => props.theme.fonts.subtitle};
`;

interface ErrorMessageProps {
  signUp: boolean;
}

export const ErrorMessage = styled.p<ErrorMessageProps>`
  color: ${props => props.theme.colors.red};
  font-size: ${props => props.theme.fonts.paragraph};
  text-align: left;
  margin-top: 5px;
  padding: 0 40px;

  @media (max-width: 1480px) {
    padding: 0 22px;
  }
  @media (max-width: 375px) {
    margin-top: 0;
    font-size: 0.8rem;
    line-height: 1.2;
  }
`;
export const HintMessage2 = styled.p<ErrorMessageProps>`
  font-size: 10px;
  margin-bottom: 10px;
  font-weight: bold;
  width: 55%;
  color: red;
  @media (max-width: 1480px) {
    margin-top: none;
    // margin-bottom: none;
    // line-height:0px;
  }
  @media (max-width: 780px) {
    width: 90%;
  }
`;
export const HintMessage = styled.p`
  color: ${props => props.theme.colors.darkGrey};
  font-size: ${props => props.theme.fonts.paragraph};
  text-align: left;
  margin-top: 5px;
  padding: 0 25px;
  @media (max-width: 1480px) {
    padding: 0 25px;
  }
`;

export const TosContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const TosCheckbox = styled(Field)`
  height: 18px;
  width: 18px;
`;

export const TosText = styled.span`
  margin-left: 20px;
  font-family: 'Poppins', sans-serif;

  @media (max-width: 390px) {
    margin: 5px;
    font-size: 0.9rem;
  }
`;
