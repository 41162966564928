import styled from 'styled-components';

interface StepCircleProps {
  active: boolean;
}

export const StepHeaderContainer = styled.div`
  position: sticky;
  top: 0;
  width: 100%;
  z-index: 1000;
  display: flex;
  flex-direction: column;
  background-color: #fff;
  margin: 0px;
  @media (max-width: 1080px) {
    position: relative;
    padding: 0;
    background-color: transparent;
    margin-bottom: 0px;
  }
  @media (max-width: 780px) {
    position: relative;
    padding: 0;
    background-color: transparent;
    margin-bottom: 5px;
  }
`;

export const CreateAccountTitle = styled.h1`
  font-size: 1.5rem;
  font-family: 'Inter', sans-serif;
  color: #dd2256;
  text-align: center;
  margin: 0 auto;
  @media (max-width: 1480px) {
    font-size: 1.2rem;
  }
  @media (max-width: 1280px) {
    font-size: 1.1rem;
  }
  @media (max-width: 780px) {
    font-size: 1.2rem;
    margin-bottom: 0px;
  }
  @media (max-width: 670px) {
    font-size: 1.2rem;
    margin-bottom: 0px;
  }

  @media (max-width: 320px) {
    font-size: 1rem;
    margin-bottom: 0px;
  }
`;

export const StepIndicator = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 1.5rem;
  @media (max-width: 780px) {
    margin-bottom: 0px;
  }
`;

export const StepCircle = styled.div<StepCircleProps>`
  width: 10px;
  height: 10px;
  margin: 0 5px;
  border-radius: 50%;
  background-color: ${props => (props.active ? '#DD2256' : '#FFC0CB')};
  transition: background-color 0.3s ease-in-out;
  @media (max-width: 780px) {
    width: 7px;
    height: 7px;
  }
`;

interface MobileImageProps {
  step: number;
}
export const MobileImage = styled.div<MobileImageProps>`
  display: none; /* Hide by default */

  @media (max-width: 780px) {
    display: block;
    text-align: center;
    height: 16%;
    width: 25%;
    width: ${({ step }) =>
      step === 3 ? '50%' : '20%'}; /* Adjust width based on step */

    margin: 0 auto;
    margin-bottom: 10px;
  }

  @media (max-width: 480px) {
    padding: 0;
    height: 24%;
    // width: 45%;
    width: ${({ step }) =>
      step === 3 ? '60%' : '40%'}; /* Adjust width based on step */
  }
  @media (max-width: 380px) {
    padding: 0;
    height: 22%;
    width: 40%;
  }

  &:active {
    transform: translateY(0); /* Reset lift on click */
  }
`;

// Optional: You can use this for additional styling
export const MobileImageContent = styled.img`
  max-width: 90%; /* Responsive width */

  // height: auto; /* Maintain aspect ratio */
  margin: 0 auto; /* Center the image */
  padding: 0px;
  margin-top: 10px;
`;
export const CancelButton = styled.button`
  display: none; /* Hide by default */
  @media (max-width: 780px) {
    display: block;
    position: absolute; /* Position it on the right */
    top: 17px;
    right: 40px;
    width: auto;
    padding: 8px 16px; /* Add some padding for better appearance */
    font-family: 'Inter', sans-serif;
    font-size: 16px; /* Adjust font size for better readability */
    font-weight: 500; /* Add some weight to the font */
    color: #dd2256;
    background-color: transparent;
    border-radius: 15px; /* Slightly round the corners */
    cursor: pointer;
    transition: all 0.3s ease; /* Smooth transition for hover effects */
    font-weight: bold; /* Make text bold */

    &:hover {
      background: linear-gradient(180deg, #dd2256 0%, #e43c39 100%);
      color: #fff; /* Change text color on hover */
    }

    &:active {
      transform: scale(0.98); /* Slightly scale down on click */
    }
    @media (max-width: 480px) {
      right: 5px;
    }
    @media (max-width: 375px) {
      right: 0px;
    }
  }
`;
