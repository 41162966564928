import styled, { keyframes } from 'styled-components';

// interface StepCircleProps {
//     active: boolean;
//   }

export const Title = styled.h2`
  font-family: 'Inter', sans-serif;
  font-size: 32px;
  color: #dd2256;
  font-family: 'Inter', sans-serif;
  text-align: center;
  width: 80%;

  margin: 0 auto;
  margin-bottom: 10px;

  @media (max-width: 1480px) {
    width: 80%;
    margin-bottom: 10px;
    margin: 0 auto;
    font-size: 30px;
    margin-bottom: 10px;
  }
  @media (max-width: 1280px) {
    width: 90%;
    margin-bottom: 10px;
    margin: 0 auto;
    font-size: 22px;
    margin-bottom: 10px;
  }
  @media (max-width: 1024px) {
    font-size: 20px;
    margin-bottom: 10px;
  }
  @media (max-width: 780px) {
    display: none;
  }
`;
export const Titlembile = styled.h2`
  display: none;

  @media (max-width: 780px) {
    font-family: 'Inter', sans-serif;
    width: 90%;
    margin: 0 auto;
    display: block; /* Show on mobile */
    font-size: 16px;
    color: #dd2256;
    font-family: 'Inter', sans-serif;
    text-align: left;
    line-height: 25px;

    @media (max-width: 480px) {
      width: 90%;
      margin: 0 auto;
      font-size: 18px;
      text-align: center;
    }
    @media (max-width: 390px) {
      width: 90%;
      font-size: 18px;
      line-height: 30px;
      text-align: center;
    }
  }
`;
export const Subtitle = styled.p`
  font-family: 'Inter', sans-serif;
  font-size: 14px;
  color: #666;
  width: 70%;
  margin: 0 auto;
  margin-bottom: 10px;
  text-align: center;
  @media (max-width: 1480px) {
    width: 70%;
    margin: 0 auto;
    font-size: 14px;
    color: #666;
  }
  @media (max-width: 1280px) {
    font-size: 12px;
    width: 75%;
  }
  @media (max-width: 1024px) {
    font-size: 12px;
    width: 75%;
  }

  @media (max-width: 780px) {
    display: none;
  }
`;

export const Subtitlemobile = styled.p`
  display: none;
  text-align: center;
  @media (max-width: 780px) {
    width: 90%;
    font-family: 'Inter', sans-serif;
    display: block;
    font-size: 18px;
    color: #666;
    text-align: left; /* Center the text */
    font-size: 12px;
    text-align: left;
  }

  @media (max-width: 380px) {
    font-size: 12px;
    text-align: left;
  }
`;
export const Subtitlemobilebottom = styled.p`
  display: none;

  @media (max-width: 780px) {
    display: block;
    width: 95%;
    margin: 0 auto;
    font-family: 'Inter', sans-serif;
    font-size: 12px;
    color: #666;
    text-align: left;
    margin: 0;
    // margin-top:5px;
  }
  @media (max-width: 480px) {
    display: block;
    width: 95%;
    margin: 0 auto;
    font-family: 'Inter', sans-serif;
    font-size: 12px;
    color: #666;
    text-align: left;
    margin: 0;
    // margin-top:5px;
  }
`;

export const BackButtondiv = styled.div`
  display: none;

  @media (max-width: 780px) {
    font-family: 'Inter', sans-serif;
    display: flex;
    width: 81%;
    position: fixed;
    bottom: 0;
    left: 50%; 
    transform: translateX(-50%); 
    background: #fff;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    z-index: 1000;
    min-height: 100px; 
  }
 
  @media (max-width: 480px) {
    padding: 0; 
  }
`;

export const BackButtonnext = styled.button`
  font-family: 'Inter', sans-serif;

  color: ${props => (props.disabled ? '#dd2256' : '#fff')};  /* Default red color */
  width: 140px;
  height: 50px;
  border: 1px solid #dd2256;
  border-radius: 20px;
  font-size: 20px;
  cursor: pointer;
  margin: 0 10px; /* Margin for spacing between buttons */
  background-color: ${props => (props.disabled ? '#ffd6df' : '#dd2256')};  /* Default red color */



  @media (max-width: 780px) {
    width: 160px;
    height: 40px;
    font-size: 16px;
    border-radius: 15px;
    margin: 0 5px; /* Adjust spacing for smaller screens */
    margin-bottom: 20px;
  }
  @media (max-width: 480px) {
    width: 140px;
    height: 40px;
    font-size: 16px;
    border-radius: 15px;
    margin: 0 5px; /* Adjust spacing for smaller screens */
    margin-bottom: 20px;
  }

  @media (max-width: 380px) {
    width: 120px;
    height: 40px;
    font-size: 14px; /* Slightly smaller font for very small screens */
    border-radius: 15px;
    margin-bottom: 10px;
  }
`;
export const BackButton = styled.button`
  background-color: #fff;
  font-family: 'Inter', sans-serif;
  color: #dd2256;
  width: 140px;
  height: 50px;
  font: bold;
  border: 1px solid #dd2256;
  border-radius: 20px;
  font-size: 20px;
  cursor: pointer;
  margin: 0 10px;

  &:hover {
    background: linear-gradient(180deg, #dd2256 0%, #e43c39 100%);
    color: #fff;
  }

  @media (max-width: 780px) {
    width: 160px;
    height: 40px;
    font-size: 16px;
    border-radius: 15px;
    margin: 0 5px;
    margin-bottom: 20px;
  }
  @media (max-width: 480px) {
    width: 140px;
    height: 40px;
    font-size: 186x;
    border-radius: 15px;
    margin: 0 5px;
    margin-bottom: 20px;
  }

  @media (max-width: 380px) {
    width: 120px;
    height: 40px;
    font-size: 14px;
    border-radius: 15px;
    margin-bottom: 10px;
  }
`;
export const StyledInput = styled.input<{ isError?: boolean }>`
  font-family: 'Inter', sans-serif;
  width: 67%;
  padding: 1.2rem 2rem;
  margin: 1rem auto;
  border-radius: 15px;
  border: none;
  border-color: #ffd6df;
  background-color: #ffd6df;
  font-size: 1rem;
  color: #dd2256;
  outline: none;

  &::placeholder {
    color: #dd2256;
  }
  ::-ms-input-placeholder {
    color: #dd2256;
  }
  &:focus {
    border-color: #f857a6;
  }
  @media (max-width: 1480px) {
    padding: 0.9rem 2rem;

    width: 72%;
  }
  @media (max-width: 1020px) {
    padding: 0.9rem 2rem;

    width: 71%;
  }
  @media (max-width: 780px) {
    width: 75%;
  }
  @media (max-width: 480px) {
    width: 95%;
  }
  @media (max-width: 390px) {
    width: 95%;
    font-size: 14px;
  }
`;
export const ContentContainer = styled.div`
  // flex: 1;
  width: 90%;
  margin: 0 auto;
  overflow-y: auto; /* Allow scrolling for content */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  @media (max-width: 780px) {
    width: 80%;
    padding-bottom: 120px;
  }
`;
export const DesktopBackButtonContainer = styled.div`
  display: flex;
  flex-direction: row-reverse;
  justify-content: center;
  align-items: center; /* Centers the buttons vertically */
  width: 68%;
  background: #fff;
  z-index: 1000;
  border-radius: 15px 15px 0 0;
  font-family: 'Inter', sans-serif;
  margin-top: 40px;
  gap: 30px;
  @media (max-width: 1480px) {
    width: 72%;
  }
  @media (max-width: 780px) {
    display: none;
  }
`;
export const NextButtonContainer = styled.div`
  width: 50%;
  display: flex;
  justify-content: start;
  align-items: center; /* Ensures the button is centered vertically */
`;

export const BackCancelContainer = styled.div`
  width: 50%;
  display: flex;
  justify-content: end;
  align-items: center; /* Ensures the button is centered vertically */
  border: 0.1em solid #ffffff;
  border-radius: 10px;
  box-sizing: border-box;
  text-decoration: none;
  font-weight: 300;
  color: #ffffff;
  text-align: center;
  transition: all 0.2s;

  @media (max-width: 780px) {
    display: none;
  }
`;
export const Button = styled.button`
  width: 100%; /* Makes the button fill its container */
  // background: linear-gradient(180deg, #DD2256 0%, #E43C39 100%);
  color: white;
  padding: 10px 0; /* Standardizes padding to ensure the same height */
  border: none;
  border-radius: 12px;
  font-size: 18px;
  cursor: pointer;
  margin: 0; /* Removes top margin to prevent height differences */
  transition: background-color 0.3s ease;
  color: ${props => (props.disabled ? '#dd2256' : '#fff')};  /* Default red color */
  background-color: ${props => (props.disabled ? '#ffd6df' : '#dd2256')};  /* Default red color */

  &:hover {
    // background-color: #c51e4a;
    background-color: ${props => (props.disabled ? 'none' : '#c51e4a')};  /* Pink on hover when enabled */

  }

  @media (max-width: 1480px) {
    font-size: 14px;
    border-radius: 10px;
    padding: 9px 0; /* Standardizes padding to ensure the same height */
  }
  @media (max-width: 1020px) {
    font-size: 12px;
    border-radius: 10px;
    padding: 8px 0; /* Standardizes padding to ensure the same height */
  }

  @media (max-width: 780px) {
    display: none;
  }
`;

// Styled button for desktop back action with hover and responsive styling
export const DesktopBackButton = styled.button`
  width: 100%; /* Makes the button fill its container */
  font-family: 'Inter', sans-serif;
  background-color: #fff;
  color: #dd2256;
  padding: 10px 0; /* Standardizes padding to ensure the same height */
  border: 1px solid #dd2256;
  border-radius: 12px;
  font-size: 18px;
  cursor: pointer;
  margin: 0; /* Removes top margin to prevent height differences */
  transition: background-color 0.3s ease, color 0.3s ease, transform 0.3s ease;

  &:hover {
    background: linear-gradient(180deg, #dd2256 0%, #e43c39 100%);
    color: #fff;
  }

  @media (max-width: 1480px) {
    font-size: 14px;
    border-radius: 10px;
    padding: 8px 0; /* Standardizes padding to ensure the same height */
  }
  @media (max-width: 1020px) {
    font-size: 12px;
    border-radius: 10px;
    padding: 8px 0; /* Standardizes padding to ensure the same height */
  }
  @media (max-width: 780px) {
    display: none;
  }
`;

export const StyledErrorMessage = styled.div`
  color: red;
  font-size: 12px;
  font-weight: bold;
  width: 65%;
  color: red;
  @media (max-width: 1480px) {
    margin-top: none;
  }
  @media (max-width: 780px) {
    width: 90%;
  }
`;

export const PasswordContainer = styled.div`
  display: flex;
  width: 1%;
  margin: 0 auto;
  flex-direction: column;
  gap: 16px; /* Adjust the gap between inputs as needed */
  margin-bottom: 20px; /* Add some margin to separate it from other elements */
  @media (max-width: 780px) {
    width: 70%;
  }
`;
export const StyledInput2 = styled.input<{ isError?: boolean }>`
  // border-color: ${props => (props.isError ? 'red' : 'initial')};
  border-color: #ffd6df;
  background-color: #ffd6df;
  font-family: 'Inter', sans-serif;
  width: 67%;
  padding: 1.2rem 2rem;
  margin: 0.8rem auto;
  border-radius: 10px;
  border: none;
  // background-color: ${({ isError }) => (isError ? 'black' : '#ffd6df')};
  font-size: 1rem;
  color: #dd2256;
  outline: none;

  &::placeholder {
    color: #dd2256;
  }
  ::-ms-input-placeholder {
    color: #dd2256;
  }
  &:focus {
    border-color: #f857a6;
  }
  &:-webkit-autofill {
    background-color: #ffd6df !important;
    -webkit-box-shadow: 0 0 0px 1000px #ffd6df inset !important;
    -webkit-text-fill-color: #dd2256 !important;
  }
  @media (max-width: 1480px) {
    padding: 0.9rem 2rem;

    width: 72%;
  }
  @media (max-width: 1020px) {
    padding: 0.9rem 2rem;

    width: 70%;
  }
  @media (max-width: 780px) {
    width: 96%;
    margin: 0.7rem auto;
  }
  @media (max-width: 390px) {
    margin: 0.6rem auto;

    width: 95%;
    font-size: 14px;
  }
`;
export const spin = keyframes`
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
`;

// Styled Spinner
export const Spinner = styled.div`
  border: 4px solid rgba(0, 0, 0, 0.1);
  border-radius: 50%;
  border-top: 4px solid #3498db;
  width: 20px;
  height: 20px;
  animation: ${spin} 1s linear infinite;
`;
export const CheckboxContainer = styled.label`
  display: flex;
  align-items: center;
  cursor: pointer; /* Change cursor on hover */
  width: 65%;
  input[type='checkbox'] {
    margin-right: 10px;
  }
  margin-top: 12px;
  margin-bottom: 10px;
  span {
    font-size: 14px; /* Adjust font size as needed */
    color: #333; /* Change text color */
  }

  input[type='checkbox']:checked + span {
    font-weight: bold; /* Style when checked */
    margin-right: 10px;
  }
  @media (max-width: 1480px) {
    width: 65%;
  }
  @media (max-width: 1020px) {
    width: 70%;
  }
  @media (max-width: 780px) {
    margin-top: 0px;
    margin-boottom: 0px;
    width: 93%;
  }
  @media (max-width: 380px) {
    width: 92%;
  }
`;
export const Textt = styled.span`
  font-size: 14px;
  font-family: 'Inter', sans-serif;
  padding: 5px; /* Add padding for better spacing */
  border-radius: 4px; /* Optional: add rounded corners */
  margin-bottom: 5px;
  @media (max-width: 780px) {
    font-size: 1spx;
    margin-bottom: 0px;
  }
`;
export const AsteriskNote = styled.p`
  width: 65%;
  font-size: 0.9em;
  color: #666;
  margin-top: 2rem;
  margin-bottom: 1rem;
  @media (max-width: 780px) {
    width: 93%;
    margin-bottom: 10px;
  }
`;
