import { Drawer, Modal } from 'rsuite';
import styled from 'styled-components';
import icons from '../../../assets/icons/icons.png';

export const StyledModal = styled(Modal)`
  max-width: 700px;
  width: 90%;
  border-radius: 10px;
  /* overflow: hidden; */

  @media (max-width: 1024px) {
    max-width: 90%;
  }

  @media (max-width: 768px) {
    max-width: 90%;
  }

  @media (max-width: 480px) {
    max-width: 100%;
    height: 100vh;
    width: 350px;
    border-radius: 0;
  }

  .rs-modal-content {
    width: 380px;
  }
`;

export const ModalContent = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

export const ImageContainer = styled.div`
  width: 100%;
  background-color: black;
`;

export const CommentsSection = styled.div`
  flex-grow: 1;
  /* overflow-y: auto; */
  /* max-height: 50vh; */
  padding: 0px 10px;

  @media (max-width: 820px) {
    width: 90%;
  }
  @media (max-width: 570px) {
    width: 85%;
  }

  @media (max-width: 470px) {
    /* max-height: 60vh; */
    width: 80%;
  }
`;

export const DrawerBorder = styled(Drawer)`
  max-width: 100%;
  font-family: 'Poppins';

  .rs-drawer-dialog .rs-drawer-content {
    border-radius: 20px 0px 0px 20px;
    padding: 0px 5vw;
    background: #edf2f7;
    overflow-y: scroll;

    .rs-drawer-header h4 {
      font-size: ${props => props.theme.fonts.title};
      font-weight: bolder;
      color: ${props => props.theme.colors.black};
    }
    .rs-drawer-body {
      display: flex;
      flex-direction: column;
      margin: 0;
      transition: all 2s ease;
      height: ${props =>
        props.increaseHei ? '810px !important' : 'auto !important'};
      overflow-y: ${props =>
        props.increaseHei ? 'scroll !important' : 'hidden'};
    }
    .rs-drawer-header button {
      font-size: ${props => props.theme.fonts.bigSubtitle};
      color: ${props => props.theme.colors.grey};
    }
  }
`;

export const SocialLogo = styled.div`
  display: flex;
  justify-content: flex-end;
  margin: 5px;

  img {
    width: 6%;
  }
`;

export const Image = styled.img`
  width: 7%;
  height: 100%;
  object-fit: cover;
  border-radius: 50%;
`;

export const PreviewWrapper = styled.div`
  display: flex;
  flex-direction: column;
  background: #fff;
  border-radius: 0.375rem;
  border-color: #e2e8f0;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.08);
`;

export const PageImageWrapper = styled.div`
  display: flex;
  gap: 10px;
`;
export const TimeWrapper = styled.div`
  display: flex;
  flex-direction: column;

  @media (max-width: 476px) {
    font-size: 12px;
  }
`;

export const PageName = styled.span`
  font-size: 15px;
  font-weight: 800;

  @media (max-width: 476px) {
    font-size: 12px;
  }
`;

export const PostText = styled.span`
  font-size: 15px;
  font-weight: 800;
  margin: 23px;

  @media (max-width: 476px) {
    font-size: 12px;
  }
`;

export const PostImage = styled.img`
  max-height: 100vh;
  object-fit: cover;
`;

export const PostImageBackground = styled.div<{ src: string }>`
  width: 100%;
  height: 50vh;
  background-image: url(${props => props.src});
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
`;

export const BottomSection = styled.div`
  display: flex;
  flex-direction: column;
  padding: 10px;

  .rs-divider-horizontal {
    margin: 7px 0px;
  }
`;

export const LikesSection = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0px 15px;
`;

export const Button = styled.button`
  background: none;
  border: none;
  padding: 0;
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  img {
    width: 18px;
    height: 18px;
  }
`;

export const CountWrapper = styled.div`
  display: flex;
  padding: 5px 0px;
  gap: 10px;

  span:nth-child(1) {
    margin-left: 5px;
  }

  span {
    font-size: 15px;
    font-weight: 600;
  }
`;

export const ImageWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  width: 2.375rem;

  img {
    width: 20px;
  }

  span {
    font-size: 13px;
  }
`;

export const SocialWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 0px 15px;

  @media (max-width: 480px) {
    padding: 0px 10px;
  }
`;

export const LikeWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;

  img {
    width: 24px;
  }

  span {
    font-size: 12px;
    color: ${props => props.color};
    cursor: pointer;
  }
`;

export const CommentWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;

  img {
    width: 24px;
  }

  span {
    font-size: 14px;
  }
`;
export const ShareWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
  opacity: 0.4;

  img {
    width: 24px;
  }

  span {
    font-size: 14px;
  }
`;

export const CommentContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  button {
    font-size: 18px;
    font-weight: 900;
    color: ${props => props.theme.colors.darkHardGrey};
    padding: 10px 14px;
  }
`;
export const DisplayCommentSection = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const CommentCardWrapper = styled.div`
  display: flex;
  padding: 10px;

  img {
    border-radius: 50%;
    width: 40px;
    height: 40px;
    margin-right: 10px;
  }
`;

export const ImageTextContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 100%;
  padding: 0px 10px;
`;

export const ButtonsContainer = styled.div`
  display: flex;
  gap: 20px;
  padding: 0px 15px;
  align-items: center;

  span {
    flex-shrink: 0;
    font-size: 12px;
  }

  button {
    padding: 0;
    font-size: 12px;
  }

  img {
    width: 20px;
    height: auto;
    margin-left: auto;
  }
`;

export const LikesButton = styled.button<{ liked: boolean }>`
  background: none;
  border: none;
  padding: 0;
  cursor: pointer;
  color: ${({ liked }) => (liked ? '#0866ff !important' : 'inherit')};
`;

export const ReplyButton = styled.button`
  background: none;
  border: none;
  padding: 0;
  cursor: pointer;
`;

export const SingleCommentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 15px;
  border-radius: 20px;
  background: ${props => props.theme.colors.lightGrey};
  gap: 5px;

  span {
    font-size: 12px;
    font-weight: 800;
    color: ${props => props.theme.colors.black};
  }
  p {
    font-size: 14px;
    color: ${props => props.theme.colors.black};
  }
`;

export const InputCommentSection = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  margin: 10px 0px;
  padding: 0px 10px;
  width: 100%;

  .smileIcon {
    width: 20px;
    height: 20px;
    left: 1px;
    bottom: 5px;
  }

  img {
    border-radius: 50%;
    width: 40px;
    height: 40px;
    margin-right: 10px;
  }
`;
export const InputSection = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 20px;
  background: ${props => props.theme.colors.lightGrey};
  width: 100%;
  padding: 9px;
  gap: 9px;

  @media (max-width: 820px) {
    display: flex;
    gap: 16px;
    width: 88%;
    padding-left: 10px;
    padding-bottom: 7px;
    padding-top: 8px;
  }
`;

export const Input = styled.input`
  font-size: 14px;
  height: 33px;
  background: transparent;
  border: none;
  color: ${props => props.theme.colors.black};

  &:focus {
    outline: none;
    border: none;
    box-shadow: none;
  }

  &::placeholder {
    color: ${props => props.theme.colors.darkHardGrey};
    font-size: 14px;
  }
`;

export const IconsWrapper = styled.div`
  position: relative;
  display: flex;
  justify-content: space-between;
  width: 100%;

  .EmojiPickerReact {
    position: absolute !important;
    transform: translate(-10px, -500px);
    /* right: 0; */
    z-index: 999999;
  }
`;

export const CommentIconsWrapper = styled.div`
  position: relative;
  display: flex;
  justify-content: space-between;
  width: 100%;
  cursor: pointer;

  .EmojiPickerReact {
    position: absolute !important;
    transform: translate(-10px, -500px);
    /* right: 0; */
    z-index: 999999;
  }
`;

export const IconSmiley = styled.img`
  width: 15px;
  height: 15px;
  position: absolute;
  bottom: 30px;
  right: 5px;
  z-index: 10;
  cursor: pointer;
`;

export const SelectIconSection = styled.div`
  display: flex;
  gap: 5px;
  padding-bottom: 5px;
`;

export const Icon = styled.span`
  width: 32px;
  height: 23px;
  background-image: url(${icons});
  background-size: cover;
  background-repeat: no-repeat;
  display: inline-block;
  margin-right: 5px;
`;

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
  padding: 0px 20px;
`;

export const CommentInnerText = styled.span`
  font-size: 16px;
  font-weight: 800;
  margin-top: 14px;

  @media (max-width: 470px) {
    font-size: 12px;
    margin-top: 15px;
  }
`;
