import axios from '../lib/axios';
import { notificationError } from '../lib/notification';

export const assignTool = async (assingTool: {
  companyId: number;
  tool: Tool[];
}) => {
  try {
    const response = await axios.post('/companies/assign-tool', {
      companyId: assingTool.companyId,
      tool: assingTool.tool,
    });
    return response;
  } catch (error) {
    notificationError({
      title: 'Error',
      description: 'Error',
    });
    return error;
  }
};
export const remove_assignTool = async (assingTool: {
  companyId: number;
  tool: Tool[];
}) => {
  try {
    const response = await axios.post('/companies/assign-tool-disabled', {
      companyId: assingTool.companyId,
      tool: assingTool.tool,
    });
    return response;
  } catch (error) {
    notificationError({
      title: 'Error',
      description: 'Error',
    });
    return error;
  }
};
