/* eslint-disable @typescript-eslint/no-explicit-any */
// import { useQueryClient } from 'react-query';
import React, { useRef, useEffect, useState, useCallback } from 'react';
import { Row } from 'rsuite';
import { TYPES_OF_SOCIAL_NETWORKS } from '../../lib/globalValues';
import ToolToogleCard from '../cards/toolToogleCard/ToolToogleCard';
import FacebookToogle from './facebookToogle/FacebookToogle';
import GoogleBusinessToggle from './googleToogle/GoogleBusinessToggle';
import InstagramToogle from './instagramToogle/InstagramToogle';
import TwitterToggle from './twitterToogle/TwitterToogle';
import TiktokToogle from './tiktokToogle/TiktokToogle';
// import UberEatsToggle from './uberEatsToogle/UberEatsToggle';
import { ColMargin, Subtitlep } from './Toogle.styles';
import { getWelcomeMessage } from '../../views/steputils/steputils';
import { getcountpages } from '../../services/getToolsCompany';

const ToogleTools = ({
  card = false,
  signup = false,
  noText = false,
  stateTool,
  dispatchTool,
  companyId,
  invalidateQuery,
  newFormat = false,
}: {
  card?: boolean;
  signup?: boolean;
  noText?: boolean;
  stateTool: any;
  dispatchTool: any;
  companyId?: number;
  invalidateQuery?: () => void;
  newFormat?: boolean;
}) => {
  let filteredStateTool: Record<string, any>;
  const childRef = useRef<any>(null);
  const childRef2 = useRef<any>(null);
  const childRef3 = useRef<any>(null);
  const childRef4 = useRef<any>(null);
  const childRef5 = useRef<any>(null);
  const [socialNetworkPages, setSocialNetworkPages] = useState({
    facebook: false,
    google: false,
    instagram: false,
    twitter: false,
    tiktok: false,
  });
  interface SocialNetworkPageCounts {
    facebook: number;
    google: number;
    instagram: number;
    twitter: number;
    tiktok: number;
  }
  const fetchPageCounts = useCallback(async () => {
    try {
      const response = (await getcountpages()) as {
        data: SocialNetworkPageCounts;
      };
      setSocialNetworkPages({
        facebook: (response?.data?.facebook ?? 0) >= 2,
        google: (response?.data?.google ?? 0) >= 2,
        instagram: (response?.data?.instagram ?? 0) >= 2,
        twitter: (response?.data?.twitter ?? 0) >= 2,
        tiktok: (response?.data?.tiktok ?? 0) >= 2,
      });
    } catch (error) {
      // console.error('Error fetching page counts:', error);
    }
  }, []);

  useEffect(() => {
    fetchPageCounts();
  }, [fetchPageCounts]);
  if (signup) {
    filteredStateTool = Object.fromEntries(
      Object.entries(stateTool).filter(([socialName]) =>
        ['FACEBOOK', 'INSTAGRAM', 'GOOGLE'].includes(socialName.toUpperCase()),
      ),
    );
  } else if (newFormat) {
    filteredStateTool = Object.fromEntries(
      Object.entries(stateTool).filter(([socialName]) =>
        ['FACEBOOK', 'INSTAGRAM', 'GOOGLE', 'TIKTOK', 'TWITTER'].includes(
          socialName.toUpperCase(),
        ),
      ),
    );
  } else {
    filteredStateTool = stateTool;
  }

  return (
    <Row
      signup={signup}
      style={
        newFormat
          ? { display: 'flex', flexDirection: 'column', gap: '20px' }
          : { marginRight: 0 }
      }>
      {Object.entries(filteredStateTool).map(([socialName, content]) => {
        // Determine the column size without using nested ternary expressions
        let mdSize;
        if (signup) {
          mdSize = 24;
        } else if (card) {
          mdSize = 18;
        } else {
          mdSize = 24;
        }

        const welcomeMessage = getWelcomeMessage(socialName);

        // Trigger the child's handleClick method when the parent is clicked
        // eslint-disable-next-line no-shadow
        const handleParentClick = (
          name: string,
          event: React.SyntheticEvent,
        ) => {
          event.stopPropagation();
          if (name === TYPES_OF_SOCIAL_NETWORKS.FACEBOOK && childRef.current) {
            childRef.current.handleClick();
          } else if (
            name === TYPES_OF_SOCIAL_NETWORKS.INSTAGRAM &&
            childRef2.current
          ) {
            childRef2.current.handleClick();
          } else if (
            name === TYPES_OF_SOCIAL_NETWORKS.GOOGLE &&
            childRef3.current
          ) {
            childRef3.current.handleClick();
          } else if (
            name === TYPES_OF_SOCIAL_NETWORKS.TWITTER &&
            childRef4.current
          ) {
            childRef4.current.handleClick();
          } else if (
            name === TYPES_OF_SOCIAL_NETWORKS.TIKTOK &&
            childRef5.current
          ) {
            childRef5.current.handleClick();
          }
        };

        return (
          <ColMargin
            signup={signup}
            newFormat={newFormat}
            xs={24}
            md={mdSize}
            key={`${socialName}Card`}>
            <ToolToogleCard
              newFormat={newFormat}
              signup={signup}
              type={socialName}
              content={content}
              noText={!noText}
              onClick={(event: React.SyntheticEvent) => {
                event.stopPropagation();
                handleParentClick(socialName, event);
              }}>
              {
                {
                  [TYPES_OF_SOCIAL_NETWORKS.FACEBOOK]: (
                    <FacebookToogle
                      stateTool={filteredStateTool}
                      dispatchTool={dispatchTool}
                      companyId={companyId}
                      invalidateQuery={invalidateQuery}
                      signup={signup}
                      newFormat={newFormat}
                      ref={childRef}
                      clickedSocial="FACEBOOK"
                      socialNetworkPages={socialNetworkPages}
                      updateParentPages={fetchPageCounts}
                    />
                  ),
                  [TYPES_OF_SOCIAL_NETWORKS.INSTAGRAM]: (
                    <InstagramToogle
                      stateTool={filteredStateTool}
                      dispatchTool={dispatchTool}
                      companyId={companyId}
                      invalidateQuery={invalidateQuery}
                      signup={signup}
                      newFormat={newFormat}
                      ref={childRef2}
                      clickedSocial="INSTAGRAM"
                      socialNetworkPages={socialNetworkPages}
                      updateParentPages={fetchPageCounts}
                    />
                  ),
                  [TYPES_OF_SOCIAL_NETWORKS.GOOGLE]: (
                    <GoogleBusinessToggle
                      stateTool={filteredStateTool}
                      dispatchTool={dispatchTool}
                      companyId={companyId}
                      invalidateQuery={invalidateQuery}
                      signup={signup}
                      newFormat={newFormat}
                      ref={childRef3}
                      clickedSocial="GOOGLE"
                      socialNetworkPages={socialNetworkPages}
                      updateParentPages={fetchPageCounts}
                    />
                  ),
                  [TYPES_OF_SOCIAL_NETWORKS.TIKTOK]: (
                    <TiktokToogle
                      stateTool={stateTool}
                      dispatchTool={dispatchTool}
                      companyId={companyId}
                      invalidateQuery={invalidateQuery}
                      signup={signup}
                      newFormat={newFormat}
                      ref={childRef5}
                      clickedSocial="TIKTOK"
                      socialNetworkPages={socialNetworkPages}
                    />
                  ),
                  [TYPES_OF_SOCIAL_NETWORKS.TWITTER]: (
                    <TwitterToggle
                      stateTool={filteredStateTool}
                      dispatchTool={dispatchTool}
                      companyId={companyId}
                      invalidateQuery={invalidateQuery}
                      signup={signup}
                      newFormat={newFormat}
                      ref={childRef4}
                      clickedSocial="TWITTER"
                      socialNetworkPages={socialNetworkPages}
                    />
                  ),
                }[socialName]
              }
            </ToolToogleCard>
            {signup && (
              <Subtitlep>
                {' '}
                <div>{welcomeMessage}</div>
              </Subtitlep>
            )}
          </ColMargin>
        );
      })}
    </Row>
  );
};

export default ToogleTools;
